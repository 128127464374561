import * as z from 'zod'

import { Option } from '~/shared/config/constants'
import { InspectionReasonCodeEnum } from '~/shared/config/enums'
import { dateSchema } from '~/shared/lib/schemas'
import { ApiModel } from './core'

const attributesSchema = z.object({
  title: z.string(),
  code: z.nativeEnum(InspectionReasonCodeEnum),
})

const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributesSchema)

export type InspectionReasonAttributes = z.infer<typeof attributesSchema>

export class InspectionReason extends ApiModel<
  typeof schema,
  InspectionReasonAttributes
> {
  static jsonApiType = 'inspection-reasons'

  static schema = schema

  getTitle(): string {
    return this.getAttribute('title')
  }

  getCode(): InspectionReasonCodeEnum {
    return this.getAttribute('code')
  }

  getOption(): Option {
    return {
      id: this.getApiId() as UniqueId,
      label: this.getTitle(),
    }
  }

  static async fetchOptions() {
    const response = await InspectionReason.get(1)
    return response.getData().map((o) => o.getOption())
  }
}
