import { Box, Chip as JoyChip, Stack } from '@mui/joy'
import { ChipTypeMap } from '@mui/joy/Chip/ChipProps'
import { myPalette } from '~/shared/lib/theme'
import { LinkDecorator } from '~/shared/ui/Buttons'

type ChipDeleteProps = ChipTypeMap['props'] & {
  to?: string
  tooltipText?: string
}

export function ChipLink({
  children,
  to,
  tooltipText,
  ...props
}: ChipDeleteProps) {
  return (
    <JoyChip
      {...props}
      sx={{
        ...props.sx,
        background: myPalette.brand['100'],
        borderRadius: '8px',
        border: 'none',
        color: myPalette.brand['600'],
        fontSize: '14px',
        height: '23px',
        padding: '0',
        fontWeight: 500,
      }}
    >
      <Stack direction='row' alignItems='center'>
        <Box sx={{ padding: '4px 8px' }}>{children}</Box>
        {to && (
          <LinkDecorator
            tooltipText={tooltipText || ''}
            to={to}
            iconButtonProps={{
              sx: {
                maxWidth: '24px',
                minWidth: '24px',
                minHeight: '21px',
                marginRight: '2px',
                svg: {
                  maxWidth: '14px',
                  maxHeight: '14px',
                },
                '&:hover': {
                  borderRadius: '7px',
                  background: myPalette.brand['300'],
                },
              },
            }}
          />
        )}
      </Stack>
    </JoyChip>
  )
}
