import {
  InspectionDocument,
  InspectionEquipment,
  InspectionService,
  InspectionWheelsState,
} from '~/shared/api'
import {
  diskTypeEnumOptions,
  Option,
  rubberTypeEnumOptions,
} from '~/shared/config/constants'
import {
  DocumentsTypeEnum,
  InspectionServiceTypeEnum,
} from '~/shared/config/enums'

const documentsDefaultValues = {
  [DocumentsTypeEnum.STS]: false,
  [DocumentsTypeEnum.DC]: false,
  [DocumentsTypeEnum.LICENSE]: false,
  [DocumentsTypeEnum.OSAGO]: false,
}

export const mapApiDocumentsToFormDocuments = (
  documents?: InspectionDocument[],
) => {
  if (!documents) return documentsDefaultValues

  return documents.reduce(
    (values: Record<DocumentsTypeEnum, boolean>, document) => ({
      ...values,
      [document.getType()]: document?.getValue(),
    }),
    documentsDefaultValues,
  )
}

export const mapApiEquipmentsToFormEquipments = (
  equipments?: InspectionEquipment[],
) => {
  if (!equipments) return {}

  return equipments.reduce(
    (values: Record<string, boolean>, equipment) => ({
      ...values,
      [equipment.getEquipmentId()]: equipment?.getValue(),
    }),
    {},
  )
}

const servicesDefaultValues = {
  [InspectionServiceTypeEnum.MOTOR_OIL]: '-',
  [InspectionServiceTypeEnum.ANTIFREEZE]: '-',
  [InspectionServiceTypeEnum.WIPERS]: '-',
  [InspectionServiceTypeEnum.LAMPS]: '-',
}

export const mapApiServicesToFormServices = (
  services?: InspectionService[],
) => {
  if (!services) return {}

  return services.reduce(
    (values: Record<InspectionServiceTypeEnum, string>, service) => {
      const type = service.getType()
      const value =
        type === InspectionServiceTypeEnum.ANTIFREEZE ||
        type === InspectionServiceTypeEnum.MOTOR_OIL
          ? `${service?.getData()[0]} мл`
          : service?.getData().join(', ')

      return {
        ...values,
        [type]: value,
      }
    },
    servicesDefaultValues,
  )
}

export const mapApiWheelsToFormWheels = (wheels?: InspectionWheelsState[]) => {
  if (!wheels) return {}

  return wheels.reduce(
    (values: Record<InspectionServiceTypeEnum, Option>, wheel) => {
      const rubberType = wheel.getRubberType()
      const diskType = wheel.getDiskType()

      const rubberOption = rubberTypeEnumOptions.find(
        ({ id }) => id === rubberType,
      )
      const diskOption = diskTypeEnumOptions.find(({ id }) => id === diskType)

      return {
        ...values,
        [wheel.getWheelCode()]: {
          rubberType: rubberOption,
          diskType: diskOption,
        },
      }
    },
    {} as Record<InspectionServiceTypeEnum, Option>,
  )
}
