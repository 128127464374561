import { Typography, Grid } from '@mui/joy'
import { Fragment } from 'react'
import {
  diskTypeEnumOptions,
  rubberTypeEnumOptions,
} from '~/shared/config/constants'
import { WheelCodeEnum } from '~/shared/config/enums'
import { AutocompleteInput, FormTitle } from '~/shared/ui/Form'
import { Container } from '~/shared/ui/Layout'

type WheelsProps = {
  loading: boolean
}

const configs = [
  { label: 'ПП', code: WheelCodeEnum.FRONT_RIGHT },
  { label: 'ПЛ', code: WheelCodeEnum.FRONT_LEFT },
  { label: 'ЗП', code: WheelCodeEnum.REAR_RIGHT },
  { label: 'ЗЛ', code: WheelCodeEnum.REAR_LEFT },
]

export function Wheels({ loading }: WheelsProps) {
  return (
    <Container>
      <FormTitle>Колеса</FormTitle>

      <Grid container spacing={2.5}>
        {configs.map(({ label, code }) => (
          <Fragment key={code}>
            <Grid xs={0.4}>
              <Typography>{label}</Typography>
            </Grid>
            <Grid xs={5.6}>
              <AutocompleteInput
                options={rubberTypeEnumOptions}
                label='Резина'
                name={`wheels.${code}.rubberType`}
                readOnly
                skeletonShow={loading}
              />
            </Grid>
            <Grid xs={6}>
              <AutocompleteInput
                options={diskTypeEnumOptions}
                label='Диск'
                name={`wheels.${code}.diskType`}
                readOnly
                skeletonShow={loading}
              />
            </Grid>
          </Fragment>
        ))}
      </Grid>
    </Container>
  )
}
