import { zodResolver } from '@hookform/resolvers/zod'
import { Grid } from '@mui/joy'
import { useStore } from 'effector-react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { FormValues, employeeModel } from '~/entities/Employee'
import { Region, Role, Subdivision } from '~/shared/api'
import { Option } from '~/shared/config/constants'
import {
  FormButtons,
  formButtonsModel,
  FormProvider,
  FormTitle,
  TextInput,
  AsyncAutocompleteInput,
} from '~/shared/ui/Form'
import { Container } from '~/shared/ui/Layout'

export type FormProps = {
  isCreating?: boolean
  canEdit?: boolean
  isSubmitting?: boolean
  isLoading?: boolean
  onSuccess?: (formValues: FormValues) => void
  defaultValues?: Partial<FormValues>
}

export function Form({
  isCreating,
  canEdit,
  isSubmitting,
  isLoading,
  defaultValues,
  onSuccess,
}: FormProps) {
  const isEditing = useStore(formButtonsModel.$isEditing)
  const disabled = (!isEditing && !isCreating) || isSubmitting

  const form = useForm<FormValues>({
    resolver: zodResolver(employeeModel.formSchema),
    defaultValues,
  })

  const { reset, setValue, watch } = form

  watch((_, { type, name }) => {
    if (type !== 'change') return

    if (name === 'regionsIds') {
      setValue('subdivisionsIds', [])
    }
  })

  const regionsOptions = watch('regionsIds') as Option[]
  const regionsIds = (regionsOptions || []).map(
    (regionOption) => regionOption?.id,
  )

  useEffect(() => {
    if (defaultValues) reset(defaultValues)
    // eslint-disable-next-line
  }, [defaultValues, isEditing])

  return (
    <FormProvider form={form} onSuccess={onSuccess} canEdit={canEdit}>
      <Container>
        <FormTitle>Информация</FormTitle>
        <Grid container spacing={2.5}>
          <Grid xs={6}>
            <TextInput
              label='ФИО'
              name='name'
              skeletonShow={isLoading}
              readOnly={disabled}
            />
          </Grid>
          <Grid xs={6}>
            <TextInput
              label='E-mail'
              name='email'
              skeletonShow={isLoading}
              readOnly={disabled}
            />
          </Grid>
        </Grid>
      </Container>

      <Container data-testid='container-regions'>
        <FormTitle>Регионы</FormTitle>
        <Grid container spacing={2.5}>
          <AsyncAutocompleteInput
            name='regionsIds'
            placeholder='Добавить регион'
            fetchOptions={Region.fetchOptions}
            skeletonShow={isLoading}
            readOnly={disabled}
            multiple
            autocompleteXs={6}
            dataTestId='Регионы'
          />
        </Grid>
      </Container>

      <Container data-testid='container-subdivisions'>
        <FormTitle>Подразделение</FormTitle>
        <Grid container spacing={2.5}>
          <AsyncAutocompleteInput
            name='subdivisionsIds'
            placeholder='Добавить подразделение'
            fetchOptions={(search: string) =>
              Subdivision.fetchOptions(search, regionsIds.map(String))
            }
            queryKey={regionsIds}
            skeletonShow={isLoading}
            readOnly={!regionsOptions?.length || disabled}
            multiple
            autocompleteXs={6}
            dataTestId='Подразделение'
          />
        </Grid>
      </Container>

      <Container data-testid='container-roles'>
        <FormTitle>Роли</FormTitle>
        <Grid container spacing={2.5}>
          <AsyncAutocompleteInput
            name='rolesIds'
            placeholder='Добавить роль'
            fetchOptions={Role.fetchOptions}
            skeletonShow={isLoading}
            readOnly={disabled}
            multiple
            autocompleteXs={6}
            dataTestId='Роли'
          />
        </Grid>
      </Container>

      <FormButtons loading={isSubmitting} isCreating={isCreating} />
    </FormProvider>
  )
}
