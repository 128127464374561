import { Option } from 'coloquent/dist/Option'
import { useCallback } from 'react'
import { Inspection } from '~/shared/api'
import { AsyncGrid, FetchRowsFn } from '~/shared/ui/AsyncGrid'
import { columns } from './columns'
import { filters } from './filters'
import { GridRow } from './types'

export const ListInspection = () => {
  const fetchRows = useCallback<FetchRowsFn<GridRow>>(
    async (page, pageSize, filters = [], sort) => {
      let builder = Inspection.limit(pageSize)
        .with('car')
        .with('car.brandModel')
        .with('reason')
        .with('responsible')

      sort
        ? (builder = builder.orderBy(sort))
        : (builder = builder.orderBy('-updatedAt'))

      filters.forEach(({ key, value }) => {
        if (key === 'responsibleName') {
          builder
            .getQuery()
            .addOption(new Option('filter[responsible][name]', value))
          return
        }
        if (key === 'carId') {
          builder.getQuery().addOption(new Option('filter[car][id]', value))
          return
        }
        if (key === 'carVin') {
          builder.getQuery().addOption(new Option('filter[car][vin]', value))
          return
        }
        if (key === 'reasonId') {
          builder.getQuery().addOption(new Option('filter[reason][id]', value))
          return
        }
        if (key === 'regionId') {
          builder
            .getQuery()
            .addOption(new Option('filter[subdivision][region][id]', value))
          return
        }
        if (key === 'subdivisionId') {
          builder
            .getQuery()
            .addOption(new Option('filter[subdivision][id]', value))
          return
        }
        builder = builder.where(key, value)
      })

      const response = await builder.get(page)
      const data = response.getData()
      const rows = data.map((inspection: Inspection) => ({
        ...inspection.getAttributes(),
        id: inspection.getApiId() as string,
        plateNumber: inspection?.getCar()?.getPlateNumber(),
        carExtId: inspection?.getCar()?.getExtId(),
        carBrandModel: inspection?.getCar()?.getBrandModel()?.getTitle(),
        reason: inspection?.getReason()?.getTitle(),
        mechanicFullName: inspection?.getResponsible().getName(),
        finishedAt: inspection?.getFinishedAt(),
      }))

      const httpClientResponse = response.getHttpClientResponse()
      const axiosResponse = httpClientResponse?.getUnderlying()
      const total = axiosResponse?.data?.meta?.page?.total || 0

      return {
        rows,
        total,
      }
    },
    [],
  )
  return (
    <AsyncGrid<GridRow>
      title='Осмотры'
      gridKey='inspections-list'
      fetchRows={fetchRows}
      columns={columns}
      filters={filters}
    />
  )
}
