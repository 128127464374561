import { Box, BoxProps } from '@mui/joy'
import { forwardRef, ReactNode } from 'react'

type ColumnValueProps = BoxProps & {
  children: string | ReactNode
}

const ColumnValue = forwardRef<HTMLElement, ColumnValueProps>(
  ({ children, ...props }, ref) => (
    <Box
      {...props}
      ref={ref}
      sx={{
        ...props.sx,
        textOverflow: 'ellipsis !important',
        overflow: 'hidden !important',
        display: 'inline-block',
      }}
    >
      {children}
    </Box>
  ),
)

ColumnValue.displayName = 'ColumnValue'

export { ColumnValue }
