import { GridColDef } from '@mui/x-data-grid'
import { GridRow } from './types'

export const columns: GridColDef<GridRow>[] = [
  {
    headerName: 'Нарушение',
    field: 'title',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Сумма',
    field: 'cost',
    flex: 1,
    renderCell: ({ value }) => value && value.toLocaleString(),
  },
]
