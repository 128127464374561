import { orange } from '@mui/material/colors'
import { Damage } from '~/entities/Inspection/ui/Damage'
import { CarDamageExtendsCodeEnum } from '~/shared/config/enums'
import { PolygonMarker } from '~/shared/ui/Polygon/types'

const getLevel = (carDamageExtent?: Damage['carDamageExtent']) => {
  switch (carDamageExtent?.code) {
    case CarDamageExtendsCodeEnum.LIGHT:
      return 'Л'
    case CarDamageExtendsCodeEnum.MEDIUM:
      return 'С'
    case CarDamageExtendsCodeEnum.HEAVY:
      return 'Т'
    default:
      return undefined
  }
}

export const mapDamagesToMarkers = (
  damages?: Damage[],
  old?: boolean,
): PolygonMarker[] => {
  if (!damages) return []

  return damages.map((damage) => ({
    level: getLevel(damage.carDamageExtent),
    left: damage.left,
    top: damage.top,
    color: old ? orange[500] : undefined,
    id: damage.id as UniqueId,
  }))
}
