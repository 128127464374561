import { useStore, useStoreMap, useGate } from 'effector-react'
import { Form, roadAccidentModel } from 'src/entities/RoadAccident'
import { usePermissions } from '~/entities/viewer'
import {
  accidentRegistrationTypeEnumLabels,
  icReviewStatusEnumLabels,
  incidentResponsibleEnumLabels,
  insurancePolicyStatusEnumLabels,
} from '~/shared/config/constants'
import { RoadAccidentsStatusEnum } from '~/shared/config/enums'
import { formatDateTimeForUI } from '~/shared/lib/date'
import {
  formSubmitted,
  Gate,
  $roadAccident,
  roadAccidentUpdateFx,
} from './model'

type EditCounterpartyProps = {
  roadAccidentId: string
}

export function EditRoadAccident({ roadAccidentId }: EditCounterpartyProps) {
  useGate(Gate, { roadAccidentId })
  const { canRoadAccidentsUpdate } = usePermissions()

  const isLoading = useStore(roadAccidentModel.requestFx.pending)
  const isSaving = useStore(roadAccidentUpdateFx.pending)

  const isOpenedStatus = useStoreMap(
    $roadAccident,
    (roadAccident) =>
      roadAccident?.getStatus() === RoadAccidentsStatusEnum.OPENED,
  )

  const defaultValues = useStoreMap($roadAccident, (roadAccident) => {
    const accidentRegistrationType = roadAccident?.getAccidentRegistrationType()
    const culpability = roadAccident?.getCulpability()
    const insurancePolicyStatus = roadAccident?.getInsurancePolicyStatus()
    const insurancePolicyStatus2 = roadAccident?.getInsurancePolicyStatus2()
    const insurancePolicyStatus3 = roadAccident?.getInsurancePolicyStatus3()
    const icReviewStatus = roadAccident?.getIcReviewStatus()
    const car = roadAccident?.getCar()
    const inspection = roadAccident?.getInspection()
    const inspectionUpdatedAt = inspection?.getUpdatedAt()
    const carPlateNumber = inspection?.getPlateNumber()

    return {
      ...roadAccident?.getAttributes(),
      carPlateNumber,
      carExtId: car?.getExtId(),
      carSubdivision: roadAccident?.getSubdivision()?.getTitle(),
      carStatus: car?.getStatus(),
      inspection: `Осмотр [${carPlateNumber}] от ${
        inspectionUpdatedAt ? formatDateTimeForUI(inspectionUpdatedAt) : ''
      }`,
      inspectionId: inspection?.getApiId(),
      accidentRegistrationType: accidentRegistrationType
        ? {
            id: accidentRegistrationType,
            label: accidentRegistrationTypeEnumLabels[accidentRegistrationType],
          }
        : null,
      culpability: culpability
        ? {
            id: culpability,
            label: incidentResponsibleEnumLabels[culpability],
          }
        : null,
      insurancePolicyStatus: insurancePolicyStatus
        ? {
            id: insurancePolicyStatus,
            label: insurancePolicyStatusEnumLabels[insurancePolicyStatus],
          }
        : null,
      insurancePolicyStatus2: insurancePolicyStatus2
        ? {
            id: insurancePolicyStatus2,
            label: insurancePolicyStatusEnumLabels[insurancePolicyStatus2],
          }
        : null,
      insurancePolicyStatus3: insurancePolicyStatus3
        ? {
            id: insurancePolicyStatus3,
            label: insurancePolicyStatusEnumLabels[insurancePolicyStatus3],
          }
        : null,
      icReviewStatus: icReviewStatus
        ? {
            id: icReviewStatus,
            label: icReviewStatusEnumLabels[icReviewStatus],
          }
        : null,
      files: roadAccident?.getFiles(),
    }
  })

  return (
    <Form
      isLoading={isLoading}
      defaultValues={defaultValues}
      onSuccess={formSubmitted}
      isSubmitting={isSaving}
      canEdit={isOpenedStatus && canRoadAccidentsUpdate}
    />
  )
}
