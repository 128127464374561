import { GridColDef } from '@mui/x-data-grid'
import { ColumnLink } from '~/shared/ui/AsyncGrid'
import { GridRow } from './types'

export const columns: GridColDef<GridRow>[] = [
  {
    headerName: 'Название',
    field: 'name',
    sortable: false,
    flex: 1,
    minWidth: 240,
    renderCell: ({ row }) => <ColumnLink to={row.id}>{row.name}</ColumnLink>,
  },
  {
    headerName: 'ИНН',
    field: 'inn',
    sortable: false,
    flex: 1,
  },
  {
    headerName: 'КПП',
    field: 'kpp',
    sortable: false,
    flex: 1,
  },
  {
    headerName: 'ОГРН',
    field: 'ogrn',
    sortable: false,
    flex: 1,
  },
]
