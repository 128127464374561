import { Box, styled } from '@mui/joy'
import { myPalette } from '~/shared/lib/theme'

export const ItemWrapper = styled(Box)`
  display: flex;
  align-items: center;
`

export const Degree = styled(Box)`
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
  border-radius: 50px;
  background: ${myPalette.gray['100']};
  color: ${myPalette.gray['700']};
`

export const Img = styled('img')`
  margin-right: 6px;
  width: 25px;
`

export const Circle = styled(Box)`
  width: 25px;
  height: 25px;
  min-width: 25px;
  min-height: 25px;
  border-radius: 100%;
  margin-right: 6px;
`
