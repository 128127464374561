import { ToOneRelation } from 'coloquent'
import * as z from 'zod'
import { Car } from '~/shared/api/car'
import { ApiModel } from '~/shared/api/core'
import { Inspection } from '~/shared/api/inspection'
import { RepairOrder } from '~/shared/api/repairOrder'
import { Subdivision } from '~/shared/api/subdivision'
import { ThumbType } from '~/shared/api/types'
import {
  AccidentRegistrationTypeEnum,
  IcReviewStatusEnum,
  IncidentResponsibleEnum,
  InsurancePolicyStatusEnum,
  RoadAccidentsStatusEnum,
} from '~/shared/config/enums'
import {
  dateSchema,
  dateSchemaOptional,
  enumOptionSchema,
  fileIdsSchema,
  filesSchema,
  priceRequiredWithZeroScheme,
} from '~/shared/lib/schemas'

import { User } from './user'

const attributeSchema = z.object({
  mileage: z.number(),
  status: enumOptionSchema(RoadAccidentsStatusEnum),
  accidentDate: dateSchemaOptional,
  accidentTime: z
    .union([
      z
        .string()
        .min(5, 'Укажите правильный формат времени ЧЧ:ММ')
        .max(5, 'Укажите правильный формат времени ЧЧ:ММ'),
      z.string().length(0),
    ])
    .optional()
    .nullable(),
  debtClosingDate: dateSchemaOptional,
  number: z.string(),
  evacuation: z.boolean().optional().nullable().default(false),
  accidentPlace: z.string().nullable(),
  driverFullName: z.string(),
  comment: z.string().nullable(),
  repairEstimatedCost: priceRequiredWithZeroScheme,
  agreementWithDriver: z.boolean().optional().nullable().default(false),
  reimbursedByCulprit: priceRequiredWithZeroScheme,
  reimbursedByDriver: priceRequiredWithZeroScheme,
  remainingAmount: z.number(),
  reimbursedBySecurityService: priceRequiredWithZeroScheme,
  accidentParticipantsNumber: z
    .string()
    .or(z.number())
    .optional()
    .nullable()
    .transform((value) => (value ? Number(value) : null)),
  culpability: enumOptionSchema(IncidentResponsibleEnum),
  accidentRegistrationType: enumOptionSchema(AccidentRegistrationTypeEnum)
    .optional()
    .nullable(),
  protocol: z.boolean().optional().nullable().default(false),
  protocolReceivingDate: dateSchemaOptional,
  insuranceCompany: z.string().nullable(),
  insurancePolicyStatus: enumOptionSchema(InsurancePolicyStatusEnum)
    .optional()
    .nullable(),
  insurancePolicyNumber: z.string().nullable(),
  insuranceCompany2: z.string().nullable(),
  insurancePolicyStatus2: enumOptionSchema(InsurancePolicyStatusEnum)
    .optional()
    .nullable(),
  insurancePolicyNumber2: z.string().nullable(),
  insuranceCompany3: z.string().nullable(),
  insurancePolicyStatus3: enumOptionSchema(InsurancePolicyStatusEnum)
    .optional()
    .nullable(),
  insurancePolicyNumber3: z.string().nullable(),
  lossNumber: z
    .string()
    .or(z.number())
    .optional()
    .nullable()
    .transform((value) => (value ? String(value) : null)),
  icAppealDate: dateSchemaOptional,
  icInspectionDate: dateSchemaOptional,
  icReviewStatus: enumOptionSchema(IcReviewStatusEnum).optional().nullable(),
  documentsSubmissionIc: z.string().nullable(),
  files: filesSchema,
  fileIds: fileIdsSchema,
})

const schema = z
  .object({
    createdAt: dateSchema,
    updatedAt: dateSchema,
  })
  .merge(attributeSchema)

export type RoadAccidentAttributes = z.infer<typeof attributeSchema>

export class RoadAccident extends ApiModel<
  typeof schema,
  RoadAccidentAttributes
> {
  static jsonApiType = 'road-accidents'
  static schema = schema

  getStatus(): RoadAccidentsStatusEnum {
    return this.getAttribute('status')
  }
  getNumber() {
    return this.getAttribute('number')
  }
  getCreatedAt() {
    return this.getAttribute('createdAt')
  }
  getMileage() {
    return this.getAttribute('mileage')
  }
  getAccidentRegistrationType(): AccidentRegistrationTypeEnum {
    return this.getAttribute('accidentRegistrationType')
  }
  getCulpability(): IncidentResponsibleEnum {
    return this.getAttribute('culpability')
  }
  getInsurancePolicyStatus(): InsurancePolicyStatusEnum {
    return this.getAttribute('insurancePolicyStatus')
  }
  getInsurancePolicyStatus2(): InsurancePolicyStatusEnum {
    return this.getAttribute('insurancePolicyStatus2')
  }
  getInsurancePolicyStatus3(): InsurancePolicyStatusEnum {
    return this.getAttribute('insurancePolicyStatus3')
  }
  getIcReviewStatus(): IcReviewStatusEnum {
    return this.getAttribute('icReviewStatus')
  }

  car(): ToOneRelation<Car, this> {
    return this.hasOne(Car)
  }
  getCar(): Car {
    return this.getRelation('car')
  }

  subdivision(): ToOneRelation<Subdivision, this> {
    return this.hasOne(Subdivision)
  }
  getSubdivision(): Subdivision {
    return this.getRelation('subdivision')
  }
  getFiles(): ThumbType[] {
    return this.getAttribute('files') || []
  }

  user(): ToOneRelation<User, this> {
    return this.hasOne(User)
  }
  getUser(): User {
    return this.getRelation('user')
  }

  inspection(): ToOneRelation<Inspection, this> {
    return this.hasOne(Inspection)
  }
  getInspection(): Inspection {
    return this.getRelation('inspection')
  }

  repairOrder(): ToOneRelation<RepairOrder, this> {
    return this.hasOne(RepairOrder)
  }
  getRepairOrder(): RepairOrder {
    return this.getRelation('repairOrder')
  }

  static async savePhoto(file: File): Promise<ThumbType> {
    const url = `${this.getJsonApiUrl()}/actions/upload-file`

    const data = new FormData()
    data.append('file', file)

    const client = RoadAccident.httpClient.getImplementingClient()

    const response = await client.post<ThumbType>(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response?.data
  }

  getOption(): { id: UniqueId; label: string } {
    return {
      id: this.getApiId() as UniqueId,
      label: this.getNumber(),
    }
  }
  getOptionWithLinkToRoadAccident(): {
    id: UniqueId
    label: string
    to: string
    tooltipText: string
  } {
    const number = this.getNumber()
    return {
      id: this.getApiId() as UniqueId,
      label: number,
      to: `/road-accidents/${this.getApiId()}`,
      tooltipText: `Перейти в карточку ДТП № ${number}`,
    }
  }
  static async fetchOptions(search: string) {
    let builder = RoadAccident.limit(50)

    if (search) {
      builder = builder.where('number', search)
    }

    const response = await builder.get(1)
    return response.getData().map((o) => o.getOption())
  }
}
