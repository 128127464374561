import { createDomain } from 'effector'
import { useStoreMap } from 'effector-react'
import * as z from 'zod'
import { AxiosErrorType, RoadAccident, ThumbType } from '~/shared/api'
import { OptionEnum } from '~/shared/config/constants'
import {
  AccidentRegistrationTypeEnum,
  IcReviewStatusEnum,
  IncidentResponsibleEnum,
  InsurancePolicyStatusEnum,
} from '~/shared/config/enums'
import { createCache } from '~/shared/lib/mapCacheFactory'

export const formSchema = RoadAccident.schema.pick({
  accidentDate: true,
  accidentTime: true,
  debtClosingDate: true,
  evacuation: true,
  accidentPlace: true,
  comment: true,
  agreementWithDriver: true,
  reimbursedByDriver: true,
  reimbursedByCulprit: true,
  culpability: true,
  accidentParticipantsNumber: true,
  accidentRegistrationType: true,
  protocol: true,
  protocolReceivingDate: true,
  insuranceCompany: true,
  insurancePolicyStatus: true,
  insurancePolicyNumber: true,
  insuranceCompany2: true,
  insurancePolicyStatus2: true,
  insurancePolicyNumber2: true,
  insuranceCompany3: true,
  insurancePolicyStatus3: true,
  insurancePolicyNumber3: true,
  lossNumber: true,
  icAppealDate: true,
  icInspectionDate: true,
  icReviewStatus: true,
  reimbursedBySecurityService: true,
  files: true,
})
export type SchemaType = z.infer<typeof formSchema>
export type FormValues = Omit<
  SchemaType,
  | 'culpability'
  | 'accidentRegistrationType'
  | 'insurancePolicyStatus'
  | 'insurancePolicyStatus2'
  | 'insurancePolicyStatus3'
  | 'icReviewStatus'
  | 'files'
> & {
  culpability:
    | OptionEnum<IncidentResponsibleEnum>
    | IncidentResponsibleEnum
    | null
  accidentRegistrationType:
    | OptionEnum<AccidentRegistrationTypeEnum>
    | AccidentRegistrationTypeEnum
    | null
  insurancePolicyStatus:
    | OptionEnum<InsurancePolicyStatusEnum>
    | InsurancePolicyStatusEnum
    | null
  insurancePolicyStatus2:
    | OptionEnum<InsurancePolicyStatusEnum>
    | InsurancePolicyStatusEnum
    | null
  insurancePolicyStatus3:
    | OptionEnum<InsurancePolicyStatusEnum>
    | InsurancePolicyStatusEnum
    | null
  icReviewStatus: OptionEnum<IcReviewStatusEnum> | IcReviewStatusEnum | null
  carExtId?: string
  carPlateNumber?: string
  carSubdivision?: string
  carStatus?: string
  inspection?: string
  inspectionId?: string
  files?: ThumbType[]
}

export const domain = createDomain('entities.roadAccident')

export const requestFx = domain.createEffect<UniqueId, RoadAccident>({
  handler: fetchRoadAccident,
})

export const saveFx = domain.createEffect<
  RoadAccident,
  RoadAccident,
  AxiosErrorType
>({
  async handler(roadAccident) {
    await roadAccident.save()
    return fetchRoadAccident(roadAccident.getApiId() as UniqueId)
  },
})

const {
  $cache: $roadAccidentCache,
  useCache: useRoadAccidentCache,
  updateCache,
} = createCache<RoadAccident>({
  domain,
  getEntityId: (roadAccident) => roadAccident.getApiId() as UniqueId,
})
export { $roadAccidentCache, useRoadAccidentCache }

$roadAccidentCache
  .on(requestFx.doneData, (cache, roadAccident) =>
    updateCache(cache, [roadAccident]),
  )
  .on(saveFx.doneData, (cache, roadAccident) =>
    updateCache(cache, [roadAccident], true),
  )

export const $roadAccidentError = domain
  .createStore<Record<UniqueId, Error>>({})
  .on([requestFx.fail], (store, { error, params: id }) => ({
    [id]: error,
    ...store,
  }))
export const useRoadAccidentError = (id: UniqueId) =>
  useStoreMap($roadAccidentError, (errors) => errors[id])

async function fetchRoadAccident(id: UniqueId) {
  const response = await RoadAccident.with('inspection')
    .with('car')
    .with('car.brandModel')
    .with('subdivision')
    .with('repairOrder')
    .find(id)
  return response.getData() as RoadAccident
}
