import { createDomain } from 'effector'
import { useStoreMap } from 'effector-react'
import * as z from 'zod'
import {
  AxiosErrorType,
  CarWithRelationOption,
  RepairOrder,
  ThumbType,
} from '~/shared/api'
import { Option, OptionEnum } from '~/shared/config/constants'
import {
  RepairOrderPaymentStatusEnum,
  RepairOrderStatusEnum,
} from '~/shared/config/enums'
import { createCache } from '~/shared/lib/mapCacheFactory'

export const formSchema = RepairOrder.schema.pick({
  mileage: true,
  status: true,
  paymentStatus: true,
  reason: true,
  startedAt: true,
  finishedAt: true,
  materialsCost: true,
  workCost: true,
  carOption: true,
  counterpartyOption: true,
  repairTypesOptions: true,
  files: true,
  counterpartyNumber: true,
  roadAccidentIds: true,
})

export type SchemaType = z.infer<typeof formSchema>
export type FormValues = Omit<
  SchemaType,
  | 'status'
  | 'paymentStatus'
  | 'carOption'
  | 'counterpartyOption'
  | 'repairTypesOptions'
  | 'files'
  | 'roadAccidentIds'
> & {
  status: OptionEnum<RepairOrderStatusEnum> | RepairOrderStatusEnum | null
  paymentStatus:
    | OptionEnum<RepairOrderPaymentStatusEnum>
    | RepairOrderPaymentStatusEnum
    | null
  carOption: CarWithRelationOption | UniqueId | null
  counterpartyOption: Option | UniqueId | null
  repairTypesOptions: Option[] | UniqueId[]
  vin?: string
  brandModel?: string
  subdivision?: string
  userName?: string | null
  files?: ThumbType[]
  roadAccidentIds: Option[] | UniqueId[] | null
}

export const domain = createDomain('entities.repairOrder')

export const requestFx = domain.createEffect<UniqueId, RepairOrder>({
  handler: fetchRepairOrder,
})

export const saveFx = domain.createEffect<
  RepairOrder,
  RepairOrder,
  AxiosErrorType
>({
  async handler(repairOrder) {
    await repairOrder.save()
    return fetchRepairOrder(repairOrder.getApiId() as UniqueId)
  },
})

const {
  $cache: $repairOrderCache,
  useCache: useRepairOrderCache,
  updateCache,
} = createCache<RepairOrder>({
  domain,
  getEntityId: (repairOrder) => repairOrder.getApiId() as UniqueId,
})
export { $repairOrderCache, useRepairOrderCache }

$repairOrderCache
  .on(requestFx.doneData, (cache, repairOrder) =>
    updateCache(cache, [repairOrder]),
  )
  .on(saveFx.doneData, (cache, repairOrder) =>
    updateCache(cache, [repairOrder], true),
  )

export const $repairOrderError = domain
  .createStore<Record<UniqueId, Error>>({})
  .on([requestFx.fail], (store, { error, params: id }) => ({
    [id]: error,
    ...store,
  }))
export const useRepairOrderError = (id: UniqueId) =>
  useStoreMap($repairOrderError, (errors) => errors[id])

async function fetchRepairOrder(id: UniqueId) {
  const response = await RepairOrder.with('car')
    .with('car.brandModel')
    .with('car.subdivision')
    .with('counterparty')
    .with('repairTypes')
    .with('user')
    .with('roadAccidents')
    .find(id)
  return response.getData() as RepairOrder
}
