import { GridColDef } from '@mui/x-data-grid'
import { getStatusColor } from 'src/entities/RoadAccident'
import { roadAccidentsEnumLabels } from '~/shared/config/constants'
import { TMS_DOMAIN } from '~/shared/config/env'
import { ColumnDate, ColumnLink } from '~/shared/ui/AsyncGrid'
import { ChipStatus } from '~/shared/ui/Chips'
import { GridRow } from './types'

export const columns: GridColDef<GridRow>[] = [
  {
    headerName: 'Номер',
    field: 'number',
    minWidth: 160,
    renderCell: ({ row }) => <ColumnLink to={row.id}>{row.number}</ColumnLink>,
  },
  {
    headerName: 'Дата',
    field: 'createdAt',
    sortable: false,
    minWidth: 180,
    renderCell: ({ value }) => <ColumnDate value={value} type='date' />,
  },
  {
    headerName: 'Гос. номер',
    field: 'carPlateNumber',
    sortable: false,
    minWidth: 180,
    renderCell: ({ row }) => (
      <ColumnLink href={`${TMS_DOMAIN}cars/${row.carExtId}`}>
        {row.carPlateNumber}
      </ColumnLink>
    ),
  },
  {
    headerName: 'Марка и модель ТС',
    field: 'carBrandModel',
    sortable: false,
    minWidth: 210,
  },
  {
    headerName: 'Пробег',
    field: 'mileage',
    sortable: false,
    minWidth: 120,
  },
  {
    headerName: 'Подразделение ТС',
    field: 'carSubdivision',
    sortable: false,
    minWidth: 180,
  },
  {
    headerName: 'Статус',
    field: 'status',
    sortable: false,
    minWidth: 180,
    renderCell: ({ row }) => {
      return (
        <ChipStatus
          label={roadAccidentsEnumLabels[row.status]}
          variant={getStatusColor(row.status)}
        />
      )
    },
  },
  {
    headerName: 'Ответственный',
    field: 'responsible',
    sortable: false,
    minWidth: 180,
  },
  {
    headerName: 'Водитель ТС',
    field: 'driverFullName',
    sortable: false,
    minWidth: 180,
  },
  {
    headerName: 'Статус ТС',
    field: 'carStatus',
    sortable: false,
    minWidth: 180,
  },
  {
    headerName: 'Заказ-наряд',
    field: 'repairOrder',
    sortable: false,
    minWidth: 180,
    renderCell: ({ value }) => (
      <ChipStatus
        variant={value ? 'green' : 'red'}
        label={value ? 'Да' : 'Нет'}
      />
    ),
  },
]
