import { ToOneRelation } from 'coloquent'
import * as z from 'zod'
import {
  Car,
  RepairType,
  Counterparty,
  User,
  ThumbType,
  RoadAccident,
} from '~/shared/api'
import { ApiModel, ToManyRelation } from '~/shared/api/core'
import { Option } from '~/shared/config/constants'
import {
  RepairOrderPaymentStatusEnum,
  RepairOrderStatusEnum,
} from '~/shared/config/enums'
import {
  dateSchema,
  enumOptionSchema,
  fileIdsSchema,
  filesSchema,
  numberRequiredScheme,
  uuidOptionSchema,
  uuidOptionsSchema,
} from '~/shared/lib/schemas'
import { isString, sanitizeStringNumber } from '~/shared/lib/utils'

const attributeSchema = z.object({
  mileage: numberRequiredScheme,
  status: enumOptionSchema(RepairOrderStatusEnum),
  paymentStatus: enumOptionSchema(RepairOrderPaymentStatusEnum),
  reason: z.string().nullable(),
  startedAt: z.string().max(0).or(dateSchema).optional().nullable(),
  finishedAt: z.string().max(0).or(dateSchema).optional().nullable(),
  materialsCost: z
    .string()
    .or(z.number())
    .transform(sanitizeStringNumber)
    .optional(),
  workCost: numberRequiredScheme,
  totalCost: numberRequiredScheme,
  number: z.number(),
  files: filesSchema,
  fileIds: fileIdsSchema,
  counterpartyNumber: z.string().optional(),
  roadAccidentIds: uuidOptionsSchema.optional(),
})
const relationsSchema = z.object({
  carOption: uuidOptionSchema,
  counterpartyOption: uuidOptionSchema,
  repairTypesOptions: uuidOptionsSchema.min(1, 'Обязательное поле'),
})
const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributeSchema)
  .merge(relationsSchema)

export type RepairOrderAttributes = z.infer<typeof attributeSchema>

export class RepairOrder extends ApiModel<
  typeof schema,
  RepairOrderAttributes
> {
  static jsonApiType = 'repair-orders'
  static schema = schema

  getNumber(): number {
    return this.getAttribute('number')
  }
  getStatus(): RepairOrderStatusEnum {
    return this.getAttribute('status')
  }
  getPaymentStatus(): RepairOrderPaymentStatusEnum {
    return this.getAttribute('paymentStatus')
  }
  getFiles(): ThumbType[] {
    return this.getAttribute('files') || []
  }

  car(): ToOneRelation<Car, this> {
    return this.hasOne(Car)
  }
  getCar(): Car {
    return this.getRelation('car')
  }
  setCar(id: UniqueId) {
    const car = new Car()
    car.setApiId(id)
    this.setRelation('car', car)
  }

  counterparty(): ToOneRelation<Counterparty, this> {
    return this.hasOne(Counterparty)
  }
  getCounterparty(): Counterparty {
    return this.getRelation('counterparty')
  }
  setCounterparty(id: UniqueId) {
    const counterparty = new Counterparty()
    counterparty.setApiId(id)
    this.setRelation('counterparty', counterparty)
  }

  repairTypes(): ToManyRelation<RepairType, this> {
    return this.hasMany(RepairType)
  }
  getRepairTypes(): RepairType[] {
    return this.getRelation('repairTypes') ?? []
  }
  setRepairTypes(ids: UniqueId[] | Option[]) {
    let repairTypes: RepairType[] = []
    ids.map((repairTypeId) => {
      const repairType = new RepairType()
      if (isString(repairTypeId)) {
        repairType.setApiId(repairTypeId)
        repairTypes = repairTypes.concat(repairType)
      }
    })
    this.setRelation('repairTypes', repairTypes)
  }

  user(): ToOneRelation<User, this> {
    return this.hasOne(User)
  }
  getUser(): User {
    return this.getRelation('user')
  }

  roadAccidents(): ToManyRelation<RoadAccident, this> {
    return this.hasMany(RoadAccident)
  }
  getRoadAccidents(): RoadAccident[] {
    return this.getRelation('roadAccidents')
  }

  static async savePhoto(file: File): Promise<ThumbType> {
    const url = `${this.getJsonApiUrl()}/actions/upload-file`

    const data = new FormData()
    data.append('file', file)

    const client = RepairOrder.httpClient.getImplementingClient()

    const response = await client.post<ThumbType>(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return response?.data
  }

  getOption(): Option {
    return {
      id: this.getApiId() as UniqueId,
      label: this.getNumber(),
    }
  }

  static async fetchOptions(search: string) {
    let builder = RepairOrder.limit(50)

    if (search) {
      builder = builder.where('number', search)
    }

    const response = await builder.get(1)
    return response.getData().map((o) => o.getOption())
  }
}
