import * as z from 'zod'
import { ApiModel } from '~/shared/api/core'
import {
  dateSchema,
  innRequiredSchema,
  kppRequiredSchema,
  ogrnRequiredSchema,
} from '~/shared/lib/schemas'

const attributeSchema = z.object({
  name: z.string().trim().min(1, 'Обязательное поле'),
  ogrn: ogrnRequiredSchema,
  inn: innRequiredSchema,
  kpp: kppRequiredSchema.optional(),
})
const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributeSchema)

export type CounterpartyAttributes = z.infer<typeof attributeSchema>

export class Counterparty extends ApiModel<
  typeof schema,
  CounterpartyAttributes
> {
  static jsonApiType = 'counterparties'
  static schema = schema

  getName(): string {
    return this.getAttribute('name')
  }

  getOption(): { id: UniqueId; label: string } {
    return {
      id: this.getApiId() as UniqueId,
      label: this.getName(),
    }
  }
  static async fetchOptions(search: string) {
    const response = await Counterparty.where('name', search).get(1)
    return response.getData().map((o) => o.getOption())
  }
}
