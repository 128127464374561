import { useStore } from 'effector-react'
import { Form, FormValues } from '~/entities/RepairOrder'
import { formSubmitted, createFx } from './model'

type AddRepairOrderProps = {
  isLoading?: boolean
  defaultValues?: Partial<FormValues>
}

export const AddRepairOrder = ({
  isLoading,
  defaultValues,
}: AddRepairOrderProps) => {
  const isSubmitting = useStore(createFx.pending)

  return (
    <Form
      isSubmitting={isSubmitting}
      isLoading={isLoading}
      isCreating
      defaultValues={defaultValues}
      onSuccess={formSubmitted}
    />
  )
}
