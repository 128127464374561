import {
  RepairOrderPaymentStatusEnum,
  RepairOrderStatusEnum,
} from '~/shared/config/enums'

export const getStatusColor = (status?: RepairOrderStatusEnum) => {
  if (status === RepairOrderStatusEnum.OPENED) return 'gray'
  if (status === RepairOrderStatusEnum.AWAITING_REPAIR) return 'brand'
  if (status === RepairOrderStatusEnum.AWAITING_SPARE_PARTS) return 'purple'
  if (status === RepairOrderStatusEnum.AT_WORK) return 'green'
  if (status === RepairOrderStatusEnum.COMPLETED) return 'yellow'

  return 'gray'
}

export const getPaymentStatusColor = (
  paymentStatus?: RepairOrderPaymentStatusEnum,
) => {
  if (paymentStatus === RepairOrderPaymentStatusEnum.PAID) return 'green'
  if (paymentStatus === RepairOrderPaymentStatusEnum.NOT_PAID) return 'red'

  return 'gray'
}
