import { Box, ListItemButton } from '@mui/joy'
import { Link, matchPath, useLocation } from 'react-router-dom'
import { env } from '~/shared/config'
import { AccountMenu } from '../AccountMenu'
import { useHeader } from '../hooks/useHeader'
import { StickyBlock, ListItem, List, Wrapper } from './styled'

export function Header() {
  const { pathname } = useLocation()

  const items = useHeader()
  return (
    <StickyBlock>
      <Wrapper>
        <List orientation='horizontal'>
          <ListItem sx={{ padding: 0 }}>
            <ListItemButton
              sx={{ padding: '0 !important', margin: '0 16px 0 0' }}
              component='a'
              href={env.NAVIGATION_DOMAIN}
            >
              <Box component='img' width='40px' height='40px' src='/logo.svg' />
            </ListItemButton>
          </ListItem>

          {items.map(({ label, to, parent }) => (
            <ListItem key={label}>
              <ListItemButton
                component={Link}
                to={to}
                selected={!!matchPath(`${parent || to}/*`, pathname)}
              >
                {label}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <AccountMenu />
      </Wrapper>
    </StickyBlock>
  )
}
