import * as z from 'zod'

import { Option } from '~/shared/config/constants'
import { CarDetailTypeEnum } from '~/shared/config/enums'
import { dateSchema } from '~/shared/lib/schemas'
import { ApiModel } from './core'

const attributesSchema = z.object({
  title: z.string(),
  type: z.nativeEnum(CarDetailTypeEnum),
  code: z.string(),
})

const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributesSchema)

export type CarDetailAttributes = z.infer<typeof attributesSchema>
export type CarDetailOption = Option & {
  code: CarDetailAttributes['code']
  type: CarDetailTypeEnum
}

export class CarDetail extends ApiModel<typeof schema, CarDetailAttributes> {
  static jsonApiType = 'car-details'

  static schema = schema

  getTitle(): string {
    return this.getAttribute('title')
  }

  getCode(): CarDetailAttributes['code'] {
    return this.getAttribute('code')
  }

  getType(): CarDetailTypeEnum {
    return this.getAttribute('type')
  }

  getOption(): CarDetailOption {
    return {
      id: this.getApiId() as UniqueId,
      label: this.getTitle(),
      code: this.getCode(),
      type: this.getType(),
    }
  }
}
