import { useStore, useStoreMap } from 'effector-react'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { EditRole, roleEmployeeModal } from '~/features/Roles/EditRole'
import { roleModel } from '~/entities/Role'
import { Breadcrumbs, BreadcrumbsList } from '~/shared/ui/Breadcrumbs'
import { Container, PageContainer, PageTitle } from '~/shared/ui/Layout'
import { NotFound } from '~/shared/ui/NotFound'

export const EditRolePage = () => {
  const { roleId } = useParams<{ roleId: string }>()

  const isLoading = useStore(roleModel.requestFx.pending)
  const roleName = useStoreMap(roleEmployeeModal.$role, (employee) =>
    employee?.getName(),
  )

  const breadcrumbs: BreadcrumbsList = useMemo(
    () => [
      { title: 'Роли', link: '/settings/roles' },
      { title: roleName as string },
    ],
    [roleName],
  )

  const error = roleModel.useRoleError(roleId as string)

  if (!roleId || error) {
    return <NotFound message='Не удалось загрузить роль' />
  }

  return (
    <PageContainer title='Карточка роли'>
      <Container isHeader>
        <Breadcrumbs list={breadcrumbs} lastLoading={isLoading} />
        <PageTitle loading={isLoading}>{roleName}</PageTitle>
      </Container>
      <EditRole roleId={roleId} />
    </PageContainer>
  )
}
