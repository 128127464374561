import { Typography } from '@mui/joy'
import { useStore } from 'effector-react'
import { useFormContext } from 'react-hook-form'
import { ThumbType } from '~/shared/api'
import { myPalette } from '~/shared/lib/theme'
import { Dropzone } from '~/shared/ui/Dropzone'
import { saveFiles, saveFilesFx } from './model'

type FilesProps = {
  isLoading?: boolean
  disabled?: boolean
}

export function Files({ isLoading, disabled }: FilesProps) {
  const isSubmitting = useStore(saveFilesFx.pending)
  const { setValue, getValues, watch } = useFormContext()

  const thumbs = watch('files')

  const handleSetFile = (thumb: ThumbType) => {
    const files = getValues('files') || []
    setValue('files', [...files, thumb])
  }

  const handleDropAccepted = (files: File[]) => {
    saveFiles({ files, fn: handleSetFile })
  }

  const handleThumbDelete = (uuid: UniqueId) => {
    const files = getValues('files') || []
    setValue(
      'files',
      files.filter((file: ThumbType) => file.uuid !== uuid),
    )
  }

  return (
    <Dropzone
      disabled={disabled}
      canDelete={!disabled}
      canCreate={!disabled}
      isLoading={isLoading}
      isSubmitting={isSubmitting}
      empty={
        <Typography
          textAlign='center'
          fontSize={14}
          sx={{ color: myPalette.gray['600'] }}
        >
          Нет файлов
        </Typography>
      }
      accept={{
        'image/jpeg': ['.jpeg', '.jpg'],
        'image/png': ['.png'],
        'application/pdf': ['.pdf'],
        'application/msword': ['.doc'],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          ['.docx'],
        'application/vnd.ms-excel': ['.xls'],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
          '.xlsx',
        ],
      }}
      onDropAccepted={handleDropAccepted}
      thumbs={thumbs}
      onDelete={handleThumbDelete}
      testId='road-accident-drop-zone'
    />
  )
}
