import { ListRepairOrders } from '~/features/RepairOrders/ListRepairOrders'
import { Container, PageContainer } from '~/shared/ui/Layout'

export const ListRepairOrdersPage = () => {
  return (
    <PageContainer title='Заказ наряды'>
      <Container>
        <ListRepairOrders />
      </Container>
    </PageContainer>
  )
}
