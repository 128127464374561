import { Details } from '~/features/Inspection/Details'
import { QuickNavigation } from '~/shared/ui/QuickNavigation'

const quickNavigationOptions = [
  { label: 'Осн. информация', anchor: 'main' },
  { label: 'Состояние', anchor: 'state' },
  { label: 'Документы', anchor: 'documents' },
  { label: 'Комплектность', anchor: 'equipments' },
  { label: 'Обслуживание', anchor: 'service' },
  { label: 'Колеса', anchor: 'wheels' },
  { label: 'Фото', anchor: 'photos' },
]

export function DetailsPage() {
  return (
    <>
      <Details />

      <QuickNavigation options={quickNavigationOptions} />
    </>
  )
}
