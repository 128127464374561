import { MarkerComponentProps } from 'react-image-marker'
import { myPalette } from '~/shared/lib/theme'
import { PolygonMarker } from '~/shared/ui/Polygon/types'
import { Wrapper } from './styled'

type MarkerProps = MarkerComponentProps &
  PolygonMarker & {
    highlightingId?: UniqueId
  }

export function Marker({ level, color, highlightingId, id }: MarkerProps) {
  const style = color ? { background: color } : {}

  const highlightingStyle =
    highlightingId && highlightingId === id
      ? { background: myPalette.brand['500'], transform: 'scale(1.5)' }
      : {}

  return <Wrapper sx={{ ...style, ...highlightingStyle }}>{level}</Wrapper>
}
