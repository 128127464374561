import { Box } from '@mui/joy'
import { useStoreMap } from 'effector-react'
import { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { inspectionModel } from '~/entities/Inspection'
import { Inspection, InspectionViolation } from '~/shared/api'
import { priceRoundFormat } from '~/shared/lib/utils'
import { AsyncGrid, FetchRowsFn } from '~/shared/ui/AsyncGrid'
import { ChipStatus } from '~/shared/ui/Chips'
import { Container } from '~/shared/ui/Layout'
import { columns } from './columns'
import { GridRow } from './types'

export function Violations() {
  const params = useParams<{ inspectionId: string }>()
  const inspectionId = params.inspectionId as string

  const violationsCost = useStoreMap(
    inspectionModel.$inspection,
    (inspection: Inspection | null) => {
      const cost = inspection?.getViolationsCost()
      if (!cost) return null
      return `${priceRoundFormat(cost)} руб`
    },
  )

  const fetchRows = useCallback<FetchRowsFn<GridRow>>(
    async (page, pageSize, _, sort) => {
      let builder = InspectionViolation.limit(pageSize).option(
        'filter[inspection][id]',
        inspectionId,
      )

      sort
        ? (builder = builder.orderBy(sort))
        : (builder = builder.orderBy('-updatedAt'))

      const response = await builder.get(page)
      const data = response.getData()
      const rows = data.map((violation: InspectionViolation) => ({
        ...violation.getAttributes(),
        id: violation.getApiId() as string,
      }))

      const httpClientResponse = response.getHttpClientResponse()
      const axiosResponse = httpClientResponse?.getUnderlying()
      const total = axiosResponse?.data?.meta?.page?.total || 0

      return {
        rows,
        total,
      }
    },
    [inspectionId],
  )

  const title = useMemo(
    () => (
      <Box sx={{ display: 'flex' }}>
        Нарушения
        {violationsCost && (
          <ChipStatus
            sx={{ marginLeft: '16px' }}
            label={violationsCost}
            variant='red'
          />
        )}
      </Box>
    ),
    [violationsCost],
  )

  return (
    <Container>
      <AsyncGrid<GridRow>
        smallTitle={title}
        gridKey='violations-list'
        nestedGridKey={inspectionId}
        fetchRows={fetchRows}
        columns={columns}
      />
    </Container>
  )
}
