import { zodResolver } from '@hookform/resolvers/zod'
import { useStore } from 'effector-react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Element } from 'react-scroll'
import { FormValues, roadAccidentModel } from '~/entities/RoadAccident'
import {
  FormButtons,
  formButtonsModel,
  FormProvider,
  FormTitle,
} from '~/shared/ui/Form'
import { Container } from '~/shared/ui/Layout'
import { Files } from './Files'
import { Insurance } from './Insurance'
import { Main } from './Main'
import { Registration } from './Registration'

type FormProps = {
  canEdit?: boolean
  isSubmitting: boolean
  isLoading: boolean
  onSuccess?: (formValues: FormValues) => void
  defaultValues?: Partial<FormValues>
}

export function Form({
  canEdit,
  isSubmitting,
  isLoading,
  defaultValues,
  onSuccess,
}: FormProps) {
  const isEditing = useStore(formButtonsModel.$isEditing)
  const disabled = !isEditing || isSubmitting

  const form = useForm<FormValues>({
    resolver: zodResolver(roadAccidentModel.formSchema),
    defaultValues,
  })

  const { reset } = form

  useEffect(() => {
    if (defaultValues) reset(defaultValues)
    // eslint-disable-next-line
  }, [defaultValues, isEditing])

  return (
    <FormProvider form={form} onSuccess={onSuccess} canEdit={canEdit}>
      <Element name='main'>
        <Main disabled={disabled} loading={isLoading} />
      </Element>

      <Element name='registration'>
        <Registration disabled={disabled} loading={isLoading} />
      </Element>

      <Element name='insurance'>
        <Insurance disabled={disabled} loading={isLoading} />
      </Element>

      <Element name='files'>
        <Container>
          <FormTitle>Файлы</FormTitle>
          <Files isLoading={isLoading} disabled={disabled} />
        </Container>
      </Element>

      <FormButtons loading={isSubmitting} />
    </FormProvider>
  )
}
