import { z } from 'zod'

import { Option } from '~/shared/config/constants'
import { dateSchema } from '~/shared/lib/schemas'
import { ApiModel } from './core'

const attributeSchema = z.object({
  title: z.string(),
})

const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributeSchema)

export type SubdivisionAttributes = z.infer<typeof attributeSchema>

export class Subdivision extends ApiModel<
  typeof schema,
  SubdivisionAttributes
> {
  static jsonApiType = 'subdivisions'

  static schema = schema

  getTitle(): string {
    return this.getAttribute('title')
  }

  getOption(): Option {
    return {
      id: this.getApiId() as UniqueId,
      label: this.getTitle(),
    }
  }

  static async fetchOptions(search: string, regionsIds?: UniqueId[]) {
    let builder = Subdivision.where('title', search)

    if (regionsIds?.length) {
      builder = builder.option('filter[region][id]', regionsIds?.join(','))
    }

    const response = await builder.get(1)
    return response.getData().map((o) => o.getOption())
  }
}
