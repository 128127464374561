import { createDomain, sample, combine, attach } from 'effector'
import { createGate } from 'effector-react'
import { omit } from 'lodash'
import { FormValues } from '~/entities/RepairOrder'
import { repairOrderModel } from '~/entities/RepairOrder'
import { RepairOrder } from '~/shared/api'
import { logger } from '~/shared/lib/logger'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { isString } from '~/shared/lib/utils'
import { formButtonsModel } from '~/shared/ui/Form'

export const Gate = createGate<{ repairOrderId: UniqueId }>()
export const domain = createDomain('features.repairOrder.edit')

export const $repairOrderId = domain
  .createStore<UniqueId | null>(null)
  .on(Gate.state, (_, { repairOrderId }) => repairOrderId)

export const $repairOrder = combine(
  $repairOrderId,
  repairOrderModel.$repairOrderCache,
  (id, cache) => {
    if (!id) return null
    return cache.map[id] ?? null
  },
)

sample({
  clock: $repairOrderId,
  filter: isString,
  target: repairOrderModel.requestFx,
})

export const repairOrderUpdateFx = attach({
  effect: repairOrderModel.saveFx,
  source: $repairOrderId,
  mapParams: (values: FormValues, id) => {
    const {
      carOption,
      counterpartyOption,
      repairTypesOptions,
      files,
      roadAccidentIds,
      ...attr
    } = omit(values, ['status', 'paymentStatus'])

    const repairOrder = new RepairOrder(
      {
        ...attr,
        fileIds: (files || []).map(({ uuid }) => uuid),
        roadAccidentIds: roadAccidentIds?.filter(isString),
      },
      id as string,
    )

    if (isString(carOption)) repairOrder.setCar(carOption)
    if (isString(counterpartyOption))
      repairOrder.setCounterparty(counterpartyOption)

    if (Array.isArray(repairTypesOptions))
      repairOrder.setRepairTypes(repairTypesOptions)

    return repairOrder
  },
})

export const formSubmitted = domain.createEvent<FormValues>()
sample({
  clock: formSubmitted,
  target: repairOrderUpdateFx,
})

sample({
  clock: repairOrderUpdateFx.doneData,
  fn() {
    return {
      message: 'Сохранено успешно',
      variant: 'success' as const,
    }
  },
  target: [snackbarEnqueued, formButtonsModel.editingEnded],
})

sample({
  clock: repairOrderUpdateFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})
