import { createDomain, sample } from 'effector'
import { createGate } from 'effector-react'
import {
  CarEquipment,
  CarEquipmentOption,
  InspectionAttributes,
  ThumbType,
} from '~/shared/api'
import { InspectionReasonCodeEnum } from '~/shared/config/enums'

export type FormValues = Omit<
  InspectionAttributes,
  'photos' | 'duration' | 'incidentResponsible'
> & {
  photos: ThumbType[]
  incidentResponsible: string
  carBrandModel?: string
  reasonCode?: InspectionReasonCodeEnum
}

export const Gate = createGate()
const domain = createDomain('entities.inspection')

// CarEquipments
export async function fetchCarEquipments() {
  const carEquipments = await CarEquipment.limit(200).get(1)
  return carEquipments.getData() as CarEquipment[]
}

export const requestCarEquipmentsFx = domain.createEffect({
  handler: fetchCarEquipments,
})

sample({
  clock: Gate.open,
  target: requestCarEquipmentsFx,
})

export const $carEquipments = domain
  .createStore<CarEquipmentOption[]>([])
  .on(requestCarEquipmentsFx.doneData, (cache, carEquipments) =>
    carEquipments.map((carEquipment) => carEquipment.getOption()),
  )
  .on(Gate.close, () => [])
