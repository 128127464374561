import { Grid } from '@mui/joy'
import { icReviewStatusEnumOptions } from '~/shared/config/constants'
import {
  AutocompleteInput,
  DatePickerInput,
  FormTitle,
  NumericInput,
  TextInput,
} from '~/shared/ui/Form'
import { Container } from '~/shared/ui/Layout'

type InsuranceProps = {
  disabled: boolean
  loading: boolean
}

export function Insurance({ disabled, loading }: InsuranceProps) {
  return (
    <Container>
      <FormTitle>Страховка</FormTitle>
      <Grid container spacing={2.5}>
        <Grid xs={6}>
          <TextInput
            label='СК подачи документов'
            placeholder='СК подачи документов'
            name='documentsSubmissionIc'
            readOnly
            skeletonShow={loading}
          />
        </Grid>
        <Grid xs={6}>
          <NumericInput
            label='Номер убытка'
            placeholder='Введите номер убытка'
            name='lossNumber'
            readOnly={disabled}
            skeletonShow={loading}
            numericProps={{ thousandSeparator: '', decimalScale: 0 }}
          />
        </Grid>

        <Grid xs={4}>
          <DatePickerInput
            label='Дата обращения в СК'
            placeholder='Выберите дату обращения в СК'
            name='icAppealDate'
            skeletonShow={loading}
            readOnly={disabled}
          />
        </Grid>
        <Grid xs={4}>
          <DatePickerInput
            label='Дата осмотра ТС в СК'
            placeholder='Выберите дату осмотра ТС в СК'
            name='icInspectionDate'
            skeletonShow={loading}
            readOnly={disabled}
          />
        </Grid>
        <Grid xs={4}>
          <AutocompleteInput
            options={icReviewStatusEnumOptions}
            label='Статус рассмотрения СК'
            placeholder='Выберите cтатус рассмотрения СК'
            name='icReviewStatus'
            readOnly={disabled}
            skeletonShow={loading}
          />
        </Grid>
        <Grid xs={12}>
          <NumericInput
            name='reimbursedBySecurityService'
            placeholder='Введите возмещение СБ'
            label='Списание'
            skeletonShow={loading}
            readOnly={disabled}
          />
        </Grid>
      </Grid>
    </Container>
  )
}
