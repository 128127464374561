type IconProps = {
  color: string
}
export function RefreshIcon({ color }: IconProps) {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.16663 7.33333C1.16663 7.33333 2.83744 5.05685 4.19482 3.69854C5.5522 2.34022 7.42796 1.5 9.49996 1.5C13.6421 1.5 17 4.85786 17 9C17 13.1421 13.6421 16.5 9.49996 16.5C6.08071 16.5 3.19589 14.2119 2.2931 11.0833M1.16663 7.33333V2.33333M1.16663 7.33333H6.16663'
        stroke={color || '#3525E5'}
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
