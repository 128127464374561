import * as z from 'zod'

import { Option } from '~/shared/config/constants'
import { CarEquipmentTypeEnum } from '~/shared/config/enums'
import { dateSchema } from '~/shared/lib/schemas'
import { ApiModel } from './core'

const attributesSchema = z.object({
  title: z.string(),
  type: z.nativeEnum(CarEquipmentTypeEnum),
})

const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributesSchema)

export type CarEquipmentAttributes = z.infer<typeof attributesSchema>
export type CarEquipmentOption = Option

export class CarEquipment extends ApiModel<
  typeof schema,
  CarEquipmentAttributes
> {
  static jsonApiType = 'car-equipments'

  static schema = schema

  getTitle(): string {
    return this.getAttribute('title')
  }

  getType(): CarEquipmentTypeEnum {
    return this.getAttribute('type')
  }

  getOption(): CarEquipmentOption {
    return {
      id: this.getApiId() as UniqueId,
      label: this.getTitle(),
    }
  }
}
