import { Grid } from '@mui/joy'
import { documentsCodeEnumOptions } from '~/shared/config/constants'
import { FormTitle, Switch } from '~/shared/ui/Form'
import { Container } from '~/shared/ui/Layout'

type DocumentsProps = {
  loading: boolean
}

export function Documents({ loading }: DocumentsProps) {
  return (
    <Container>
      <FormTitle>Документы</FormTitle>
      <Grid container spacing={5}>
        {documentsCodeEnumOptions.map((param) => (
          <Grid xs={6} key={param.id}>
            <Switch
              label={param.label}
              name={`documents.${param.id}`}
              readOnly
              skeletonShow={loading}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}
