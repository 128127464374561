import { usePermissions } from '~/entities/viewer'
import { canShowObject } from '~/shared/lib/utils'

type Header = { label: string; to: string; parent?: string }

export const useHeader = (): Header[] => {
  const { canInspectionsView, canSettingsView, canRepairOrdersView } =
    usePermissions()

  return [
    ...canShowObject(canInspectionsView, {
      label: 'Осмотры',
      to: '/inspections',
    }),
    ...canShowObject(canRepairOrdersView, {
      label: 'Заказ наряды',
      to: '/repair-orders',
    }),
    ...canShowObject(canRepairOrdersView, {
      label: 'ДТП и Страховые случаи',
      to: '/road-accidents',
    }),
    ...canShowObject(canRepairOrdersView, {
      label: 'Контрагенты',
      to: '/counterparties',
    }),
    ...canShowObject(canSettingsView, {
      label: 'Настройки',
      to: '/settings/employees',
      parent: '/settings',
    }),
  ]
}
