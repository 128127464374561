import { Car, Counterparty, RepairType, Subdivision, User } from '~/shared/api'
import { repairOrderStatusEnumOptions } from '~/shared/config/constants'
import { Filter } from '~/shared/ui/AsyncGrid/types'

export const filters: Filter[] = [
  {
    field: 'number',
    label: 'Номер',
    type: 'text',
  },
  {
    label: 'Дата',
    type: 'dateRange',
    startField: 'createdAtGte',
    endField: 'createdAtLte',
  },
  {
    field: 'car',
    label: 'Автомобиль',
    type: 'autocomplete',
    fetchOptions: Car.fetchPlateNumberOptions,
  },
  {
    field: 'subdivision',
    label: 'Подразделение',
    type: 'autocomplete',
    fetchOptions: Subdivision.fetchOptions,
  },
  {
    field: 'user',
    label: 'Пользователь',
    type: 'autocomplete',
    fetchOptions: User.fetchOptions,
  },
  {
    field: 'status',
    label: 'Статус заказ-наряда',
    type: 'select',
    options: repairOrderStatusEnumOptions,
  },
  {
    field: 'repairTypes',
    label: 'Тип ремонта',
    type: 'autocomplete',
    fetchOptions: RepairType.fetchOptions,
  },
  {
    field: 'counterparty',
    label: 'Контрагент',
    type: 'autocomplete',
    fetchOptions: Counterparty.fetchOptions,
  },
  {
    field: 'materialsCost',
    label: 'Стоимость материалов',
    type: 'price',
  },
  {
    field: 'workCost',
    label: 'Стоимость работ',
    type: 'price',
  },
  {
    field: 'totalCost',
    label: 'Стоимость итого',
    type: 'price',
  },
]
