import React from 'react'

import { ListInspection } from '~/features/Inspection/ListInspection'
import { Container, PageContainer } from '~/shared/ui/Layout'

export function ListInspectionPage() {
  return (
    <PageContainer title='Осмотры'>
      <Container>
        <ListInspection />
      </Container>
    </PageContainer>
  )
}
