import { Stack } from '@mui/joy'
import { useStore, useStoreMap } from 'effector-react'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { AuditsModal } from '~/widgets/AuditsModal'
import {
  editRepairOrderModel,
  EditRepairOrder,
  Status,
  PaymentStatus,
} from '~/features/RepairOrders/EditRepairOrder'
import { repairOrderModel } from '~/entities/RepairOrder'
import { usePermissions } from '~/entities/viewer'
import { Breadcrumbs, BreadcrumbsList } from '~/shared/ui/Breadcrumbs'
import { Container, PageTitle, PageContainer } from '~/shared/ui/Layout'
import { NotFound } from '~/shared/ui/NotFound'
import { QuickNavigation } from '~/shared/ui/QuickNavigation'

const quickNavigationOptions = [
  { label: 'Автомобиль', anchor: 'car' },
  { label: 'Описание ремонта', anchor: 'descriptionRepair' },
  { label: 'СТО', anchor: 'sto' },
  { label: 'Стоимость', anchor: 'cost' },
  { label: 'Файлы', anchor: 'files' },
]

export const RoadAccidentEditRepairOrderPage = () => {
  const { repairOrderId, roadAccidentId } = useParams<{
    repairOrderId: string
    roadAccidentId: string
  }>()

  const { canAuditsView } = usePermissions()

  const isLoading = useStore(repairOrderModel.requestFx.pending)
  const name = useStoreMap(editRepairOrderModel.$repairOrder, (repairOrder) =>
    repairOrder?.getNumber(),
  )

  const title = `№ ${name || '...'}`

  const breadcrumbs: BreadcrumbsList = useMemo(
    () => [
      { title: 'ДТП и Страховые случаи', link: '/road-accidents' },
      {
        title: `ДТП № ${roadAccidentId}`,
        link: `/road-accidents/${roadAccidentId}`,
      },
      { title },
    ],
    [roadAccidentId, title],
  )

  const error = repairOrderModel.useRepairOrderError(repairOrderId as string)

  if (!repairOrderId || error) {
    return <NotFound message='Не удалось загрузить заказ наряд' />
  }

  return (
    <PageContainer title='Карточка заказ наряда'>
      <Container isHeader>
        <Breadcrumbs list={breadcrumbs} lastLoading={isLoading} />
        <Stack direction='row' alignItems='end' spacing={2}>
          <Status />
          <PaymentStatus />
        </Stack>
        <Stack direction='row' alignItems='end' justifyContent='space-between'>
          <PageTitle loading={isLoading}>{title}</PageTitle>
          {canAuditsView && <AuditsModal auditableId={repairOrderId} />}
        </Stack>
      </Container>

      <EditRepairOrder repairOrderId={repairOrderId} />

      <QuickNavigation options={quickNavigationOptions} />
    </PageContainer>
  )
}
