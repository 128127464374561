export enum InspectionReasonCodeEnum {
  PLAN = 'PLAN',
  INITIAL = 'INITIAL',
  REFUND = 'REFUND',
  CONFISCATION = 'CONFISCATION',
  AFTER_ACCIDENT = 'AFTER_ACCIDENT',
  RETURN_FROM_REPAIR = 'RETURN_FROM_REPAIR',
  RENTAL = 'RENTAL',
}

export enum CarEquipmentTypeEnum {
  EXTERIOR = 'EXTERIOR',
  INTERIOR = 'INTERIOR',
  TRUNK = 'TRUNK',
}

export enum DocumentsTypeEnum {
  STS = 'STS',
  OSAGO = 'OSAGO',
  DC = 'DC',
  LICENSE = 'LICENSE',
}

export enum InspectionServiceTypeEnum {
  MOTOR_OIL = 'MOTOR_OIL',
  ANTIFREEZE = 'ANTIFREEZE',
  WIPERS = 'WIPERS',
  LAMPS = 'LAMPS',
}

export enum WheelCodeEnum {
  FRONT_RIGHT = 'FRONT_RIGHT',
  FRONT_LEFT = 'FRONT_LEFT',
  REAR_RIGHT = 'REAR_RIGHT',
  REAR_LEFT = 'REAR_LEFT',
}

export enum DiskTypeEnum {
  STAMPED = 'STAMPED',
  STAMPED_WITH_HOOD = 'STAMPED_WITH_HOOD',
  CAST = 'CAST',
}

export enum RubberTypeEnum {
  WINTER = 'WINTER',
  SUMMER = 'SUMMER',
}

export enum InspectionPhotoTypeEnum {
  CAR = 'car',
  TARIFFS = 'tariffs',
  LAST_TO = 'lastTO',
  MILEAGE = 'mileage',
  DAMAGE = 'damage',
  DRIVER_SIGNATURE = 'driverSignature',
}

export enum CarDetailTypeEnum {
  EXTERIOR = 'EXTERIOR',
  INTERIOR = 'INTERIOR',
  TIRE = 'TIRE',
  ENVIRONMENT = 'ENVIRONMENT',
}

export enum CarDamageExtendsCodeEnum {
  LIGHT = 'LIGHT',
  MEDIUM = 'MEDIUM',
  HEAVY = 'HEAVY',
}

export enum IncidentResponsibleEnum {
  DRIVER = 'DRIVER',
  NOT_DRIVER = 'NOT_DRIVER',
  UNKNOWN = 'UNKNOWN',
}

export enum RepairOrderStatusEnum {
  OPENED = 'OPENED',
  AWAITING_REPAIR = 'AWAITING_REPAIR',
  AWAITING_SPARE_PARTS = 'AWAITING_SPARE_PARTS',
  AT_WORK = 'AT_WORK',
  COMPLETED = 'COMPLETED',
}

export enum RepairOrderPaymentStatusEnum {
  PAID = 'PAID',
  NOT_PAID = 'NOT_PAID',
}

export enum EventAuditEnum {
  CREATED = 'created',
  UPDATED = 'updated',
  DELETED = 'deleted',
  RESTORED = 'restored',
}

export enum RoadAccidentsStatusEnum {
  OPENED = 'OPENED',
  CLOSED = 'CLOSED',
}

export enum AccidentRegistrationTypeEnum {
  EUROPROTOCOL = 'EUROPROTOCOL',
  GIBDD = 'GIBDD',
}

export enum InsurancePolicyStatusEnum {
  VALID = 'VALID',
  NOT_VALID = 'NOT_VALID',
}

export enum IcReviewStatusEnum {
  DOCUMENTS_SUBMITTED = 'DOCUMENTS_SUBMITTED',
  DOCUMENTS_READY = 'DOCUMENTS_READY',
  INSPECTION_SCHEDULED = 'INSPECTION_SCHEDULED',
  REPAIR_REFERRAL_ISSUED = 'REPAIR_REFERRAL_ISSUED',
  PAYMENT_WAITING = 'PAYMENT_WAITING',
  REIMBURSED = 'REIMBURSED',
  REFUSAL = 'REFUSAL',
  COURT_PROCEEDINGS = 'COURT_PROCEEDINGS',
}
