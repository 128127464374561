import * as z from 'zod'

import { dateSchema } from '~/shared/lib/schemas'
import { ApiModel } from './core'

const attributeSchema = z.object({
  cost: z.number(),
  field: z.string(),
})

const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributeSchema)

export type InspectionViolationAttributes = z.infer<typeof attributeSchema>

export class InspectionViolation extends ApiModel<
  typeof schema,
  InspectionViolationAttributes
> {
  static jsonApiType = 'inspection-violations'

  static schema = schema
}
