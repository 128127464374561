import { Damage } from '~/entities/Inspection/ui/Damage'
import { Form } from '~/entities/Inspection/ui/Damage/DamageModal/Form'
import { formatDateTimeForUI } from '~/shared/lib/date'
import { Button } from '~/shared/ui/Buttons'
import { Modal } from '~/shared/ui/Modal'

type DamageModalProps = {
  isShow: boolean
  onClose: () => void
  selectedDamage?: Damage
}

export function DamageModal({
  isShow,
  onClose,
  selectedDamage,
}: DamageModalProps) {
  return (
    <Modal
      isShow={isShow}
      onCancel={onClose}
      dialogProps={{
        sx: {
          minWidth: '1000px',
          maxWidth: '1000px',
        },
      }}
      title={`Повреждение от ${
        selectedDamage ? formatDateTimeForUI(selectedDamage.createdAt) : '-'
      }`}
    >
      <Form damage={selectedDamage} />
      <br />
      <Button variant='gray' onClick={onClose} fullWidth>
        Закрыть
      </Button>
    </Modal>
  )
}
