import { Box } from '@mui/joy'
import { omit } from 'lodash'
import React from 'react'
import ImageMarker, { MarkerComponentProps } from 'react-image-marker'
import { PolygonMarker } from '../types'
import { Marker } from './ui/Marker'

type MarkersProps = {
  img: string
  onMarkerSelect: (marker: PolygonMarker) => void
  markers: PolygonMarker[]
  markerHighlightingId?: UniqueId
}

export function PolygonMarkers({
  img,
  onMarkerSelect,
  markers,
  markerHighlightingId,
}: MarkersProps) {
  return (
    <ImageMarker
      src={img}
      markers={markers}
      bufferLeft={0}
      bufferTop={0}
      markerComponent={(markerProps: MarkerComponentProps) => (
        <Box
          sx={{ cursor: 'pointer' }}
          onClick={() =>
            onMarkerSelect(omit(markerProps, 'itemNumber') as PolygonMarker)
          }
        >
          <Marker
            {...(markerProps as MarkerComponentProps & PolygonMarker)}
            highlightingId={markerHighlightingId}
          />
        </Box>
      )}
    />
  )
}
