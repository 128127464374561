import { GridColDef } from '@mui/x-data-grid'
import { format, parseISO } from 'date-fns'
import { datetime } from '~/shared/config'
import { ChipStatus } from '~/shared/ui/Chips'
import { Gallery } from '~/shared/ui/Gallery'
import { GridRow } from './types'

export const columns: GridColDef<GridRow>[] = [
  {
    headerName: 'Дата осмотра',
    field: 'inspectionFinishedAt',
    flex: 1,
    sortable: false,
    renderCell: ({ value: inspectionFinishedAt }) =>
      inspectionFinishedAt &&
      format(parseISO(inspectionFinishedAt), datetime.uiDateFormat),
  },
  {
    headerName: 'Деталь',
    field: 'carDetail',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Степень повреждения',
    field: 'carDamageExtend',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Стоимость повреждения',
    field: 'cost',
    flex: 1,
    sortable: false,
    renderCell: ({ value }) => value && value.toLocaleString(),
  },
  {
    headerName: 'Фото',
    field: 'photos',
    flex: 1,
    sortable: false,
    renderCell: ({ value }) => (
      <Gallery
        buttonTitle='Смотреть'
        buttonProps={{
          variant: 'textBrand',
          size: 'sm',
        }}
        showList={false}
        images={value}
      />
    ),
  },
  {
    headerName: 'Водитель',
    field: 'driverFullName',
    flex: 1,
    sortable: false,
  },
  {
    headerName: 'Устранено',
    field: 'isRepair',
    flex: 1,
    sortable: false,
    renderCell: ({ value }) => (
      <ChipStatus
        variant={value ? 'green' : 'red'}
        label={value ? 'Да' : 'Нет'}
      />
    ),
  },
]
