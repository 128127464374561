import * as z from 'zod'

import { Option } from '~/shared/config/constants'
import { CarDamageExtendsCodeEnum } from '~/shared/config/enums'
import { dateSchema } from '~/shared/lib/schemas'
import { ApiModel } from './core'

const attributesSchema = z.object({
  title: z.string(),
  code: z.nativeEnum(CarDamageExtendsCodeEnum),
})

const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributesSchema)

export type CarDamageExtentAttributes = z.infer<typeof attributesSchema>
export type CarDamageExtentOption = Option & {
  code: CarDamageExtendsCodeEnum
}

export class CarDamageExtent extends ApiModel<
  typeof schema,
  CarDamageExtentAttributes
> {
  static jsonApiType = 'car-damage-extents'

  static schema = schema

  getTitle(): string {
    return this.getAttribute('title')
  }

  getCode(): CarDamageExtendsCodeEnum {
    return this.getAttribute('code')
  }

  getOption(): CarDamageExtentOption {
    return {
      id: this.getApiId() as UniqueId,
      label: this.getTitle(),
      code: this.getCode(),
    }
  }
}
