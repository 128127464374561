import { useStoreMap } from 'effector-react'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { inspectionModel } from '~/entities/Inspection'
import { InspectionDamage } from '~/shared/api'
import { AsyncGrid, FetchRowsFn } from '~/shared/ui/AsyncGrid'
import { mapPhotoToImage } from '~/shared/ui/Gallery'
import { columns } from './columns'
import { GridRow } from './types'

type ListHistoryDamagesProps = {
  onRowClick: (damageId: UniqueId) => void
}

export const ListHistoryDamages = ({ onRowClick }: ListHistoryDamagesProps) => {
  const params = useParams<{ inspectionId: string }>()
  const inspectionId = params.inspectionId as string

  const carId = useStoreMap(inspectionModel.$inspection, (inspection) =>
    inspection?.getCar().getApiId(),
  )
  const inspectionFinishedAt = useStoreMap(
    inspectionModel.$inspection,
    (inspection) => inspection?.getFinishedAt(),
  )

  const fetchRows = useCallback<FetchRowsFn<GridRow>>(
    async (page, pageSize, _, sort) => {
      let builder = InspectionDamage.limit(pageSize)
        .with('detail')
        .with('extent')
        .with('type')
        .with('inspection')
        .with('repairInspection')
        .option('filter[inspection][car][id]', carId as UniqueId)
        .option('filter[createdAtLt]', inspectionFinishedAt as string)

      sort
        ? (builder = builder.orderBy(sort))
        : (builder = builder.orderBy('-updatedAt'))

      const response = await builder.get(page)
      const data = response.getData()
      const rows = data.map((damage: InspectionDamage) => {
        return {
          ...damage.getAttributes(),
          id: damage.getApiId() as string,
          carDamageExtend: damage?.getExtent()?.getTitle(),
          carDetail: damage?.getDetail()?.getTitle(),
          driverFullName: damage?.getInspection()?.getDriverFullName(),
          inspectionFinishedAt: damage?.getInspection()?.getFinishedAt(),
          photos: damage?.getPhotos().map(mapPhotoToImage),
          isRepair: Boolean(damage?.getRepairInspection()),
        }
      })

      const httpClientResponse = response.getHttpClientResponse()
      const axiosResponse = httpClientResponse?.getUnderlying()
      const total = axiosResponse?.data?.meta?.page?.total || 0

      return {
        rows,
        total,
      }
    },
    [carId, inspectionFinishedAt],
  )

  if (!inspectionFinishedAt) return null

  return (
    <AsyncGrid<GridRow>
      gridKey='history-damages-list'
      nestedGridKey={inspectionId}
      fetchRows={fetchRows}
      columns={columns}
      onRowClick={(row) => onRowClick(row.id as UniqueId)}
      memoryLocal
      smallTitle='История повреждений'
    />
  )
}
