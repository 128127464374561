import { ListRoadAccidents } from '~/features/RoadAccidents/ListRoadAccidents'
import { Container } from '~/shared/ui/Layout'

export const ListRoadAccidentsPage = () => {
  return (
    <Container>
      <ListRoadAccidents />
    </Container>
  )
}
