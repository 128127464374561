import { attach, sample } from 'effector'
import { editRepairOrderModel } from '~/features/RepairOrders/EditRepairOrder'
import { repairOrderModel } from '~/entities/RepairOrder'
import { RepairOrder } from '~/shared/api'
import { OptionEnum } from '~/shared/config/constants'
import { RepairOrderStatusEnum } from '~/shared/config/enums'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { isString } from '~/shared/lib/utils'

export type StatusFormValues = {
  status?: OptionEnum<RepairOrderStatusEnum> | null
}

export const statusChangeFx = attach({
  effect: repairOrderModel.saveFx,
  source: editRepairOrderModel.$repairOrderId,
  mapParams: (status: StatusFormValues['status'], id) => {
    return new RepairOrder(
      {
        ...(isString(status?.id) && { status: status.id }),
      },
      id as string,
    )
  },
})

sample({
  clock: statusChangeFx.doneData,
  fn() {
    return {
      message: 'Статус успешно изменён',
      variant: 'success' as const,
    }
  },
  target: snackbarEnqueued,
})
