import { useStore, useStoreMap, useGate } from 'effector-react'
import { Form, employeeModel } from '~/entities/Employee'
import { usePermissions } from '~/entities/viewer'
import { Region, Role, Subdivision } from '~/shared/api'
import { formSubmitted, Gate, $employee, employeeUpdateFx } from './model'

type EditEmployeeProps = {
  employeeId: string
}

export function EditEmployee({ employeeId }: EditEmployeeProps) {
  useGate(Gate, { employeeId })
  const { canUsersUpdate } = usePermissions()

  const isLoading = useStore(employeeModel.requestFx.pending)
  const isSaving = useStore(employeeUpdateFx.pending)

  const defaultValues = useStoreMap($employee, (employee) => ({
    ...employee?.getAttributes(),
    rolesIds: employee?.getRoles()?.map((role: Role) => role.getOption()),
    regionsIds: employee
      ?.getRegions()
      ?.map((region: Region) => region.getOption()),
    subdivisionsIds: employee
      ?.getSubdivisions()
      ?.map((subdivision: Subdivision) => subdivision.getOption()),
  }))

  return (
    <Form
      isLoading={isLoading}
      defaultValues={defaultValues}
      onSuccess={formSubmitted}
      isSubmitting={isSaving}
      canEdit={canUsersUpdate}
    />
  )
}
