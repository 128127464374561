import { Box, styled } from '@mui/joy'
import { myPalette } from '~/shared/lib/theme'

export const DropContainer = styled(Box)`
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px 0;
  transition: 0.3s background ease;

  &:hover:not(:active) {
    background: ${myPalette.gray['100']};
  }
`

export const Title = styled(Box)`
  font-size: 14px;
  width: 220px;
  text-align: center;
  margin-top: 12px;
`

export const SubTitle = styled(Box)`
  color: ${myPalette.brand['700']};
  font-weight: 600;
`

export const Item = styled(Box)`
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${myPalette.gray['200']};
  border-radius: 12px;
  padding: 20px 16px;
  transition: 0.3s background ease;

  &:hover {
    background: ${myPalette.gray['50']};
  }
`

export const FileWrapper = styled(Box)`
  width: 40px;
  height: 40px;

  img,
  embed,
  svg {
    width: 40px;
    height: 40px;
    object-fit: contain;
  }
`
