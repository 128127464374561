import React from 'react'
import { Tabs } from '~/shared/ui/RoutesTabs/type'
import { DamagesPage } from './Damages'
import { DetailsPage } from './Details'
import { ViolationsPage } from './Violations'

export const useConfigTabs = (): Tabs => {
  return [
    {
      label: 'Информация',
      path: '/details',
      content: <DetailsPage />,
    },
    {
      label: 'Повреждения',
      path: '/damages',
      content: <DamagesPage />,
    },
    {
      label: 'Нарушения',
      path: '/violations',
      content: <ViolationsPage />,
    },
  ]
}
