import { Tooltip } from '@mui/joy'
import { GridColDef } from '@mui/x-data-grid'
import { getStatusColor } from '~/entities/RepairOrder'
import { repairOrderStatusEnumLabels } from '~/shared/config/constants'
import { TMS_DOMAIN } from '~/shared/config/env'
import { mileageFormat, priceFormat } from '~/shared/lib/utils'
import { ColumnDate, ColumnLink, ColumnValue } from '~/shared/ui/AsyncGrid'
import { ChipStatus } from '~/shared/ui/Chips'
import { GridRow } from './types'

export const columns: GridColDef<GridRow>[] = [
  {
    headerName: 'Номер',
    field: 'number',
    minWidth: 160,
    renderCell: ({ row }) => <ColumnLink to={row.id}>{row.number}</ColumnLink>,
  },
  {
    headerName: 'Дата и время',
    field: 'createdAt',
    renderCell: ({ value }) => <ColumnDate value={value} type='dateTime' />,
    minWidth: 200,
  },
  {
    headerName: 'Автомобиль',
    field: 'carPlateNumber',
    sortable: false,
    minWidth: 160,
    renderCell: ({ row }) => (
      <ColumnLink href={`${TMS_DOMAIN}cars/${row.carExtId}`}>
        {row.carPlateNumber}
      </ColumnLink>
    ),
  },
  {
    headerName: 'Подразделение',
    field: 'carSubdivision',
    sortable: false,
    minWidth: 180,
  },
  {
    headerName: 'Пробег',
    field: 'mileage',
    sortable: false,
    minWidth: 180,
    renderCell: ({ value }) => (
      <ColumnValue>{mileageFormat(value)}</ColumnValue>
    ),
  },

  {
    headerName: 'Пользователь',
    field: 'userName',
    sortable: false,
    minWidth: 180,
  },
  {
    headerName: 'Статус заказ-наряда',
    field: 'status',
    sortable: false,
    minWidth: 210,
    renderCell: ({ row }) => {
      return (
        <ChipStatus
          label={repairOrderStatusEnumLabels[row.status]}
          variant={getStatusColor(row.status)}
        />
      )
    },
  },
  {
    headerName: 'Тип ремонта',
    field: 'repairTypes',
    sortable: false,
    minWidth: 220,
  },
  {
    headerName: 'Контрагент',
    field: 'counterpartyName',
    sortable: false,
    minWidth: 160,
  },
  {
    headerName: 'Стоимость материалов',
    field: 'materialsCost',
    renderCell: ({ value }) => <ColumnValue>{priceFormat(value)}</ColumnValue>,
    minWidth: 160,
  },
  {
    headerName: 'Стоимость работ',
    field: 'workCost',
    renderCell: ({ value }) => <ColumnValue>{priceFormat(value)}</ColumnValue>,
    minWidth: 160,
  },
  {
    headerName: 'Стоимость итого',
    field: 'totalCost',
    renderCell: ({ value }) => <ColumnValue>{priceFormat(value)}</ColumnValue>,
    minWidth: 160,
  },
  {
    headerName: 'Номер заказ-наряда контрагента',
    field: 'counterpartyNumber',
    minWidth: 250,
  },
  {
    headerName: 'Причина обращения (неисправности)',
    field: 'reason',
    minWidth: 250,
    renderCell: ({ value }) => (
      <Tooltip variant='outlined' title={value || ''} sx={{ maxWidth: '50%' }}>
        <ColumnValue sx={{ width: '100%' }}>{value}</ColumnValue>
      </Tooltip>
    ),
  },
]
