import * as z from 'zod'

import { dateSchema } from '~/shared/lib/schemas'
import { ApiModel } from './core'

const attributesSchema = z.object({
  title: z.string(),
})

const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributesSchema)

export type CarBrandModelsAttributes = z.infer<typeof attributesSchema>

export class CarBrandModel extends ApiModel<
  typeof schema,
  CarBrandModelsAttributes
> {
  static jsonApiType = 'car-brand-models'

  static schema = schema

  getTitle(): string {
    return this.getAttribute('title')
  }
}
