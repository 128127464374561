import { ListCounterparties } from '~/features/Counterparties/ListCounterparties'
import { Container } from '~/shared/ui/Layout'

export const ListCounterpartiesPage = () => {
  return (
    <Container>
      <ListCounterparties />
    </Container>
  )
}
