import { Link } from '@mui/joy'
import { IconButton } from '~/shared/ui/Buttons'
import { IconButtonProps } from '~/shared/ui/Buttons/IconButton'
import { PrintIcon } from '~/shared/ui/Icons'

type FileExportLinkProps = {
  filepath: string
  tooltipText: string
  iconButtonProps?: Partial<IconButtonProps>
}

export function FileExportLink({
  filepath,
  tooltipText,
  iconButtonProps,
}: FileExportLinkProps) {
  return (
    <Link href={filepath} target={'_self'}>
      <IconButton {...iconButtonProps} tooltipProps={{ title: tooltipText }}>
        <PrintIcon />
      </IconButton>
    </Link>
  )
}
