import { ToManyRelation } from 'coloquent'
import * as z from 'zod'
import { Car } from '~/shared/api/car'
import { InspectionService } from '~/shared/api/inspectionServices'
import { InspectionWheelsState } from '~/shared/api/inspectionWheelsState'
import { ThumbType } from '~/shared/api/types'
import { User } from '~/shared/api/user'
import {
  IncidentResponsibleEnum,
  InspectionPhotoTypeEnum,
} from '~/shared/config/enums'
import { dateSchema } from '~/shared/lib/schemas'
import { ApiModel, ToOneRelation } from './core'
import { InspectionDocument } from './inspectionDocument'
import { InspectionEquipment } from './inspectionEquipment'
import { InspectionReason } from './inspectionReason'

const attributesSchema = z.object({
  carId: z.string(),
  plateNumber: z.string(),

  reasonId: z.string(),
  incidentResponsible: z.nativeEnum(IncidentResponsibleEnum).optional(),
  isExteriorClean: z.boolean().optional(),
  isCarRefueled: z.boolean().optional(),
  withDriverParticipation: z.boolean().optional(),
  isPlanned: z.boolean().optional(),
  photos: z.array(
    z.object({
      originalUrl: z.string(),
      previewUrl: z.string(),
      type: z.nativeEnum(InspectionPhotoTypeEnum),
      uuid: z.string(),
    }),
  ),
  inspectionReport: z
    .object({
      originalUrl: z.string(),
      uuid: z.string(),
    })
    .nullable(),

  hasTobaccoSmell: z.boolean().optional(),
  isInteriorDirty: z.boolean().optional(),
  isDryCleaningNeed: z.boolean().optional(),

  mileage: z.number(),
  startedAt: z.string(),
  duration: z.number(),
  finishedAt: z.string(),

  comment: z.string().optional(),
  regionTitle: z.string(),
  subdivisionTitle: z.string(),

  hasDamages: z.boolean(),
  hasCarPhotos: z.boolean(),
  hasDocumentsLoss: z.boolean(),
  driverFullName: z.boolean(),

  violationsCost: z.number(),
  damagesCost: z.number(),
  totalCost: z.number(),
})

const schema = z
  .object({
    createdAt: dateSchema,
    updatedAt: dateSchema,
  })
  .merge(attributesSchema)

export type InspectionAttributes = z.infer<typeof schema>

export class Inspection extends ApiModel<typeof schema, InspectionAttributes> {
  static jsonApiType = 'inspections'

  static schema = schema

  getDuration(): number {
    return this.getAttribute('duration')
  }
  getDriverFullName(): string {
    return this.getAttribute('driverFullName')
  }
  getUpdatedAt(): string {
    return this.getAttribute('updatedAt')
  }
  getFinishedAt(): string {
    return this.getAttribute('finishedAt')
  }
  getIncidentResponsible(): IncidentResponsibleEnum {
    return this.getAttribute('incidentResponsible')
  }
  getDamagesCost(): number {
    return this.getAttribute('damagesCost')
  }
  getViolationsCost(): number {
    return this.getAttribute('violationsCost')
  }
  getPhotos(): ThumbType[] {
    return this.getAttribute('photos')
  }
  getInspectionReportUrl(): string | null {
    return this.getAttribute('inspectionReport')?.originalUrl
  }
  getPlateNumber(): string {
    return this.getAttribute('plateNumber')
  }

  car(): ToOneRelation<Car, this> {
    return this.hasOne(Car)
  }
  getCar(): Car {
    return this.getRelation('car')
  }

  reason(): ToOneRelation<InspectionReason, this> {
    return this.hasOne(InspectionReason)
  }
  getReason(): InspectionReason {
    return this.getRelation('reason')
  }

  equipments(): ToManyRelation<InspectionEquipment, this> {
    return this.hasMany(InspectionEquipment)
  }
  getEquipments(): InspectionEquipment[] {
    return this.getRelation('equipments')
  }

  documents(): ToManyRelation<InspectionDocument, this> {
    return this.hasMany(InspectionDocument)
  }
  getDocuments(): InspectionDocument[] {
    return this.getRelation('documents')
  }

  responsible(): ToOneRelation<User, this> {
    return this.hasOne(User)
  }
  getResponsible(): User {
    return this.getRelation('responsible')
  }

  services(): ToManyRelation<InspectionService, this> {
    return this.hasMany(InspectionService)
  }
  getServices(): InspectionService[] {
    return this.getRelation('services')
  }

  wheelsStates(): ToManyRelation<InspectionWheelsState, this> {
    return this.hasMany(InspectionWheelsState)
  }
  getWheelsStates(): InspectionWheelsState[] {
    return this.getRelation('wheelsStates')
  }
}
