import {
  FormControl,
  FormHelperText,
  FormLabel,
  Skeleton,
  TextareaProps,
} from '@mui/joy'

import { Controller } from 'react-hook-form'
import { Textarea } from './styled'

type TextareaInputProps = {
  label?: string
  name: string
  skeletonShow?: boolean
} & Omit<TextareaProps, 'name' | 'slotProps'>

export function TextareaInput({
  label,
  name,
  skeletonShow,
  ...inputProps
}: TextareaInputProps) {
  return (
    <Controller
      name={name}
      defaultValue=''
      render={({ field, fieldState: { error, invalid } }) => (
        <FormControl error={invalid}>
          {label && <FormLabel>{label}</FormLabel>}
          <Textarea
            size='md'
            {...inputProps}
            {...field}
            value={field?.value || ''}
            error={invalid}
            slotProps={{
              ...(skeletonShow ? { root: { component: Skeleton } } : {}),
            }}
          />
          {invalid && (
            <FormHelperText data-testid={`error-message-${label}`}>
              {error?.message || ''}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  )
}
