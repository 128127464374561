import { Stack } from '@mui/joy'
import { myPalette } from '~/shared/lib/theme'
import { Circle, Degree, Img, ItemWrapper } from './styled'

export function Legend() {
  return (
    <Stack
      direction='row'
      spacing={2}
      justifyContent='center'
      sx={{ fontSize: '12px' }}
    >
      <ItemWrapper>
        <Img src='/images/marker.svg' alt='marker' />С данного осмотра
      </ItemWrapper>
      <ItemWrapper>
        <Circle sx={{ background: myPalette.yellow['300'] }} /> С прошлых
        осмотров
      </ItemWrapper>
      <ItemWrapper>
        <Circle sx={{ background: myPalette.brand['500'] }} /> Выбрано в таблице
      </ItemWrapper>

      <ItemWrapper>
        <Degree>Л</Degree> Легкое повреждение
      </ItemWrapper>
      {/*<ItemWrapper>*/}
      {/*  <Degree>С</Degree>  Среднее повреждение*/}
      {/*</ItemWrapper>*/}
      <ItemWrapper>
        <Degree>Т</Degree> Тяжелое повреждение
      </ItemWrapper>
    </Stack>
  )
}
