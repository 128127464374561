import { createDomain, sample, combine, attach } from 'effector'
import { createGate } from 'effector-react'
import { counterpartyModel } from '~/entities/Counterparty'
import { FormValues } from '~/entities/Counterparty'
import { Counterparty } from '~/shared/api'
import { logger } from '~/shared/lib/logger'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { isString } from '~/shared/lib/utils'
import { formButtonsModel } from '~/shared/ui/Form'

export const Gate = createGate<{ counterpartyId: UniqueId }>()
export const domain = createDomain('features.counterparty.edit')

export const $counterpartyId = domain
  .createStore<UniqueId | null>(null)
  .on(Gate.state, (_, { counterpartyId }) => counterpartyId)

export const $counterparty = combine(
  $counterpartyId,
  counterpartyModel.$counterpartyCache,
  (id, cache) => {
    if (!id) return null
    return cache.map[id] ?? null
  },
)

sample({
  clock: $counterpartyId,
  filter: isString,
  target: counterpartyModel.requestFx,
})

export const counterpartyUpdateFx = attach({
  effect: counterpartyModel.saveFx,
  source: $counterpartyId,
  mapParams: (values: FormValues, id) => {
    return new Counterparty(values, id as string)
  },
})

export const formSubmitted = domain.createEvent<FormValues>()
sample({
  clock: formSubmitted,
  target: counterpartyUpdateFx,
})

sample({
  clock: counterpartyUpdateFx.doneData,
  fn() {
    return {
      message: 'Сохранено успешно',
      variant: 'success' as const,
    }
  },
  target: [snackbarEnqueued, formButtonsModel.editingEnded],
})

sample({
  clock: counterpartyUpdateFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: 'Ошибка при сохранении данных контрагента',
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})
