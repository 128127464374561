import { AddCounterparty } from '~/features/Counterparties/AddCounterparty'
import { Breadcrumbs, BreadcrumbsList } from '~/shared/ui/Breadcrumbs'
import { Container, PageContainer, PageTitle } from '~/shared/ui/Layout'

const breadcrumbs: BreadcrumbsList = [
  { title: 'Контрагенты', link: '/counterparties' },
  { title: 'Создание контрагента' },
]

export const AddCounterpartyPage = () => {
  return (
    <PageContainer title='Создание контрагента'>
      <Container isHeader>
        <Breadcrumbs list={breadcrumbs} />
        <PageTitle>Создание контрагента</PageTitle>
      </Container>
      <AddCounterparty />
    </PageContainer>
  )
}
