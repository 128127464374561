import { LinearProgress, Stack, Typography } from '@mui/joy'
import { useStore } from 'effector-react'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Element } from 'react-scroll'
import { AuditsModal } from '~/widgets/AuditsModal'
import {
  editRoadAccidentModel,
  EditRoadAccident,
  Status,
} from 'src/features/RoadAccidents/EditRoadAccident'
import { roadAccidentModel } from 'src/entities/RoadAccident'
import { usePermissions } from '~/entities/viewer'
import { formatDateTimeForUI } from '~/shared/lib/date'
import { myPalette } from '~/shared/lib/theme'
import { Breadcrumbs, BreadcrumbsList } from '~/shared/ui/Breadcrumbs'
import { Button, LinkDecorator } from '~/shared/ui/Buttons'
import { FormTitle } from '~/shared/ui/Form'
import { PlusIcon } from '~/shared/ui/Icons'
import { Container, PageTitle, PageContainer } from '~/shared/ui/Layout'
import { NotFound } from '~/shared/ui/NotFound'
import { QuickNavigation } from '~/shared/ui/QuickNavigation'

const quickNavigationOptions = [
  { label: 'Информация', anchor: 'main' },
  { label: 'Оформление ДТП', anchor: 'registration' },
  { label: 'Страховка', anchor: 'insurance' },
  { label: 'Файлы', anchor: 'files' },
  { label: 'Заказ-наряд', anchor: 'repairOrders' },
]

export const EditRoadAccidentPage = () => {
  const params = useParams<{ roadAccidentId: string }>()
  const roadAccidentId = params.roadAccidentId as string

  const { canAuditsView, canRepairOrdersUpdate, canRoadAccidentsUpdate } =
    usePermissions()

  const isLoading = useStore(roadAccidentModel.requestFx.pending)
  const roadAccident = useStore(editRoadAccidentModel.$roadAccident)

  const number = roadAccident?.getNumber()
  const createdAt = roadAccident?.getCreatedAt()
  const inspectionId = roadAccident?.getInspection()?.getApiId()

  const repairOrder = roadAccident?.getRepairOrder()
  const repairOrderId = repairOrder?.getApiId()
  const repairOrdeNumber = repairOrder?.getNumber()

  const title = `№ ${number || '...'} от ${
    createdAt ? formatDateTimeForUI(createdAt) : ''
  }`

  const breadcrumbs: BreadcrumbsList = useMemo(
    () => [
      { title: 'ДТП и Страховые случаи', link: '/road-accidents' },
      { title },
    ],
    [title],
  )

  const error = roadAccidentModel.useRoadAccidentError(roadAccidentId as string)
  if (!roadAccidentId || error) {
    return <NotFound message='Не удалось загрузить карточку ДТП' />
  }

  return (
    <PageContainer title='Карточка ДТП'>
      <Container isHeader>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Breadcrumbs list={breadcrumbs} lastLoading={isLoading} />
          <Button to={`/inspections/${inspectionId}`} variant='gray'>
            Осмотр
          </Button>
        </Stack>
        <Stack direction='row' spacing={2}>
          <Status />
        </Stack>
        <Stack direction='row' alignItems='end' justifyContent='space-between'>
          <PageTitle loading={isLoading}>{title}</PageTitle>
          {canAuditsView && <AuditsModal auditableId={roadAccidentId} />}
        </Stack>
      </Container>

      <EditRoadAccident roadAccidentId={roadAccidentId} />

      <Element name='repairOrders'>
        <Container>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
          >
            <FormTitle>Заказ-наряд</FormTitle>
            {!isLoading && !repairOrder && (
              <Button
                show={canRepairOrdersUpdate && canRoadAccidentsUpdate}
                startDecorator={<PlusIcon />}
                to='repair-orders/new'
              >
                Создать
              </Button>
            )}
          </Stack>
          {isLoading && <LinearProgress size='lg' />}

          {!isLoading && !repairOrder && (
            <Typography
              textAlign='center'
              fontSize={14}
              sx={{ color: myPalette.gray['600'] }}
            >
              Нет данных
            </Typography>
          )}
          {!isLoading && repairOrder && (
            <Typography
              startDecorator={
                <LinkDecorator
                  tooltipText='Перейти в карточку заказ наряда'
                  to={`repair-orders/${repairOrderId}`}
                />
              }
            >
              № {repairOrdeNumber}
            </Typography>
          )}
        </Container>
      </Element>

      <QuickNavigation options={quickNavigationOptions} />
    </PageContainer>
  )
}
