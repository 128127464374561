import { useStore, useStoreMap } from 'effector-react'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import {
  editEmployeeModel,
  EditEmployee,
} from '~/features/Employees/EditEmployee'
import { employeeModel } from '~/entities/Employee'
import { Breadcrumbs, BreadcrumbsList } from '~/shared/ui/Breadcrumbs'
import { Container, PageTitle, PageContainer } from '~/shared/ui/Layout'
import { NotFound } from '~/shared/ui/NotFound'

export const EditEmployeePage = () => {
  const { employeeId } = useParams<{ employeeId: string }>()

  const isLoading = useStore(employeeModel.requestFx.pending)
  const employeeName = useStoreMap(editEmployeeModel.$employee, (employee) =>
    employee?.getName(),
  )

  const breadcrumbs: BreadcrumbsList = useMemo(
    () => [
      { title: 'Сотрудники', link: '/settings/employees' },
      { title: employeeName || '' },
    ],
    [employeeName],
  )

  const error = employeeModel.useEmployeeError(employeeId as string)

  if (!employeeId || error) {
    return <NotFound message='Не удалось загрузить сотрудника' />
  }

  return (
    <PageContainer title='Карточка сотрудника'>
      <Container isHeader>
        <Breadcrumbs list={breadcrumbs} lastLoading={isLoading} />
        <PageTitle loading={isLoading}>{employeeName}</PageTitle>
      </Container>

      <EditEmployee employeeId={employeeId} />
    </PageContainer>
  )
}
