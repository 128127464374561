import { Grid } from '@mui/joy'
import { InspectionServiceTypeEnum } from '~/shared/config/enums'
import { FormTitle, TextInput } from '~/shared/ui/Form'
import { Container } from '~/shared/ui/Layout'

type ServiceProps = {
  loading: boolean
}

export function Service({ loading }: ServiceProps) {
  return (
    <Container>
      <FormTitle>Обслуживание</FormTitle>
      <Grid container spacing={2.5}>
        <Grid xs={6}>
          <TextInput
            label='Долив масла'
            name={`services.${InspectionServiceTypeEnum.MOTOR_OIL}`}
            readOnly
            skeletonShow={loading}
          />
        </Grid>
        <Grid xs={6}>
          <TextInput
            label='Долив антифриза'
            name={`services.${InspectionServiceTypeEnum.ANTIFREEZE}`}
            readOnly
            skeletonShow={loading}
          />
        </Grid>
        <Grid xs={6}>
          <TextInput
            label='Замена лампочек'
            name={`services.${InspectionServiceTypeEnum.LAMPS}`}
            readOnly
            skeletonShow={loading}
          />
        </Grid>
        <Grid xs={6}>
          <TextInput
            label='Замена дворников'
            name={`services.${InspectionServiceTypeEnum.WIPERS}`}
            readOnly
            skeletonShow={loading}
          />
        </Grid>
      </Grid>
    </Container>
  )
}
