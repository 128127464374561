import { Option } from 'coloquent/dist/Option'
import { useCallback } from 'react'
import { usePermissions } from '~/entities/viewer'
import { RepairOrder } from '~/shared/api'
import { AsyncGrid } from '~/shared/ui/AsyncGrid'
import type { FetchRowsFn } from '~/shared/ui/AsyncGrid/types'
import { Button } from '~/shared/ui/Buttons'
import { PlusIcon } from '~/shared/ui/Icons'
import { columns } from './columns'
import { filters } from './filters'
import { GridRow } from './types'

export const ListRepairOrders = () => {
  const { canRepairOrdersUpdate } = usePermissions()

  const fetchRows = useCallback<FetchRowsFn<GridRow>>(
    async (page, pageSize, filters = []) => {
      let builder = RepairOrder.limit(pageSize)
        .with('car')
        .with('car.subdivision')
        .with('counterparty')
        .with('repairTypes')
        .with('user')
        .orderBy('-createdAt')

      filters.forEach(({ key, value }) => {
        if (key === 'counterparty') {
          builder
            .getQuery()
            .addOption(new Option('filter[counterparty][id]', value))
          return
        }
        if (key === 'repairTypes') {
          builder
            .getQuery()
            .addOption(new Option('filter[repairTypes][id]', value))
          return
        }
        if (key === 'user') {
          builder.getQuery().addOption(new Option('filter[user][id]', value))
          return
        }
        if (key === 'subdivision') {
          builder
            .getQuery()
            .addOption(new Option('filter[car][subdivision][id]', value))
          return
        }
        if (key === 'car') {
          builder.getQuery().addOption(new Option('filter[car][id]', value))
          return
        }

        builder = builder.where(key, value)
      })

      const response = await builder.get(page)

      const data = response.getData()
      const rows = data.map((repairOrder) => {
        const car = repairOrder?.getCar()
        return {
          ...repairOrder.getAttributes(),
          id: repairOrder.getApiId() as string,
          carPlateNumber: car?.getPlateNumber(),
          carExtId: car?.getExtId(),
          carSubdivision: car?.getSubdivision()?.getTitle(),
          userName: repairOrder?.getUser().getName(),
          status: repairOrder?.getStatus(),
          counterpartyName: repairOrder?.getCounterparty()?.getName(),
          repairTypes: repairOrder
            ?.getRepairTypes()
            .map((repairType) => repairType.getTitle())
            .join(', '),
        }
      })

      const httpClientResponse = response.getHttpClientResponse()
      const axiosResponse = httpClientResponse?.getUnderlying()
      const total = axiosResponse?.data?.meta?.page?.total || 0

      return {
        rows,
        total,
      }
    },
    [],
  )

  return (
    <AsyncGrid<GridRow>
      gridKey='list-repair-oders'
      fetchRows={fetchRows}
      columns={columns}
      filters={filters}
      title='Заказ наряды'
      headerExtra={
        <Button
          startDecorator={<PlusIcon />}
          to='new'
          show={canRepairOrdersUpdate}
        >
          Добавить
        </Button>
      }
    />
  )
}
