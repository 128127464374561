import isEqual from 'lodash/isEqual'
import { useEffect, useState } from 'react'
import { Routes, Route, useLocation, Navigate } from 'react-router-dom'

import { MainLayout } from '~/widgets/layout/main'
import { MinimalLayout } from '~/widgets/layout/minimal'
import { ProtectedPage } from '~/features/Auth/protected-page'

import NotFoundPage from './404'
import { usePermissionsPage } from './hooks/usePermissionsPage'
import { ListInspectionPage } from './Inspection/List'
import ViewInspectionPage from './Inspection/View'
// import InspectionSchedulePage from './InspectionSchedulePage'
import { LoginPage } from './Login'
import { NoAccess } from './NoAccess'
import { AddRepairOrderPage } from './RepairOrders/AddRepairOrderPage'
import { EditRepairOrderPage } from './RepairOrders/EditRepairOrderPage'
import { ListRepairOrdersPage } from './RepairOrders/ListRepairOrdersPage'
import { EditRoadAccidentPage } from './RoadAccidents/EditRoadAccidentPage'
import { ListRoadAccidentsPage } from './RoadAccidents/ListRoadAccidentsPage'
import { RoadAccidentAddRepairOrderPage } from './RoadAccidents/RoadAccidentAddRepairOrderPage'
import { RoadAccidentEditRepairOrderPage } from './RoadAccidents/RoadAccidentEditRepairOrderPage'
import SettingsPage from './Settings'
import UserPersonalDataPage from './UserPersonalData'
import { AddCounterpartyPage } from './Сounterparties/AddCounterpartyPage'
import { EditCounterpartyPage } from './Сounterparties/EditCounterpartyPage'
import { ListCounterpartiesPage } from './Сounterparties/ListCounterpartiesPage'

export const Routing: React.FC = () => {
  const location = useLocation()
  const { notPermissions } = usePermissionsPage()

  const [notPermissionsPage, setNotPermissionsPage] = useState(notPermissions)

  const handleScrollTop = () => {
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    handleScrollTop()

    // Найти лучший способ для ререндера роутов
    if (!isEqual(notPermissions, notPermissionsPage)) {
      setNotPermissionsPage(notPermissions)
    }
    // eslint-disable-next-line
  }, [location])

  return (
    <Routes>
      <Route element={<ProtectedPage />}>
        <Route element={<MainLayout />}>
          {notPermissionsPage?.map((path) => (
            <Route key={path} path={path} element={<NoAccess />} />
          ))}

          <Route path='/' element={<Navigate to='/inspections' />} />

          <Route path='/inspections' element={<ListInspectionPage />} />
          <Route
            path='/inspections/:inspectionId/*'
            element={<ViewInspectionPage />}
          />
          <Route path='/settings/*' element={<SettingsPage />} />

          <Route path='/repair-orders' element={<ListRepairOrdersPage />} />
          <Route path='/repair-orders/new' element={<AddRepairOrderPage />} />
          <Route
            path='/repair-orders/:repairOrderId'
            element={<EditRepairOrderPage />}
          />

          <Route path='/road-accidents' element={<ListRoadAccidentsPage />} />
          <Route
            path='/road-accidents/:roadAccidentId'
            element={<EditRoadAccidentPage />}
          />
          <Route
            path='/road-accidents/:roadAccidentId/repair-orders/new'
            element={<RoadAccidentAddRepairOrderPage />}
          />
          <Route
            path='/road-accidents/:roadAccidentId/repair-orders/:repairOrderId'
            element={<RoadAccidentEditRepairOrderPage />}
          />

          <Route path='/counterparties' element={<ListCounterpartiesPage />} />
          <Route path='/counterparties/new' element={<AddCounterpartyPage />} />
          <Route
            path='/counterparties/:counterpartyId'
            element={<EditCounterpartyPage />}
          />

          {/*<Route*/}
          {/*  path='/inspection-schedule'*/}
          {/*  element={<InspectionSchedulePage />}*/}
          {/*/>*/}

          <Route
            path='/user-personal-data'
            element={<UserPersonalDataPage />}
          />
        </Route>
      </Route>

      <Route element={<MinimalLayout />}>
        <Route path='/login' element={<LoginPage />} />
      </Route>

      <Route element={<MainLayout />}>
        <Route path='*' element={<NotFoundPage />} />
      </Route>
    </Routes>
  )
}
