import { Box, Skeleton, Grid } from '@mui/joy'
import { useStore } from 'effector-react'
import { FormTitle, Switch } from '~/shared/ui/Form'
import { Container } from '~/shared/ui/Layout'
import { $carEquipments, requestCarEquipmentsFx } from '../model'

type EquipmentsProps = {
  loading: boolean
}

export function Equipments({ loading }: EquipmentsProps) {
  const isCarEquipmentsLoading = useStore(requestCarEquipmentsFx.pending)
  const carEquipments = useStore($carEquipments)

  if (!carEquipments)
    return <Box color='error'>Ошибка получения комплектности</Box>

  return (
    <Container>
      <FormTitle>Комплектность</FormTitle>
      <Grid container spacing={5}>
        {isCarEquipmentsLoading ? (
          <Skeleton
            sx={{
              height: '342px',
              position: 'relative',
              borderRadius: '8px',
              margin: '0 24px 24px',
            }}
          />
        ) : (
          carEquipments.map((param) => (
            <Grid xs={4} key={param.id}>
              <Switch
                label={param.label}
                name={`equipments.${param.id}`}
                readOnly
                skeletonShow={loading}
              />
            </Grid>
          ))
        )}
      </Grid>
    </Container>
  )
}
