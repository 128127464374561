import { AddRole } from '~/features/Roles/AddRole'
import { Breadcrumbs, BreadcrumbsList } from '~/shared/ui/Breadcrumbs'
import { Container, PageTitle, PageContainer } from '~/shared/ui/Layout'

const breadcrumbs: BreadcrumbsList = [
  { title: 'Роли', link: '/settings/roles' },
  { title: 'Создание роли' },
]

export const AddRolePage = () => {
  return (
    <PageContainer title='Создание роли'>
      <Container isHeader>
        <Breadcrumbs list={breadcrumbs} />
        <PageTitle>Создание роли</PageTitle>
      </Container>
      <AddRole />
    </PageContainer>
  )
}
