import {
  AutocompleteProps,
  Typography,
  CircularProgress,
  FormLabel,
  FormHelperText,
  FormControl,
  Skeleton,
  Box,
  Grid,
} from '@mui/joy'
import { useQuery } from '@tanstack/react-query'
import { uniqBy } from 'lodash'
import { SyntheticEvent, useMemo, useState } from 'react'
import { Controller, ControllerProps } from 'react-hook-form'
import { useDebounce } from 'use-debounce'
import { Option } from '~/shared/config/constants'
import { ChipList } from '~/shared/ui/Chips'
import { SearchIcon } from '~/shared/ui/Icons'
import { Listbox } from '../ui/Listbox'
import { Autocomplete } from '../ui/styled'

type AsyncAutocompleteInputProps<
  T extends Option,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> = {
  name: string
  label?: string
  skeletonShow?: boolean
  fetchOptions: (search: string) => Promise<T[]>
  queryKey?: unknown[]
  rules?: ControllerProps['rules']
  dataTestId?: string
} & Partial<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>> &
  (
    | { multiple: true; autocompleteXs: number }
    | { multiple?: false | undefined; autocompleteXs?: never }
  )

export function AsyncAutocompleteInput<T extends Option>({
  label,
  name,
  skeletonShow,
  fetchOptions,
  queryKey,
  multiple,
  autocompleteXs,
  rules,
  dataTestId,
  ...props
}: AsyncAutocompleteInputProps<T, boolean, boolean, boolean>) {
  const [search, setSearch] = useState<string>('')
  const [debouncedSearch] = useDebounce(search, 200)

  const isSearch = Boolean(debouncedSearch?.length)

  const { data, isError, isLoading, isFetching } = useQuery<T[]>(
    ['async-autocomplete-options', name, debouncedSearch, ...(queryKey || [])],
    () => fetchOptions(debouncedSearch),
    {
      keepPreviousData: false,
      retry: false,
      refetchOnWindowFocus: false,
    },
  )
  const loading = isLoading || isFetching || props.loading

  const options = useMemo(() => (data ? data : []), [data])

  const handleSearchSet = (
    _: SyntheticEvent,
    value: string,
    reason: 'input' | 'reset' | 'clear',
  ) => {
    if (reason === 'input') {
      setSearch(value)
    }
    if (reason === 'reset' || reason === 'clear') {
      setSearch('')
    }
  }

  const noOptionsText = useMemo(() => {
    if (loading) return <Typography color='primary'>Загрузка</Typography>
    if (isError) return <Typography color='danger'>Ошбка сервера</Typography>
    if (!options?.length || isSearch)
      return <Typography color='warning'>Нет вариантов</Typography>

    return <Typography>Начните вводить</Typography>
  }, [loading, isError, options?.length, isSearch])

  const layout = useMemo(() => {
    if (multiple) {
      return {
        formControl: {
          component: Grid,
          xs: 12,
        },
        autocompleteBox: {
          component: Grid,
          paddingTop: 0,
          paddingLeft: 0,
          paddingBottom: 0,
          xs: autocompleteXs,
        },
      }
    }
  }, [autocompleteXs, multiple])

  return (
    <Controller
      name={name}
      defaultValue={multiple ? [] : null}
      rules={rules}
      render={({
        field: { onChange, value },
        fieldState: { error, invalid },
      }) => (
        <FormControl error={invalid} {...layout?.formControl}>
          {label && <FormLabel>{label}</FormLabel>}

          {multiple && (
            <Box
              sx={{
                marginBottom: '24px',
                minHeight: '24px',
              }}
            >
              {skeletonShow ? (
                <Skeleton
                  sx={{
                    position: 'relative',
                    height: '24px',
                    borderRadius: '12px',
                  }}
                />
              ) : (
                <ChipList
                  type={props.readOnly ? 'link' : 'delete'}
                  onDelete={(option: Option) => {
                    const filteredValues = value.filter(
                      (v: T) => v.id !== option.id,
                    )
                    onChange(filteredValues)
                  }}
                  options={value || []}
                  chipProps={{
                    disabled: props.readOnly,
                  }}
                />
              )}
            </Box>
          )}

          <Box {...layout?.autocompleteBox}>
            <Autocomplete
              name={name}
              multiple={multiple}
              {...(multiple ? { renderTags: () => null } : {})}
              size='lg'
              value={multiple ? value || [] : value || null}
              onChange={(_, value) => {
                onChange(value)
              }}
              clearOnBlur
              onInputChange={handleSearchSet}
              options={
                value && !options.some(({ id }) => id === value?.id)
                  ? uniqBy([...(multiple ? value : [value]), ...options], 'id')
                  : options
              }
              noOptionsText={noOptionsText}
              startDecorator={!props.readOnly && <SearchIcon />}
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              slotProps={{
                ...(skeletonShow ? { root: { component: Skeleton } } : {}),
                listbox: {
                  component: Listbox,
                  params: {
                    onClick: () => {
                      onChange(
                        uniqBy([...(value || []), ...(options || [])], 'id'),
                      )
                    },
                    isSelectAll: multiple,
                  },
                },
              }}
              {...props}
              endDecorator={
                loading ? (
                  <CircularProgress data-testid='circular-progress' size='sm' />
                ) : (
                  props.endDecorator
                )
              }
            />
          </Box>

          {invalid && (
            <FormHelperText
              data-testid={`error-message-${dataTestId || label}`}
            >
              {error?.message || ''}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  )
}
