import {
  AutocompleteProps,
  Typography,
  FormLabel,
  FormHelperText,
  FormControl,
  Skeleton,
  Box,
  Grid,
  CircularProgress,
} from '@mui/joy'
import { uniqBy } from 'lodash'
import { useMemo } from 'react'
import { Controller, ControllerProps } from 'react-hook-form'
import { Option } from '~/shared/config/constants'
import { ChipList } from '~/shared/ui/Chips'
import { SearchIcon } from '~/shared/ui/Icons'
import { Listbox } from '../ui/Listbox'
import { Autocomplete } from '../ui/styled'

type AutocompleteInputProps<
  T extends Option,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> = {
  name: string
  label?: string
  skeletonShow?: boolean
  options: T[]
  rules?: ControllerProps['rules']
  dataTestId?: string
} & Partial<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>> &
  (
    | { multiple: true; autocompleteXs: number }
    | { multiple?: false | undefined; autocompleteXs?: never }
  )

export function AutocompleteInput<T extends Option>({
  label,
  name,
  skeletonShow,
  options,
  multiple,
  autocompleteXs,
  rules,
  dataTestId,
  ...props
}: AutocompleteInputProps<T, boolean, boolean, boolean>) {
  const layout = useMemo(() => {
    if (multiple) {
      return {
        formControl: {
          component: Grid,
          xs: 12,
        },
        autocompleteBox: {
          component: Grid,
          paddingTop: 0,
          paddingLeft: 0,
          paddingBottom: 0,
          xs: autocompleteXs,
        },
      }
    }
  }, [autocompleteXs, multiple])

  return (
    <Controller
      name={name}
      defaultValue={multiple ? [] : null}
      rules={rules}
      render={({
        field: { onChange, value },
        fieldState: { error, invalid },
      }) => (
        <FormControl error={invalid} {...layout?.formControl}>
          {label && <FormLabel>{label}</FormLabel>}

          {multiple && (
            <Box
              sx={{
                marginBottom: '24px',
                minHeight: '24px',
              }}
            >
              {skeletonShow ? (
                <Skeleton
                  sx={{
                    position: 'relative',
                    height: '24px',
                    borderRadius: '12px',
                  }}
                />
              ) : (
                <ChipList
                  type='delete'
                  onDelete={(option: Option) => {
                    const filteredValues = value.filter(
                      (v: T) => v.id !== option.id,
                    )
                    onChange(filteredValues)
                  }}
                  options={value || []}
                  chipProps={{
                    disabled: props.readOnly,
                  }}
                />
              )}
            </Box>
          )}

          <Box {...layout?.autocompleteBox}>
            <Autocomplete
              name={name}
              multiple={multiple}
              {...(multiple ? { renderTags: () => null } : {})}
              value={multiple ? value || [] : value || null}
              size='lg'
              onChange={(_, value) => {
                onChange(value)
              }}
              options={options}
              noOptionsText={
                <Typography color='warning'>Нет вариантов</Typography>
              }
              startDecorator={!props.readOnly && <SearchIcon />}
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              slotProps={{
                ...(skeletonShow ? { root: { component: Skeleton } } : {}),
                listbox: {
                  component: Listbox,
                  params: {
                    onClick: () => {
                      onChange(
                        uniqBy([...(value || []), ...(options || [])], 'id'),
                      )
                    },
                    isSelectAll: multiple,
                  },
                },
              }}
              {...props}
              endDecorator={
                props.loading ? (
                  <CircularProgress size='sm' />
                ) : (
                  props.endDecorator
                )
              }
            />
          </Box>

          {invalid && (
            <FormHelperText
              data-testid={`error-message-${dataTestId || label}`}
            >
              {error?.message || ''}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  )
}
