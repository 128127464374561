import { Car, InspectionReason, Region, Subdivision } from '~/shared/api'
import { Filter } from '~/shared/ui/AsyncGrid/types'

export const filters: Filter[] = [
  {
    field: 'driverFullName',
    label: 'ФИО водителя',
    type: 'text',
  },
  {
    field: 'responsibleName',
    label: 'ФИО механика',
    type: 'text',
  },
  {
    field: 'carId',
    label: 'Гос. номер',
    type: 'autocomplete',
    fetchOptions: Car.fetchPlateNumberOptions,
  },
  {
    field: 'carVin',
    label: 'VIN',
    type: 'text',
  },
  {
    label: 'Дата осмотра',
    type: 'dateRange',
    startField: 'finishedAtDateGte',
    endField: 'finishedAtDateLte',
  },
  {
    field: 'reasonId',
    label: 'Причина осмотра',
    type: 'autocomplete',
    fetchOptions: InspectionReason.fetchOptions,
  },
  {
    field: 'regionId',
    label: 'Регион',
    type: 'autocomplete',
    fetchOptions: Region.fetchOptions,
  },
  {
    field: 'subdivisionId',
    label: 'Подразделение',
    type: 'autocomplete',
    fetchOptions: Subdivision.fetchOptions,
  },
  {
    field: 'comment',
    label: 'Комментарий',
    type: 'text',
  },
  {
    field: 'hasDamages',
    label: 'Есть повреждения',
    type: 'boolean',
  },
  {
    field: 'hasDocumentsLoss',
    label: 'Утеря документов',
    type: 'boolean',
  },
]
