import { useStore, useStoreMap } from 'effector-react'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import {
  editCounterpartyModel,
  EditCounterparty,
} from '~/features/Counterparties/EditCounterparty'
import { counterpartyModel } from '~/entities/Counterparty'
import { Breadcrumbs, BreadcrumbsList } from '~/shared/ui/Breadcrumbs'
import { Container, PageTitle, PageContainer } from '~/shared/ui/Layout'
import { NotFound } from '~/shared/ui/NotFound'

export const EditCounterpartyPage = () => {
  const { counterpartyId } = useParams<{ counterpartyId: string }>()

  const isLoading = useStore(counterpartyModel.requestFx.pending)
  const name = useStoreMap(
    editCounterpartyModel.$counterparty,
    (counterparty) => counterparty?.getName(),
  )

  const breadcrumbs: BreadcrumbsList = useMemo(
    () => [
      { title: 'Контрагенты', link: '/counterparties' },
      { title: name || '' },
    ],
    [name],
  )

  const error = counterpartyModel.useCounterpartyError(counterpartyId as string)

  if (!counterpartyId || error) {
    return <NotFound message='Не удалось загрузить контрагента' />
  }

  return (
    <PageContainer title='Карточка контргента'>
      <Container isHeader>
        <Breadcrumbs list={breadcrumbs} lastLoading={isLoading} />
        <PageTitle loading={isLoading}>{name}</PageTitle>
      </Container>

      <EditCounterparty counterpartyId={counterpartyId} />
    </PageContainer>
  )
}
