import { Box, Typography, Skeleton } from '@mui/joy'
import { ThumbType } from '~/shared/api'
import { InspectionPhotoTypeEnum } from '~/shared/config/enums'
import { FormTitle } from '~/shared/ui/Form'
import { Gallery, mapPhotoToImage } from '~/shared/ui/Gallery'
import { GalleryImage } from '~/shared/ui/Gallery/type'
import { Container } from '~/shared/ui/Layout'

type PhotosProps = {
  photos: ThumbType[]
  loading: boolean
}

export function Photos({ photos, loading }: PhotosProps) {
  const carPhotos = photos
    .filter(({ type }) => type === InspectionPhotoTypeEnum.CAR)
    .map(mapPhotoToImage)
    .filter((photo): photo is GalleryImage => !!photo)

  const tariffsPhotos = photos
    .filter(({ type }) => type === InspectionPhotoTypeEnum.TARIFFS)
    .map(mapPhotoToImage)
    .filter((photo): photo is GalleryImage => !!photo)

  const mileagePhotos = photos
    .filter(({ type }) => type === InspectionPhotoTypeEnum.MILEAGE)
    .map(mapPhotoToImage)
    .filter((photo): photo is GalleryImage => !!photo)

  const lastToPhotos = photos
    .filter(({ type }) => type === InspectionPhotoTypeEnum.LAST_TO)
    .map(mapPhotoToImage)
    .filter((photo): photo is GalleryImage => !!photo)

  const emptyPhoto = <Typography>Фото отсутствует</Typography>

  return (
    <Container sx={{ minHeight: '220px' }}>
      {loading && (
        <>
          <FormTitle>Фото</FormTitle>
          <Box sx={{ position: 'relative', flex: 1 }}>
            <Skeleton sx={{ borderRadius: '8px' }} />
          </Box>
        </>
      )}
      {!loading && (
        <>
          <FormTitle>Круговое фото авто</FormTitle>
          {carPhotos?.length ? <Gallery images={carPhotos} /> : emptyPhoto}
          <br />

          <FormTitle>Тарифная сетка</FormTitle>
          {tariffsPhotos?.length ? (
            <Gallery images={tariffsPhotos} />
          ) : (
            emptyPhoto
          )}
          <br />

          <FormTitle>Пробег</FormTitle>
          {mileagePhotos?.length ? (
            <Gallery images={mileagePhotos} />
          ) : (
            emptyPhoto
          )}
          <br />

          <FormTitle>Бирка ТО</FormTitle>
          {lastToPhotos?.length ? (
            <Gallery images={lastToPhotos} />
          ) : (
            emptyPhoto
          )}
        </>
      )}
    </Container>
  )
}
