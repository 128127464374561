import { Option } from 'coloquent/dist/Option'
import { useCallback } from 'react'
import { RoadAccident } from '~/shared/api'
import { AsyncGrid } from '~/shared/ui/AsyncGrid'
import type { FetchRowsFn } from '~/shared/ui/AsyncGrid/types'
import { columns } from './columns'
import { filters } from './filters'
import { GridRow } from './types'

export const ListRoadAccidents = () => {
  const fetchRows = useCallback<FetchRowsFn<GridRow>>(
    async (page, pageSize, filters = []) => {
      let builder = RoadAccident.limit(pageSize)
        .with('car')
        .with('car.brandModel')
        .with('subdivision')
        .with('user')
        .with('inspection')
        .with('repairOrder')
        .orderBy('-createdAt')

      filters.forEach(({ key, value }) => {
        if (key === 'carId') {
          builder.getQuery().addOption(new Option('filter[car][id]', value))
          return
        }
        if (key === 'userId') {
          builder.getQuery().addOption(new Option('filter[user][id]', value))
          return
        }
        if (key === 'subdivisionId') {
          builder
            .getQuery()
            .addOption(new Option('filter[subdivision][id]', value))
          return
        }
        if (key === 'repairOrderId') {
          builder
            .getQuery()
            .addOption(new Option('filter[repairOrder][id]', value))
          return
        }
        builder = builder.where(key, value)
      })

      const response = await builder.get(page)

      const data = response.getData()
      const rows = data.map((roadAccident) => {
        const car = roadAccident?.getCar()
        const inspection = roadAccident?.getInspection()

        return {
          ...roadAccident.getAttributes(),
          id: roadAccident.getApiId() as string,
          status: roadAccident.getStatus(),
          carExtId: car?.getExtId(),
          carBrandModel: car?.getBrandModel()?.getTitle(),
          carStatus: car?.getStatus(),
          carSubdivision: roadAccident?.getSubdivision()?.getTitle(),
          responsible: roadAccident?.getUser()?.getName(),
          repairOrder: Boolean(roadAccident?.getRepairOrder()),
          carPlateNumber: inspection?.getPlateNumber(),
          driverFullName: inspection?.getDriverFullName(),
        }
      })

      const httpClientResponse = response.getHttpClientResponse()
      const axiosResponse = httpClientResponse?.getUnderlying()
      const total = axiosResponse?.data?.meta?.page?.total || 0

      return {
        rows,
        total,
      }
    },
    [],
  )

  return (
    <AsyncGrid<GridRow>
      gridKey='road-accidents'
      fetchRows={fetchRows}
      columns={columns}
      filters={filters}
      title='ДТП и Страховые случаи'
    />
  )
}
