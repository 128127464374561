import { Grid } from '@mui/joy'
import { useStore } from 'effector-react'
import { useMemo, useState } from 'react'
import { Element } from 'react-scroll'
import { inspectionModel } from '~/entities/Inspection'
import {
  Damage,
  DamageModal,
  ListHistoryDamages,
  ListNewDamages,
} from '~/entities/Inspection/ui/Damage'
import { FormTitle } from '~/shared/ui/Form'
import { Container } from '~/shared/ui/Layout'
import { PolygonMarkers } from '~/shared/ui/Polygon'
import carBodySvg from '~/shared/ui/Polygon/Images/carBody.svg'
import carEnvironmentSvg from '~/shared/ui/Polygon/Images/carEnvironment.svg'
import carInteriorSvg from '~/shared/ui/Polygon/Images/carInterior.svg'
import { PolygonMarker } from '~/shared/ui/Polygon/types'
import { mapDamagesToMarkers } from './mappers'
import { Legend } from './ui/Legend'

export function Damages() {
  const damagesExterior = useStore(inspectionModel.$damagesExterior)
  const damagesTire = useStore(inspectionModel.$damagesTire)
  const damagesInterior = useStore(inspectionModel.$damagesInterior)
  const damagesEnvironment = useStore(inspectionModel.$damagesEnvironment)

  const oldDamagesExterior = useStore(inspectionModel.$oldDamagesExterior)
  const oldDamagesTire = useStore(inspectionModel.$oldDamagesTire)
  const oldDamagesInterior = useStore(inspectionModel.$oldDamagesInterior)
  const oldDamagesEnvironment = useStore(inspectionModel.$oldDamagesEnvironment)

  const damages = useMemo(
    () => [
      ...damagesExterior,
      ...damagesTire,
      ...damagesInterior,
      ...damagesEnvironment,
      ...oldDamagesExterior,
      ...oldDamagesTire,
      ...oldDamagesInterior,
      ...oldDamagesEnvironment,
    ],
    [
      damagesEnvironment,
      damagesExterior,
      damagesInterior,
      damagesTire,
      oldDamagesEnvironment,
      oldDamagesExterior,
      oldDamagesInterior,
      oldDamagesTire,
    ],
  )

  const markersBody = useMemo(
    () => [
      ...mapDamagesToMarkers(damagesExterior),
      ...mapDamagesToMarkers(damagesTire),
      ...mapDamagesToMarkers(oldDamagesExterior, true),
      ...mapDamagesToMarkers(oldDamagesTire, true),
    ],
    [damagesExterior, damagesTire, oldDamagesExterior, oldDamagesTire],
  )

  const markersInterior = useMemo(
    () => [
      ...mapDamagesToMarkers(damagesInterior),
      ...mapDamagesToMarkers(oldDamagesInterior, true),
    ],
    [damagesInterior, oldDamagesInterior],
  )

  const markersEnvironment = useMemo(
    () => [
      ...mapDamagesToMarkers(damagesEnvironment),
      ...mapDamagesToMarkers(oldDamagesEnvironment, true),
    ],
    [damagesEnvironment, oldDamagesEnvironment],
  )

  const [selectedDamage, setSelectedDamage] = useState<Damage>()
  const [markerHighlightingId, setMarkerHighlightingId] = useState<UniqueId>()

  const handleSelectedMarker = (marker: PolygonMarker) => {
    const foundMarker = damages.find(({ id }) => id === marker.id)
    setSelectedDamage(foundMarker)
  }

  const handleDamageModalClose = () => {
    setSelectedDamage(undefined)
  }

  const handleDamageSelected = (damageId: UniqueId) => {
    setMarkerHighlightingId(damageId)
  }

  return (
    <>
      <Element name='damagesMap'>
        <Container>
          <FormTitle>Карта повреждений</FormTitle>
          <Grid container spacing={2.5}>
            <Grid xs={4} sx={{ paddingTop: 0 }}>
              <PolygonMarkers
                img={carBodySvg}
                onMarkerSelect={handleSelectedMarker}
                markers={markersBody}
                markerHighlightingId={markerHighlightingId}
              />
            </Grid>
            <Grid xs={4} sx={{ paddingTop: 0 }}>
              <PolygonMarkers
                img={carInteriorSvg}
                onMarkerSelect={handleSelectedMarker}
                markers={markersInterior}
                markerHighlightingId={markerHighlightingId}
              />
            </Grid>
            <Grid xs={4} sx={{ paddingTop: 0 }}>
              <PolygonMarkers
                img={carEnvironmentSvg}
                onMarkerSelect={handleSelectedMarker}
                markers={markersEnvironment}
                markerHighlightingId={markerHighlightingId}
              />
            </Grid>
          </Grid>
          <Legend />
        </Container>
      </Element>

      <Element name='damagesNew'>
        <Container data-testid='damages-new'>
          <ListNewDamages onRowClick={handleDamageSelected} />
        </Container>
      </Element>

      <Element name='damagesHistory'>
        <Container data-testid='damages-history'>
          <ListHistoryDamages onRowClick={handleDamageSelected} />
        </Container>
      </Element>

      <DamageModal
        isShow={Boolean(selectedDamage)}
        onClose={handleDamageModalClose}
        selectedDamage={selectedDamage}
      />
    </>
  )
}
