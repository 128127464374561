import { attach, sample } from 'effector'
import { editRepairOrderModel } from '~/features/RepairOrders/EditRepairOrder'
import { repairOrderModel } from '~/entities/RepairOrder'
import { RepairOrder } from '~/shared/api'
import { OptionEnum } from '~/shared/config/constants'
import { RepairOrderPaymentStatusEnum } from '~/shared/config/enums'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { isString } from '~/shared/lib/utils'

export type StatusFormValues = {
  paymentStatus: OptionEnum<RepairOrderPaymentStatusEnum> | null
}

export const paymentStatusChangeFx = attach({
  effect: repairOrderModel.saveFx,
  source: editRepairOrderModel.$repairOrderId,
  mapParams: (paymentStatus: StatusFormValues['paymentStatus'], id) => {
    return new RepairOrder(
      {
        ...(isString(paymentStatus?.id) && { paymentStatus: paymentStatus.id }),
      },
      id as string,
    )
  },
})

sample({
  clock: paymentStatusChangeFx.doneData,
  fn() {
    return {
      message: 'Статус оплаты успешно изменён',
      variant: 'success' as const,
    }
  },
  target: snackbarEnqueued,
})
