import { createDomain, sample, combine, attach } from 'effector'
import { createGate } from 'effector-react'
import { FormValues, employeeModel } from '~/entities/Employee'

import { Employee } from '~/shared/api'
import { logger } from '~/shared/lib/logger'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { isString } from '~/shared/lib/utils'
import { formButtonsModel } from '~/shared/ui/Form'

export const Gate = createGate<{ employeeId: UniqueId }>()
export const domain = createDomain('features.employees.edit')

export const $employeeId = domain
  .createStore<UniqueId | null>(null)
  .on(Gate.state, (_, { employeeId }) => employeeId)

export const $employee = combine(
  $employeeId,
  employeeModel.$employeesCache,
  (id, cache) => {
    if (!id) return null
    return cache.map[id] ?? null
  },
)

sample({
  clock: $employeeId,
  filter: isString,
  target: employeeModel.requestFx,
})

export const employeeUpdateFx = attach({
  effect: employeeModel.saveFx,
  source: $employeeId,
  mapParams: (values: FormValues, id) => {
    const { rolesIds, regionsIds, subdivisionsIds, ...attr } = values
    const employee = new Employee(attr, id as string)

    if (Array.isArray(regionsIds)) employee.setRegions(regionsIds)
    if (Array.isArray(subdivisionsIds))
      employee.setSubdivisions(subdivisionsIds)
    if (Array.isArray(rolesIds)) employee.setRoles(rolesIds)

    return employee
  },
})

export const formSubmitted = domain.createEvent<FormValues>()
sample({
  clock: formSubmitted,
  target: employeeUpdateFx,
})

sample({
  clock: employeeUpdateFx.doneData,
  fn() {
    return {
      message: 'Сохранено успешно',
      variant: 'success' as const,
    }
  },
  target: [snackbarEnqueued, formButtonsModel.editingEnded],
})

sample({
  clock: employeeUpdateFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: 'Ошибка при сохранении данных сотрудника',
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})
