import { createDomain } from 'effector'
import { useStoreMap } from 'effector-react'
import * as z from 'zod'
import { Counterparty } from '~/shared/api'
import { createCache } from '~/shared/lib/mapCacheFactory'

export const formSchema = Counterparty.schema.pick({
  name: true,
  inn: true,
  kpp: true,
  ogrn: true,
})

export type SchemaType = z.infer<typeof formSchema>
export type FormValues = SchemaType

export const domain = createDomain('entities.counterparty')

export const requestFx = domain.createEffect<UniqueId, Counterparty>({
  handler: fetchCounterparty,
})

export const saveFx = domain.createEffect<Counterparty, Counterparty>({
  async handler(counterparty) {
    await counterparty.save()
    return fetchCounterparty(counterparty.getApiId() as UniqueId)
  },
})

const {
  $cache: $counterpartyCache,
  useCache: useCounterpartyCache,
  updateCache,
} = createCache<Counterparty>({
  domain,
  getEntityId: (counterparty) => counterparty.getApiId() as UniqueId,
})
export { $counterpartyCache, useCounterpartyCache }

$counterpartyCache
  .on(requestFx.doneData, (cache, counterparty) =>
    updateCache(cache, [counterparty]),
  )
  .on(saveFx.doneData, (cache, counterparty) =>
    updateCache(cache, [counterparty], true),
  )

export const $counterpartyError = domain
  .createStore<Record<UniqueId, Error>>({})
  .on([requestFx.fail], (store, { error, params: id }) => ({
    [id]: error,
    ...store,
  }))
export const useCounterpartyError = (id: UniqueId) =>
  useStoreMap($counterpartyError, (errors) => errors[id])

async function fetchCounterparty(id: UniqueId) {
  const response = await Counterparty.find(id)
  return response.getData() as Counterparty
}
