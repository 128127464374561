import { createDomain, sample } from 'effector'
import { createGate } from 'effector-react'
import { Permission, OptionTitle } from '~/shared/api'
import { logger } from '~/shared/lib/logger'
import { snackbarEnqueued } from '~/shared/lib/notifications'

export const domain = createDomain('entities.permissions')

export const PermissionsGate = createGate()
export const fetchPermissionsFx = domain.createEffect({
  async handler() {
    return await fetchPermissionList()
  },
})

sample({
  clock: PermissionsGate.open,
  target: fetchPermissionsFx,
})

sample({
  clock: fetchPermissionsFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: 'Ошибка при получении списка прав',
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

async function fetchPermissionList() {
  const response = await Permission.limit(1000).get(1)
  return response.getData()?.map((item: Permission) => item.getOptionTitle())
}

export const $permissions = domain
  .createStore<OptionTitle[] | null>(null)
  .on(fetchPermissionsFx.doneData, (store, fetchData) => fetchData)
  .on(PermissionsGate.close, () => [])
