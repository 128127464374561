import { Grid } from '@mui/joy'
import { useFormContext } from 'react-hook-form'
import { IMask } from 'react-imask'
import { FormValues } from '~/entities/RoadAccident'
import { TMS_DOMAIN } from '~/shared/config/env'
import { LinkDecorator } from '~/shared/ui/Buttons'
import {
  DatePickerInput,
  FormTitle,
  NumericInput,
  Switch,
  TextInput,
  TextareaInput,
  MaskInput,
} from '~/shared/ui/Form'
import { Container } from '~/shared/ui/Layout'

type MainProps = {
  disabled: boolean
  loading: boolean
}

export function Main({ disabled, loading }: MainProps) {
  const { watch } = useFormContext<FormValues>()

  const carExtId = watch('carExtId')
  const inspectionId = watch('inspectionId')

  return (
    <Container>
      <FormTitle>Информация</FormTitle>
      <Grid container spacing={2.5}>
        <Grid xs={6}>
          <DatePickerInput
            label='Дата создания'
            placeholder='Дата создания'
            name='createdAt'
            skeletonShow={loading}
            readOnly
          />
        </Grid>
        <Grid xs={3}>
          <DatePickerInput
            label='Дата ДТП'
            placeholder='Выберите дату ДТП'
            name='accidentDate'
            skeletonShow={loading}
            readOnly={disabled}
          />
        </Grid>
        <Grid xs={3}>
          <MaskInput
            label='Время ДТП'
            placeholder='Введите время ДТП'
            name='accidentTime'
            readOnly={disabled}
            skeletonShow={loading}
            mask='HH:MM'
            blocks={{
              HH: {
                mask: IMask.MaskedRange,
                from: 0,
                to: 23,
              },
              MM: {
                mask: IMask.MaskedRange,
                from: 0,
                to: 59,
              },
            }}
          />
        </Grid>

        <Grid xs={2}>
          <Switch
            label='Эвакуация'
            name='evacuation'
            readOnly={disabled}
            skeletonShow={loading}
            formControlProps={{ sx: { marginTop: '40px' } }}
          />
        </Grid>
        <Grid xs={3}>
          <Switch
            label='Соглашение с водителем'
            name='agreementWithDriver'
            readOnly={disabled}
            skeletonShow={loading}
            formControlProps={{ sx: { marginTop: '40px' } }}
          />
        </Grid>
        <Grid xs={7}>
          <TextInput
            label='Место ДТП'
            placeholder='Укажите место ДТП'
            name='accidentPlace'
            readOnly={disabled}
            skeletonShow={loading}
          />
        </Grid>

        <Grid xs={4}>
          <TextInput
            label='ТС'
            placeholder='ТС'
            name='carPlateNumber'
            readOnly
            skeletonShow={loading}
            endDecorator={
              carExtId && (
                <LinkDecorator
                  tooltipText='Перейти в карточку автомобиля'
                  href={`${TMS_DOMAIN}cars/${carExtId}`}
                />
              )
            }
          />
        </Grid>
        <Grid xs={4}>
          <TextInput
            label='Подразделение ТС'
            placeholder='Подразделение ТС'
            name='carSubdivision'
            readOnly
            skeletonShow={loading}
          />
        </Grid>
        <Grid xs={4}>
          <TextInput
            label='Статус ТС'
            placeholder='Статус ТС'
            name='carStatus'
            readOnly
            skeletonShow={loading}
          />
        </Grid>

        <Grid xs={4}>
          <TextInput
            label='Водитель ФИО'
            placeholder='Водитель ФИО'
            name='driverFullName'
            readOnly
            skeletonShow={loading}
          />
        </Grid>
        <Grid xs={4}>
          <TextInput
            label='Осмотр ТС'
            placeholder='Укажите осмотр ТС'
            name='inspection'
            readOnly
            skeletonShow={loading}
            endDecorator={
              inspectionId && (
                <LinkDecorator
                  tooltipText='Перейти в карточку автомобиля'
                  to={`/inspections/${inspectionId}`}
                />
              )
            }
          />
        </Grid>
        <Grid xs={4}>
          <NumericInput
            name='mileage'
            placeholder='Пробег'
            label='Пробег'
            skeletonShow={loading}
            readOnly
          />
        </Grid>

        <Grid xs={6} container>
          <Grid xs={12}>
            <NumericInput
              name='repairEstimatedCost'
              label='Оценочная стоимость ремонта'
              placeholder='Оценочная стоимость ремонта'
              skeletonShow={loading}
              readOnly
            />
          </Grid>
          <Grid xs={12}>
            <DatePickerInput
              label='Дата закрытия долга'
              placeholder='Выберите дату закрытия долга'
              name='debtClosingDate'
              skeletonShow={loading}
              readOnly={disabled}
            />
          </Grid>
        </Grid>
        <Grid xs={6}>
          <TextareaInput
            label='Комментарий'
            name='comment'
            placeholder='Введите комментарий'
            sx={{
              height: '138px',
            }}
            skeletonShow={loading}
            readOnly={disabled}
          />
        </Grid>

        <Grid xs={4}>
          <NumericInput
            label='Возмещено водителем'
            name='reimbursedByDriver'
            placeholder='Введите сумму возмещенную водителем'
            skeletonShow={loading}
            readOnly={disabled}
          />
        </Grid>
        <Grid xs={4}>
          <NumericInput
            label='Возмещено виновником'
            name='reimbursedByCulprit'
            placeholder='Введите сумму возмещенную виновником'
            skeletonShow={loading}
            readOnly={disabled}
          />
        </Grid>
        <Grid xs={4}>
          <NumericInput
            label='Остаток суммы'
            placeholder='Остаток суммы'
            name='remainingAmount'
            skeletonShow={loading}
            readOnly
          />
        </Grid>
      </Grid>
    </Container>
  )
}
