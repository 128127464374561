import { userOptionsStore } from '~/shared/api'

export const usePermissions = () => {
  const permissions = userOptionsStore.permissions || []

  const checkPermissions = (permission: string) =>
    permissions.includes(permission)

  const canUsersUpdate = checkPermissions('update users') // Создавать/изменять пользователей
  const canUsersView = checkPermissions('view users') // Просматривать пользователей

  const canRolesUpdate = checkPermissions('update roles') // Создавать/изменять роли
  const canRolesView = checkPermissions('view roles') // Просматривать роли

  const canRepairOrdersUpdate = checkPermissions('update repair orders') // Редактировать заказ-наряды
  const canRepairOrdersView = checkPermissions('view repair orders') // Просматривать заказ-наряды

  const canInspectionsView = checkPermissions('view inspections') // Просматривать осмотры

  const canRoadAccidentsUpdate = checkPermissions('update road accidents') // Редактировать карточки ДТП
  const canRoadAccidentsView = checkPermissions('view road accidents') // Просматривать карточки ДТП

  const canAuditsView = checkPermissions('view audits') // Просмотр истории изменений

  const canSettingsView = canUsersView || canRolesView

  return {
    canUsersUpdate,
    canUsersView,
    canRolesUpdate,
    canRolesView,
    canSettingsView,
    canInspectionsView,
    canRepairOrdersUpdate,
    canRepairOrdersView,
    canRoadAccidentsView,
    canRoadAccidentsUpdate,
    canAuditsView,
  }
}
