import { useStore, useGate } from 'effector-react'
import { permissionModel } from '~/entities/Permission'
import { roleModel } from '~/entities/Role'
import { Form } from '~/entities/Role'
import { usePermissions } from '~/entities/viewer'
import { $editRoleModel, formSubmitted, Gate, roleUpdateFx } from './model'

type EditRoleProps = {
  roleId: string
}

export function EditRole({ roleId }: EditRoleProps) {
  useGate(Gate, { roleId })
  useGate(permissionModel.PermissionsGate)
  const { canRolesUpdate } = usePermissions()

  const isLoading = useStore(roleModel.requestFx.pending)
  const isPermissionsLoading = useStore(
    permissionModel.fetchPermissionsFx.pending,
  )
  const isSaving = useStore(roleUpdateFx.pending)
  const permissions = useStore(permissionModel.$permissions)

  const defaultValues = useStore($editRoleModel)

  return (
    <Form
      isLoading={isLoading}
      isPermissionsLoading={isPermissionsLoading}
      defaultValues={defaultValues}
      permissions={permissions || []}
      onSuccess={formSubmitted}
      isSubmitting={isSaving}
      canEdit={canRolesUpdate}
    />
  )
}
