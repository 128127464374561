import { Grid } from '@mui/joy'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Damage, DamageFormValues } from '~/entities/Inspection/ui/Damage'
import { AutocompleteInput, FormProvider, NumericInput } from '~/shared/ui/Form'
import { Gallery, mapPhotoToImage } from '~/shared/ui/Gallery'
import { GalleryImage } from '~/shared/ui/Gallery/type'

const defaultValues = {
  carDamageExtent: null,
  carDetail: null,
  photos: [],
}

type FormProps = {
  damage?: Damage
}

export function Form({ damage }: FormProps) {
  const form = useForm<DamageFormValues>()
  const { reset } = form

  const photos = form.watch('photos')
  const carDamageExtent = form.watch('carDamageExtent')
  const carDetail = form.watch('carDetail')

  const images = (photos || [])
    .map(mapPhotoToImage)
    .filter((photo): photo is GalleryImage => !!photo)

  useEffect(() => {
    reset(damage || defaultValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [damage])

  return (
    <FormProvider form={form}>
      <Grid container spacing={2.5} margin={0}>
        <Grid xs={12}>
          <AutocompleteInput
            label='Степень повреждения'
            name='carDamageExtent'
            options={carDamageExtent ? [carDamageExtent] : []}
            readOnly
          />
        </Grid>

        <Grid xs={12}>
          <AutocompleteInput
            label='Область повреждения'
            name='carDetail'
            options={carDetail ? [carDetail] : []}
            readOnly
          />
        </Grid>

        <Grid xs={12}>
          <NumericInput
            label='Стоимость повреждения'
            name='cost'
            readOnly
            numericProps={{
              suffix: ' руб',
            }}
          />
        </Grid>

        <Grid xs={12}>
          <Gallery images={images} xs={3} />
        </Grid>
      </Grid>
    </FormProvider>
  )
}
