import { createDomain, sample, combine, attach } from 'effector'
import { createGate } from 'effector-react'
import { roadAccidentModel } from 'src/entities/RoadAccident'
import { FormValues } from 'src/entities/RoadAccident'
import { RoadAccident } from '~/shared/api'
import { logger } from '~/shared/lib/logger'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { isString } from '~/shared/lib/utils'
import { formButtonsModel } from '~/shared/ui/Form'

export const Gate = createGate<{ roadAccidentId: UniqueId }>()
export const domain = createDomain('features.roadAccident.edit')

export const $roadAccidentId = domain
  .createStore<UniqueId | null>(null)
  .on(Gate.state, (_, { roadAccidentId }) => roadAccidentId)

export const $roadAccident = combine(
  $roadAccidentId,
  roadAccidentModel.$roadAccidentCache,
  (id, cache) => {
    if (!id) return null
    return cache.map[id] ?? null
  },
)

sample({
  clock: Gate.open,
  source: $roadAccidentId,
  filter: isString,
  target: roadAccidentModel.requestFx,
})

export const roadAccidentUpdateFx = attach({
  effect: roadAccidentModel.saveFx,
  source: $roadAccidentId,
  mapParams: (formValues: FormValues, id) => {
    const {
      culpability,
      accidentRegistrationType,
      insurancePolicyStatus,
      insurancePolicyStatus2,
      insurancePolicyStatus3,
      icReviewStatus,
      files,
      ...attr
    } = formValues

    return new RoadAccident(
      {
        ...attr,
        fileIds: (files || []).map(({ uuid }) => uuid),
        ...(isString(culpability) && { culpability }),
        ...(isString(accidentRegistrationType) && { accidentRegistrationType }),
        ...(isString(insurancePolicyStatus) && { insurancePolicyStatus }),
        ...(isString(insurancePolicyStatus2) && { insurancePolicyStatus2 }),
        ...(isString(insurancePolicyStatus3) && { insurancePolicyStatus3 }),
        ...(isString(icReviewStatus) && { icReviewStatus }),
      },
      id as string,
    )
  },
})

export const formSubmitted = domain.createEvent<FormValues>()
sample({
  clock: formSubmitted,
  target: roadAccidentUpdateFx,
})

sample({
  clock: roadAccidentUpdateFx.doneData,
  fn() {
    return {
      message: 'Сохранено успешно',
      variant: 'success' as const,
    }
  },
  target: [snackbarEnqueued, formButtonsModel.editingEnded],
})

sample({
  clock: roadAccidentUpdateFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})
