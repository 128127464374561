import * as z from 'zod'
import { ApiModel } from '~/shared/api/core'
import { dateSchema } from '~/shared/lib/schemas'

const attributeSchema = z.object({
  title: z.string(),
})
const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributeSchema)

export type RepairTypeAttributes = z.infer<typeof attributeSchema>

export class RepairType extends ApiModel<typeof schema, RepairTypeAttributes> {
  static jsonApiType = 'repair-types'
  static schema = schema

  getTitle(): string {
    return this.getAttribute('title')
  }

  getOption(): { id: UniqueId; label: string } {
    return {
      id: this.getApiId() as UniqueId,
      label: this.getTitle(),
    }
  }

  static async fetchOptions() {
    const response = await RepairType.get(1)
    return response.getData().map((o) => o.getOption())
  }
}
