import { createDomain, sample } from 'effector'
import { FormValues } from '~/entities/RepairOrder/model'
import { AxiosErrorType, RepairOrder } from '~/shared/api'
import { backFx } from '~/shared/lib/history'
import { logger } from '~/shared/lib/logger'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { isString } from '~/shared/lib/utils'

export const domain = createDomain('features.repairOrder.add')

export const createFx = domain.createEffect<
  FormValues,
  RepairOrder,
  AxiosErrorType
>({
  async handler(values) {
    const {
      status,
      paymentStatus,
      carOption,
      counterpartyOption,
      repairTypesOptions,
      files,
      roadAccidentIds,
      ...attr
    } = values

    const repairOrder = new RepairOrder({
      ...attr,
      ...(isString(status) && { status }),
      ...(isString(paymentStatus) && { paymentStatus }),
      fileIds: (files || []).map(({ uuid }) => uuid),
      roadAccidentIds: roadAccidentIds?.filter(isString),
    })

    if (isString(carOption)) repairOrder.setCar(carOption)
    if (isString(counterpartyOption))
      repairOrder.setCounterparty(counterpartyOption)

    if (Array.isArray(repairTypesOptions))
      repairOrder.setRepairTypes(repairTypesOptions)

    const result = await repairOrder.save()
    return result.getModel() as RepairOrder
  },
})

export const formSubmitted = domain.createEvent<FormValues>()
sample({
  clock: formSubmitted,
  target: createFx,
})

sample({
  clock: createFx.doneData,
  fn() {
    return {
      message: 'Заказ наряд создан',
      variant: 'success' as const,
    }
  },
  target: [snackbarEnqueued, backFx],
})
sample({
  clock: createFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})
