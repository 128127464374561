import { Car, Subdivision } from '~/shared/api'
import {
  incidentResponsibleEnumOptions,
  roadAccidentsEnumOptions,
} from '~/shared/config/constants'
import { Filter } from '~/shared/ui/AsyncGrid/types'

export const filters: Filter[] = [
  {
    label: 'Номер',
    field: 'number',
    type: 'text',
  },
  {
    label: 'Дата',
    startField: 'createdAtGte',
    endField: 'createdAtLte',
    type: 'dateRange',
  },
  {
    label: 'Статус',
    field: 'status',
    type: 'select',
    options: roadAccidentsEnumOptions,
  },
  {
    label: 'Гос. номер',
    field: 'carId',
    type: 'autocomplete',
    fetchOptions: Car.fetchPlateNumberOptions,
  },
  {
    label: 'Подразделение',
    field: 'subdivisionId',
    type: 'autocomplete',
    fetchOptions: Subdivision.fetchOptions,
  },
  {
    label: 'Заказ-наряд',
    field: 'hasRepairOrder',
    type: 'select',
    options: [
      { id: 'true', label: 'Да' },
      { id: 'false', label: 'Нет' },
    ],
  },
  {
    label: 'Виновность',
    field: 'culpability',
    type: 'select',
    options: incidentResponsibleEnumOptions,
  },
]
