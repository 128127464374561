import { useStore, useStoreMap } from 'effector-react'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { editRepairOrderModel } from '~/features/RepairOrders/EditRepairOrder'
import { getPaymentStatusColor, repairOrderModel } from '~/entities/RepairOrder'
import {
  repairOrderPaymentStatusEnumLabels,
  repairOrderPaymentStatusEnumOptions,
  STATUS_VARIANT,
} from '~/shared/config/constants'
import { AutocompleteInput, FormProvider } from '~/shared/ui/Form'
import { paymentStatusChangeFx, StatusFormValues } from './model'

export function PaymentStatus() {
  const isChanged = useStore(paymentStatusChangeFx.pending)
  const isLoading = useStore(repairOrderModel.requestFx.pending)

  const defaultValues = useStoreMap(
    editRepairOrderModel.$repairOrder,
    (repairOrder) => {
      const paymentStatus = repairOrder?.getPaymentStatus()
      return {
        paymentStatus: paymentStatus
          ? {
              id: paymentStatus,
              label: repairOrderPaymentStatusEnumLabels[paymentStatus],
            }
          : null,
      }
    },
  )

  useEffect(() => {
    reset(defaultValues)
    // eslint-disable-next-line
  }, [defaultValues])

  const form = useForm<StatusFormValues>({
    defaultValues,
  })
  const { reset, watch, setValue } = form

  const paymentStatus = watch('paymentStatus')?.id

  const statusColor = useMemo(
    () => getPaymentStatusColor(paymentStatus),
    [paymentStatus],
  )

  const handleStatusChange = async (
    paymentStatus: StatusFormValues['paymentStatus'],
  ) => {
    try {
      setValue('paymentStatus', paymentStatus)
      await paymentStatusChangeFx(paymentStatus)
    } catch (e) {
      reset(defaultValues)
    }
  }

  return (
    <FormProvider form={form}>
      <AutocompleteInput
        name='paymentStatus'
        options={repairOrderPaymentStatusEnumOptions}
        startDecorator={null}
        disableClearable
        skeletonShow={isLoading}
        readOnly={isChanged}
        loading={isChanged}
        onChange={(_, value) =>
          handleStatusChange(value as StatusFormValues['paymentStatus'])
        }
        size='sm'
        sx={{
          width: '130px',
          marginBottom: '8px',
          border: 'none',
          '&': {
            ...STATUS_VARIANT[statusColor],
          },

          '&.Mui-focused:before': {
            boxShadow: 'none',
          },
        }}
      />
    </FormProvider>
  )
}
