import { createDomain, sample } from 'effector'
import { AxiosErrorType, RoadAccident, ThumbType } from '~/shared/api'

const domain = createDomain('entities.roadAccident.ui.form')

// Files
export const saveFiles = domain.createEvent<{
  files: File[]
  fn: (thumb: ThumbType) => void
}>()
export const saveFilesFx = domain.createEffect<
  {
    files: File[]
    fn: (thumb: ThumbType) => void
  },
  void,
  AxiosErrorType
>({
  async handler({ files, fn }) {
    for (const file of files) {
      const res = await RoadAccident.savePhoto(file)
      fn(res)
    }
  },
})

sample({
  clock: saveFiles,
  target: saveFilesFx,
})
