import * as z from 'zod'

import { InspectionServiceTypeEnum } from '~/shared/config/enums'
import { dateSchema } from '~/shared/lib/schemas'
import { ApiModel } from './core'

const attributesSchema = z.object({
  title: z.string(),
  data: z.array(z.union([z.string(), z.number()])),
  type: z.nativeEnum(InspectionServiceTypeEnum),
})

const schema = z
  .object({
    createdAt: dateSchema,
    updatedAt: dateSchema,
  })
  .merge(attributesSchema)

export type InspectionServiceAttributes = z.infer<typeof schema>

export class InspectionService extends ApiModel<
  typeof schema,
  InspectionServiceAttributes
> {
  static jsonApiType = 'inspection-services'

  static schema = schema

  getType(): InspectionServiceTypeEnum {
    return this.getAttribute('type')
  }

  getData(): (string | number)[] {
    return this.getAttribute('data')
  }
}
