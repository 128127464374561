import { Grid } from '@mui/joy'
import { FormTitle, NumericInput, Switch } from '~/shared/ui/Form'
import { Container } from '~/shared/ui/Layout'

type StateProps = {
  loading: boolean
}

export function State({ loading }: StateProps) {
  return (
    <Container>
      <FormTitle>Состояние</FormTitle>
      <Grid container spacing={5}>
        <Grid xs={6} sx={{ paddingBottom: 0 }}>
          <NumericInput
            label='Пробег'
            name='mileage'
            numericProps={{
              thousandSeparator: ' ',
              suffix: ' км',
            }}
            readOnly
            skeletonShow={loading}
          />
        </Grid>
        <Grid xs={6} />

        <Grid xs={6}>
          <Switch
            label='Автомобиль чистый'
            name='isExteriorClean'
            readOnly
            skeletonShow={loading}
          />
        </Grid>
        <Grid xs={6}>
          <Switch
            label='Автомобиль заправлен'
            name='isCarRefueled'
            readOnly
            skeletonShow={loading}
          />
        </Grid>
        <Grid xs={6}>
          <Switch
            label='Запах табака'
            name='hasTobaccoSmell'
            readOnly
            skeletonShow={loading}
          />
        </Grid>
        <Grid xs={6}>
          <Switch
            label='Грязный салон'
            name='isInteriorDirty'
            readOnly
            skeletonShow={loading}
          />
        </Grid>
        <Grid xs={6}>
          <Switch
            label='Требуется химчистка'
            name='isDryCleaningNeed'
            readOnly
            skeletonShow={loading}
          />
        </Grid>
      </Grid>
    </Container>
  )
}
