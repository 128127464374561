import { Box, ChipProps, BoxProps } from '@mui/joy'
import { Option as OptionType } from '~/shared/config/constants'
import { ChipLink } from '~/shared/ui/Chips/ChipLink'
import { ChipDelete } from '../ChipDelete'

type Option = OptionType & {
  to?: string
  tooltipText?: string
}

type ChipListProps = {
  type: 'delete' | 'link'
  chipProps?: Partial<ChipProps>
  sx?: BoxProps['sx']
  options: Option[]
  onDelete?: (option: Option) => void
} & ({ type: 'delete' } | { type: 'link' })

export function ChipList({
  type,
  options,
  onDelete,
  chipProps,
  sx,
}: ChipListProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '12px',
        alignItems: 'center',
        flexWrap: 'wrap',
        ...sx,
      }}
    >
      {options.map((option) => {
        if (type === 'delete') {
          return (
            <ChipDelete
              key={option.id}
              onDelete={() => onDelete?.(option)}
              data-testid={`chip-${option.label}`}
              {...chipProps}
            >
              {option.label}
            </ChipDelete>
          )
        }
        if (type === 'link') {
          return (
            <ChipLink
              key={option.id}
              to={option.to}
              tooltipText={option.tooltipText}
              data-testid={`chip-${option.label}`}
            >
              {option.label}
            </ChipLink>
          )
        }
        return null
      })}
    </Box>
  )
}
