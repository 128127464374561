import { useStore, useStoreMap, useGate } from 'effector-react'
import { Form, counterpartyModel } from '~/entities/Counterparty'
import { usePermissions } from '~/entities/viewer'
import {
  formSubmitted,
  Gate,
  $counterparty,
  counterpartyUpdateFx,
} from './model'

type EditCounterpartyProps = {
  counterpartyId: string
}

export function EditCounterparty({ counterpartyId }: EditCounterpartyProps) {
  useGate(Gate, { counterpartyId: counterpartyId })
  const { canRepairOrdersUpdate } = usePermissions()

  const isLoading = useStore(counterpartyModel.requestFx.pending)
  const isSaving = useStore(counterpartyUpdateFx.pending)

  const defaultValues = useStoreMap($counterparty, (counterparty) => ({
    ...counterparty?.getAttributes(),
  }))

  return (
    <Form
      isLoading={isLoading}
      defaultValues={defaultValues}
      onSuccess={formSubmitted}
      isSubmitting={isSaving}
      canEdit={canRepairOrdersUpdate}
    />
  )
}
