import { AddRepairOrder } from '~/features/RepairOrders/AddRepairOrder'
import { Breadcrumbs, BreadcrumbsList } from '~/shared/ui/Breadcrumbs'
import { Container, PageContainer, PageTitle } from '~/shared/ui/Layout'
import { QuickNavigation } from '~/shared/ui/QuickNavigation'

const breadcrumbs: BreadcrumbsList = [
  { title: 'Заказ наряды', link: '/repair-orders' },
  { title: 'Создание заказ наряда' },
]

const quickNavigationOptions = [
  { label: 'Автомобиль', anchor: 'car' },
  { label: 'Описание ремонта', anchor: 'descriptionRepair' },
  { label: 'СТО', anchor: 'sto' },
  { label: 'Стоимость', anchor: 'cost' },
  { label: 'Файлы', anchor: 'files' },
]

export const AddRepairOrderPage = () => {
  return (
    <PageContainer title='Создание заказ наряда'>
      <Container isHeader>
        <Breadcrumbs list={breadcrumbs} />
        <PageTitle>Создание заказ наряда</PageTitle>
      </Container>

      <AddRepairOrder />

      <QuickNavigation options={quickNavigationOptions} />
    </PageContainer>
  )
}
