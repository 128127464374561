import { Box } from '@mui/joy'
import { useStoreMap } from 'effector-react'
import { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { inspectionModel } from '~/entities/Inspection'
import { Inspection, InspectionDamage } from '~/shared/api'
import { priceRoundFormat } from '~/shared/lib/utils'
import { AsyncGrid, FetchRowsFn } from '~/shared/ui/AsyncGrid'
import { ChipStatus } from '~/shared/ui/Chips'
import { mapPhotoToImage } from '~/shared/ui/Gallery'
import { columns } from './columns'
import { GridRow } from './types'

type ListNewDamagesProps = {
  onRowClick: (damageId: UniqueId) => void
}

export const ListNewDamages = ({ onRowClick }: ListNewDamagesProps) => {
  const params = useParams<{ inspectionId: string }>()
  const inspectionId = params.inspectionId as string

  const damagesCost = useStoreMap(
    inspectionModel.$inspection,
    (inspection: Inspection | null) => {
      const cost = inspection?.getDamagesCost()
      if (!cost) return null
      return `${priceRoundFormat(cost)} руб`
    },
  )

  const fetchRows = useCallback<FetchRowsFn<GridRow>>(
    async (page, pageSize, _, sort) => {
      let builder = InspectionDamage.limit(pageSize)
        .with('detail')
        .with('extent')
        .with('type')
        .with('inspection')
        .with('repairInspection')

        .option('filter[inspection][id]', inspectionId as UniqueId)

      sort
        ? (builder = builder.orderBy(sort))
        : (builder = builder.orderBy('-updatedAt'))

      const response = await builder.get(page)
      const data = response.getData()
      const rows = data.map((damage: InspectionDamage) => {
        return {
          ...damage.getAttributes(),
          id: damage.getApiId() as string,
          carDamageExtend: damage?.getExtent()?.getTitle(),
          carDetail: damage?.getDetail()?.getTitle(),
          driverFullName: damage?.getInspection()?.getDriverFullName(),
          photos: damage?.getPhotos().map(mapPhotoToImage),
        }
      })

      const httpClientResponse = response.getHttpClientResponse()
      const axiosResponse = httpClientResponse?.getUnderlying()
      const total = axiosResponse?.data?.meta?.page?.total || 0

      return {
        rows,
        total,
      }
    },
    [inspectionId],
  )

  const title = useMemo(
    () => (
      <Box sx={{ display: 'flex' }}>
        Новые повреждения
        {damagesCost && (
          <ChipStatus
            sx={{ marginLeft: '16px' }}
            label={damagesCost}
            variant='red'
          />
        )}
      </Box>
    ),
    [damagesCost],
  )

  return (
    <AsyncGrid<GridRow>
      gridKey='new-damages-list'
      nestedGridKey={inspectionId}
      fetchRows={fetchRows}
      columns={columns}
      onRowClick={(row) => onRowClick(row.id as UniqueId)}
      memoryLocal
      smallTitle={title}
    />
  )
}
