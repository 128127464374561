export function UploadCloudIcon() {
  return (
    <svg
      width='44'
      height='44'
      viewBox='0 0 44 44'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_d_2757_6267)'>
        <rect
          x='2'
          y='1'
          width='40'
          height='40'
          rx='20'
          fill='white'
          shapeRendering='crispEdges'
        />
        <rect
          x='2.5'
          y='1.5'
          width='39'
          height='39'
          rx='19.5'
          stroke='#EAECF0'
          shapeRendering='crispEdges'
        />
        <path
          d='M18.667 24.3333L22.0003 21M22.0003 21L25.3337 24.3333M22.0003 21V28.5M28.667 24.9524C29.6849 24.1117 30.3337 22.8399 30.3337 21.4167C30.3337 18.8854 28.2816 16.8333 25.7503 16.8333C25.5682 16.8333 25.3979 16.7383 25.3054 16.5814C24.2187 14.7374 22.2124 13.5 19.917 13.5C16.4652 13.5 13.667 16.2982 13.667 19.75C13.667 21.4718 14.3632 23.0309 15.4894 24.1613'
          stroke='#475467'
          strokeWidth='1.66667'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <filter
          id='filter0_d_2757_6267'
          x='0'
          y='0'
          width='44'
          height='44'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='1' />
          <feGaussianBlur stdDeviation='1' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_2757_6267'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_2757_6267'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  )
}
