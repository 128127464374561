import { Grid } from '@mui/joy'
import {
  accidentRegistrationTypeEnumOptions,
  incidentResponsibleEnumOptions,
  insurancePolicyStatusEnumOptions,
} from '~/shared/config/constants'
import {
  AutocompleteInput,
  DatePickerInput,
  FormTitle,
  NumericInput,
  Switch,
  TextInput,
} from '~/shared/ui/Form'
import { Container } from '~/shared/ui/Layout'

type RegistrationProps = {
  disabled: boolean
  loading: boolean
}

export function Registration({ disabled, loading }: RegistrationProps) {
  return (
    <Container>
      <FormTitle>Оформление ДТП</FormTitle>
      <Grid container spacing={2.5}>
        <Grid xs={6}>
          <NumericInput
            label='Кол-во участников ДТП'
            placeholder='Укажите кол-во участников ДТП'
            name='accidentParticipantsNumber'
            readOnly={disabled}
            skeletonShow={loading}
            numericProps={{ thousandSeparator: '', decimalScale: 0 }}
          />
        </Grid>
        <Grid xs={6}>
          <AutocompleteInput
            options={accidentRegistrationTypeEnumOptions}
            label='Оформление'
            placeholder='Выберите оформление'
            name='accidentRegistrationType'
            readOnly={disabled}
            skeletonShow={loading}
          />
        </Grid>

        <Grid xs={2}>
          <Switch
            label='Постановление'
            name='protocol'
            readOnly={disabled}
            skeletonShow={loading}
            formControlProps={{ sx: { marginTop: '40px' } }}
          />
        </Grid>
        <Grid xs={4}>
          <AutocompleteInput
            options={incidentResponsibleEnumOptions}
            label='Виновность'
            name='culpability'
            readOnly={disabled}
            skeletonShow={loading}
          />
        </Grid>
        <Grid xs={6}>
          <DatePickerInput
            label='Дата получения протокола / постановления ГАИ'
            placeholder='Выберите дату получения протокола / постановления ГАИ'
            name='protocolReceivingDate'
            skeletonShow={loading}
            readOnly={disabled}
          />
        </Grid>

        <Grid xs={4}>
          <TextInput
            label='Страховая компания'
            placeholder='Страховая компания'
            name='insuranceCompany'
            skeletonShow={loading}
            readOnly
          />
        </Grid>
        <Grid xs={4}>
          <TextInput
            label='Номер страхового полиса'
            placeholder='Номер страхового полиса'
            name='insurancePolicyNumber'
            skeletonShow={loading}
            readOnly
          />
        </Grid>
        <Grid xs={4}>
          <AutocompleteInput
            options={insurancePolicyStatusEnumOptions}
            label='Статус полиса'
            placeholder='Выберите статус полиса'
            name='insurancePolicyStatus'
            skeletonShow={loading}
            readOnly
          />
        </Grid>

        <Grid xs={12}>
          <br />
          <FormTitle>Участник №2</FormTitle>
        </Grid>
        <Grid xs={12}>
          <TextInput
            label='Страховая компания'
            placeholder='Введите страховую компанию'
            name='insuranceCompany2'
            readOnly={disabled}
            skeletonShow={loading}
          />
        </Grid>
        <Grid xs={6}>
          <TextInput
            label='Номер страхового полиса'
            placeholder='Введите номер страхового полиса'
            name='insurancePolicyNumber2'
            readOnly={disabled}
            skeletonShow={loading}
          />
        </Grid>
        <Grid xs={6}>
          <AutocompleteInput
            options={insurancePolicyStatusEnumOptions}
            label='Статус полиса'
            placeholder='Выберите статус полиса'
            name='insurancePolicyStatus2'
            readOnly={disabled}
            skeletonShow={loading}
          />
        </Grid>

        <Grid xs={12}>
          <br />
          <FormTitle>Участник №3</FormTitle>
        </Grid>
        <Grid xs={12}>
          <TextInput
            label='Страховая компания'
            placeholder='Введите страховую компанию'
            name='insuranceCompany3'
            readOnly={disabled}
            skeletonShow={loading}
          />
        </Grid>
        <Grid xs={6}>
          <TextInput
            label='Номер страхового полиса'
            placeholder='Введите номер страхового полиса'
            name='insurancePolicyNumber3'
            readOnly={disabled}
            skeletonShow={loading}
          />
        </Grid>
        <Grid xs={6}>
          <AutocompleteInput
            options={insurancePolicyStatusEnumOptions}
            label='Статус полиса'
            placeholder='Выберите статус полиса'
            name='insurancePolicyStatus3'
            readOnly={disabled}
            skeletonShow={loading}
          />
        </Grid>
      </Grid>
    </Container>
  )
}
