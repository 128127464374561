import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Link as JoyLink } from '@mui/joy'
import { Link } from 'react-router-dom'
import { myPalette } from '~/shared/lib/theme'
import { IconButton } from '~/shared/ui/Buttons'
import { IconButtonProps } from '~/shared/ui/Buttons/IconButton'

type LinkDecoratorProps = {
  to?: string
  href?: string
  tooltipText: string
  iconButtonProps?: Partial<IconButtonProps>
}

export function LinkDecorator({
  to,
  href,
  tooltipText,
  iconButtonProps,
}: LinkDecoratorProps) {
  const toProps = to ? { component: Link, to, type: 'text' } : undefined
  const hrefProps = href ? { href: href, variant: 'text' } : undefined
  const props = toProps || hrefProps || {}

  return (
    <JoyLink {...props}>
      <IconButton
        {...iconButtonProps}
        tooltipProps={{ title: tooltipText }}
        size='md'
        sx={{
          '&:hover': {
            borderRadius: '10px',
          },
          ...iconButtonProps?.sx,
        }}
      >
        <OpenInNewIcon sx={{ color: myPalette.brand['700'] }} />
      </IconButton>
    </JoyLink>
  )
}
