import { Chip, ChipProps } from '@mui/joy'
import { ReactNode, useMemo } from 'react'
import { STATUS_VARIANT } from '~/shared/config/constants'

type ChipStatusProps = {
  label: ReactNode | string
  variant?:
    | 'green'
    | 'outlinedGreen'
    | 'red'
    | 'outlinedRed'
    | 'gray'
    | 'yellow'
    | 'brand'
    | 'blue'
    | 'purple'
    | string
} & Omit<ChipProps, 'variant'>

export function ChipStatus({ label, variant, ...props }: ChipStatusProps) {
  const customVariant = useMemo(() => {
    if (variant === 'green') return STATUS_VARIANT['green']
    if (variant === 'outlinedGreen') return STATUS_VARIANT['outlinedGreen']
    if (variant === 'red') return STATUS_VARIANT['red']
    if (variant === 'outlinedRed') return STATUS_VARIANT['outlinedRed']
    if (variant === 'gray') return STATUS_VARIANT['gray']
    if (variant === 'yellow') return STATUS_VARIANT['yellow']
    if (variant === 'brand') return STATUS_VARIANT['brand']
    if (variant === 'blue') return STATUS_VARIANT['blue']
    if (variant === 'purple') return STATUS_VARIANT['purple']
  }, [variant])

  return (
    <Chip
      {...props}
      sx={{
        ...customVariant,
        ...props.sx,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {label}
    </Chip>
  )
}
