import {
  FormControl,
  FormHelperText,
  FormLabel,
  InputProps,
  Skeleton,
} from '@mui/joy'

import React from 'react'
import { Controller } from 'react-hook-form'
import { NumericFormat } from 'react-number-format'
import { NumericFormatProps } from 'react-number-format/types/types'
import { Input } from '../ui/styled'

type NumericInputProps = {
  label?: string
  name: string
  skeletonShow?: boolean
  numericProps?: NumericFormatProps
} & Omit<InputProps, 'name' | 'slotProps'>

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  params: {
    numericProps?: NumericFormatProps
  }
}

const NumericAdapter = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatAdapter({ params, ...other }, ref) {
    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        thousandSeparator=' '
        allowNegative={false}
        decimalScale={2}
        decimalSeparator={','}
        {...params?.numericProps}
      />
    )
  },
)

export function NumericInput({
  label,
  name,
  skeletonShow,
  numericProps,
  ...inputProps
}: NumericInputProps) {
  return (
    <Controller
      name={name}
      render={({ field, fieldState: { error, invalid } }) => (
        <FormControl error={invalid}>
          {label && <FormLabel>{label}</FormLabel>}

          <Input
            fullWidth
            size='lg'
            {...inputProps}
            {...field}
            error={invalid}
            slotProps={{
              ...(skeletonShow ? { root: { component: Skeleton } } : {}),
              input: {
                component: NumericAdapter,
                params: {
                  numericProps,
                },
              },
            }}
          />
          {invalid && (
            <FormHelperText data-testid={`error-message-${label}`}>
              {error?.message || ''}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  )
}
