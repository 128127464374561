export function PrintIcon() {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15 5.83329V4.33329C15 3.39987 15 2.93316 14.8183 2.57664C14.6585 2.26304 14.4035 2.00807 14.0899 1.84828C13.7334 1.66663 13.2667 1.66663 12.3333 1.66663H7.66663C6.73321 1.66663 6.2665 1.66663 5.90998 1.84828C5.59637 2.00807 5.3414 2.26304 5.18162 2.57664C4.99996 2.93316 4.99996 3.39987 4.99996 4.33329V5.83329M4.99996 15C4.22498 15 3.83749 15 3.51958 14.9148C2.65685 14.6836 1.98298 14.0097 1.75181 13.147C1.66663 12.8291 1.66663 12.4416 1.66663 11.6666V9.83329C1.66663 8.43316 1.66663 7.7331 1.93911 7.19832C2.17879 6.72791 2.56124 6.34546 3.03165 6.10578C3.56643 5.83329 4.26649 5.83329 5.66663 5.83329H14.3333C15.7334 5.83329 16.4335 5.83329 16.9683 6.10578C17.4387 6.34546 17.8211 6.72791 18.0608 7.19832C18.3333 7.7331 18.3333 8.43316 18.3333 9.83329V11.6666C18.3333 12.4416 18.3333 12.8291 18.2481 13.147C18.0169 14.0097 17.3431 14.6836 16.4803 14.9148C16.1624 15 15.7749 15 15 15M12.5 8.74996H15M7.66663 18.3333H12.3333C13.2667 18.3333 13.7334 18.3333 14.0899 18.1516C14.4035 17.9918 14.6585 17.7369 14.8183 17.4233C15 17.0668 15 16.6 15 15.6666V14.3333C15 13.3999 15 12.9332 14.8183 12.5766C14.6585 12.263 14.4035 12.0081 14.0899 11.8483C13.7334 11.6666 13.2667 11.6666 12.3333 11.6666H7.66663C6.73321 11.6666 6.2665 11.6666 5.90998 11.8483C5.59637 12.0081 5.3414 12.263 5.18162 12.5766C4.99996 12.9332 4.99996 13.3999 4.99996 14.3333V15.6666C4.99996 16.6 4.99996 17.0668 5.18162 17.4233C5.3414 17.7369 5.59637 17.9918 5.90998 18.1516C6.2665 18.3333 6.73321 18.3333 7.66663 18.3333Z'
        stroke='#3525E5'
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
