import { useStore, useStoreMap } from 'effector-react'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { editRepairOrderModel } from '~/features/RepairOrders/EditRepairOrder'
import { getStatusColor, repairOrderModel } from '~/entities/RepairOrder'
import {
  repairOrderStatusEnumLabels,
  repairOrderStatusEnumOptions,
  STATUS_VARIANT,
} from '~/shared/config/constants'
import { AutocompleteInput, FormProvider } from '~/shared/ui/Form'
import { statusChangeFx, StatusFormValues } from './model'

export function Status() {
  const isChanged = useStore(statusChangeFx.pending)
  const isLoading = useStore(repairOrderModel.requestFx.pending)

  const defaultValues = useStoreMap(
    editRepairOrderModel.$repairOrder,
    (repairOrder) => {
      const status = repairOrder?.getStatus()
      return {
        status: status
          ? { id: status, label: repairOrderStatusEnumLabels[status] }
          : null,
      }
    },
  )

  useEffect(() => {
    reset(defaultValues)
    // eslint-disable-next-line
  }, [defaultValues])

  const form = useForm<StatusFormValues>({
    defaultValues,
  })
  const { reset, watch, setValue } = form

  const status = watch('status')?.id

  const statusColor = useMemo(() => getStatusColor(status), [status])

  const handleStatusChange = async (status: StatusFormValues['status']) => {
    try {
      setValue('status', status)
      await statusChangeFx(status)
    } catch (e) {
      reset(defaultValues)
    }
  }

  return (
    <FormProvider form={form}>
      <AutocompleteInput
        name='status'
        options={repairOrderStatusEnumOptions}
        startDecorator={null}
        disableClearable
        skeletonShow={isLoading}
        readOnly={isChanged}
        loading={isChanged}
        onChange={(_, value) =>
          handleStatusChange(value as StatusFormValues['status'])
        }
        size='sm'
        sx={{
          width: '190px',
          marginBottom: '8px',
          border: 'none',
          '&': {
            ...STATUS_VARIANT[statusColor],
          },

          '&.Mui-focused:before': {
            boxShadow: 'none',
          },
        }}
      />
    </FormProvider>
  )
}
