import { useStore } from 'effector-react'
import { Form } from '~/entities/Counterparty'
import { formSubmitted, createFx } from './model'

export const AddCounterparty = () => {
  const isSubmitting = useStore(createFx.pending)

  return (
    <Form isSubmitting={isSubmitting} isCreating onSuccess={formSubmitted} />
  )
}
