import { Box, styled } from '@mui/joy'

export const Wrapper = styled(Box)`
  background: url('/images/marker.svg') no-repeat;
  background-size: 100%;
  width: 31px;
  height: 31px;
  border-radius: 100%;
  position: absolute;
  left: -15px;
  top: -15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
`
