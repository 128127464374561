import DeleteIcon from '@mui/icons-material/Delete'
import DescriptionIcon from '@mui/icons-material/Description'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Grid, Stack } from '@mui/joy'
import { useMemo } from 'react'
import { myPalette } from '~/shared/lib/theme'
import { IconButton } from '~/shared/ui/Buttons'
import { FileWrapper, Item } from '../styled'

type UploadFilesProps = {
  files: File[]
  onDelete: (file: File) => void
  canDelete?: boolean
}

export function UploadFiles({
  files,
  onDelete,
  canDelete = true,
}: UploadFilesProps) {
  const extra = useMemo(
    () => (
      <Grid container spacing={2.5}>
        {files.map((file) => (
          <Grid xs={4} key={file.name} data-testid={`upload-file-${file.name}`}>
            <Item>
              <FileWrapper>
                {file.type.startsWith('image/') ? (
                  <img src={URL.createObjectURL(file)} alt={file.name} />
                ) : file.type === 'application/pdf' ? (
                  <embed
                    src={URL.createObjectURL(file)}
                    type='application/pdf'
                  />
                ) : (
                  <DescriptionIcon sx={{ color: myPalette.green['700'] }} />
                )}
              </FileWrapper>

              <Stack direction='row' spacing={1}>
                <IconButton
                  href={URL.createObjectURL(file)}
                  tooltipProps={{ title: 'Открыть' }}
                  slotProps={{ root: { target: '_blank' } }}
                >
                  <OpenInNewIcon sx={{ color: myPalette.brand['700'] }} />
                </IconButton>
                {canDelete && (
                  <IconButton
                    onClick={() => onDelete(file)}
                    tooltipProps={{ title: 'Удалить' }}
                  >
                    <DeleteIcon sx={{ color: myPalette.red['700'] }} />
                  </IconButton>
                )}
              </Stack>
            </Item>
          </Grid>
        ))}
      </Grid>
    ),
    [canDelete, files, onDelete],
  )

  if (!files.length) return null

  return extra
}
