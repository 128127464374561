import { combine, createDomain, sample } from 'effector'
import { createGate, useStoreMap } from 'effector-react'

import { mapInspectionDamageToDamageForm } from '~/entities/Inspection/mappers'
import { Damage } from '~/entities/Inspection/ui/Damage'
import { Inspection, InspectionDamage } from '~/shared/api'
import { CarDetailTypeEnum } from '~/shared/config/enums'
import { createCache } from '~/shared/lib/mapCacheFactory'
import { isString } from '~/shared/lib/utils'

export const domain = createDomain('entities.inspection')

export const Gate = createGate<{ inspectionId: UniqueId }>()

export const $inspectionId = domain
  .createStore<UniqueId | null>(null)
  .on(Gate.state, (_, { inspectionId }) => inspectionId)

export const requestInspectionFx = domain.createEffect<UniqueId, Inspection>({
  async handler(id) {
    return await fetchInspection(id)
  },
})

const fetchInspection = async (id: UniqueId) => {
  const response = await Inspection.with('car')
    .with('car.brandModel')
    .with('documents')
    .with('equipments')
    .with('reason')
    .with('services')
    .with('wheelsStates')
    .with('responsible')
    .with('violations')
    .find(id)
  return response.getData() as Inspection
}

sample({
  clock: $inspectionId,
  filter: isString,
  target: requestInspectionFx,
})

const {
  $cache: $inspectionCache,
  useCache: useInspectionCache,
  updateCache,
} = createCache<Inspection>({
  domain,
  getEntityId: (inspection) => inspection.getApiId() as UniqueId,
})
export { $inspectionCache, useInspectionCache }

export const $inspection = combine(
  $inspectionId,
  $inspectionCache,
  (id, cache) => {
    if (!id) return null
    return cache.map[id] ?? null
  },
)

$inspectionCache.on(requestInspectionFx.doneData, (cache, inspection) =>
  updateCache(cache, [inspection]),
)

export const $inspectionError = domain
  .createStore<Record<UniqueId, Error>>({})
  .on(requestInspectionFx.fail, (store, { error, params: id }) => ({
    [id]: error,
    ...store,
  }))

export const useInspectionError = (id: UniqueId) =>
  useStoreMap($inspectionError, (errors) => errors[id])

// InspectionDamage
type FetchDamagesParams = {
  inspectionId: UniqueId
  type: CarDetailTypeEnum
}

export async function fetchDamagesByTypes({
  inspectionId,
  type,
}: FetchDamagesParams) {
  const carDetails = await InspectionDamage.where('isRepaired', '0')
    .option('filter[detail][type]', type)
    .option('filter[inspection][id]', inspectionId)
    .with('detail')
    .with('extent')
    .with('type')
    .limit(100)
    .get(1)
  return carDetails.getData() as InspectionDamage[]
}

export const requestDamagesExteriorFx = domain.createEffect<
  FetchDamagesParams,
  InspectionDamage[]
>({
  handler: fetchDamagesByTypes,
})

sample({
  clock: requestInspectionFx.doneData,
  fn: (inspection) => ({
    inspectionId: inspection?.getApiId() as UniqueId,
    type: CarDetailTypeEnum.EXTERIOR,
  }),
  target: requestDamagesExteriorFx,
})

export const $damagesExterior = domain
  .createStore<Damage[]>([])
  .on(requestDamagesExteriorFx.doneData, (cache, damages) =>
    mapInspectionDamageToDamageForm(damages),
  )
  .on($inspectionId, () => [])

export const requestDamagesTireFx = domain.createEffect<
  FetchDamagesParams,
  InspectionDamage[]
>({
  handler: fetchDamagesByTypes,
})

sample({
  clock: requestInspectionFx.doneData,
  fn: (inspection) => ({
    inspectionId: inspection?.getApiId() as UniqueId,
    type: CarDetailTypeEnum.TIRE,
  }),
  target: requestDamagesTireFx,
})

export const $damagesTire = domain
  .createStore<Damage[]>([])
  .on(requestDamagesTireFx.doneData, (cache, damages) =>
    mapInspectionDamageToDamageForm(damages),
  )
  .on($inspectionId, () => [])

export const requestDamagesInteriorFx = domain.createEffect<
  FetchDamagesParams,
  InspectionDamage[]
>({
  handler: fetchDamagesByTypes,
})

sample({
  clock: requestInspectionFx.doneData,
  fn: (inspection) => ({
    inspectionId: inspection?.getApiId() as UniqueId,
    type: CarDetailTypeEnum.INTERIOR,
  }),
  target: requestDamagesInteriorFx,
})

export const $damagesInterior = domain
  .createStore<Damage[]>([])
  .on(requestDamagesInteriorFx.doneData, (cache, damages) =>
    mapInspectionDamageToDamageForm(damages),
  )
  .on($inspectionId, () => [])

export const requestDamagesEnvironmentFx = domain.createEffect<
  FetchDamagesParams,
  InspectionDamage[]
>({
  handler: fetchDamagesByTypes,
})

sample({
  clock: requestInspectionFx.doneData,
  fn: (inspection) => ({
    inspectionId: inspection?.getApiId() as UniqueId,
    type: CarDetailTypeEnum.ENVIRONMENT,
  }),
  target: requestDamagesEnvironmentFx,
})

export const $damagesEnvironment = domain
  .createStore<Damage[]>([])
  .on(requestDamagesEnvironmentFx.doneData, (cache, damages) =>
    mapInspectionDamageToDamageForm(damages),
  )
  .on($inspectionId, () => [])

// OldInspectionDamage
type FetchOldDamagesParams = {
  carId: UniqueId
  inspectionFinishedAt: string
  type: CarDetailTypeEnum
}

export async function fetchOldDamagesByTypes({
  carId,
  inspectionFinishedAt,
  type,
}: FetchOldDamagesParams) {
  const carDetails = await InspectionDamage.where('isRepaired', '0')
    .option('filter[detail][type]', type)
    .option('filter[inspection][car][id]', carId)
    .option('filter[createdAtLt]', inspectionFinishedAt)
    .with('detail')
    .with('extent')
    .with('type')
    .limit(100)
    .get(1)
  return carDetails.getData() as InspectionDamage[]
}

export const requestOldDamagesExteriorFx = domain.createEffect<
  FetchOldDamagesParams,
  InspectionDamage[]
>({
  handler: fetchOldDamagesByTypes,
})

sample({
  clock: requestInspectionFx.doneData,
  fn: (inspection) => ({
    carId: inspection?.getCar().getApiId() as UniqueId,
    inspectionFinishedAt: inspection?.getFinishedAt(),
    type: CarDetailTypeEnum.EXTERIOR,
  }),
  target: requestOldDamagesExteriorFx,
})

export const $oldDamagesExterior = domain
  .createStore<Damage[]>([])
  .on(requestOldDamagesExteriorFx.doneData, (cache, damages) =>
    mapInspectionDamageToDamageForm(damages),
  )
  .on($inspectionId, () => [])

export const requestOldDamagesTireFx = domain.createEffect<
  FetchOldDamagesParams,
  InspectionDamage[]
>({
  handler: fetchOldDamagesByTypes,
})

sample({
  clock: requestInspectionFx.doneData,
  fn: (inspection) => ({
    carId: inspection?.getCar().getApiId() as UniqueId,
    inspectionFinishedAt: inspection?.getFinishedAt(),
    type: CarDetailTypeEnum.TIRE,
  }),
  target: requestOldDamagesTireFx,
})

export const $oldDamagesTire = domain
  .createStore<Damage[]>([])
  .on(requestOldDamagesTireFx.doneData, (cache, damages) =>
    mapInspectionDamageToDamageForm(damages),
  )
  .on($inspectionId, () => [])

export const requestOldDamagesInteriorFx = domain.createEffect<
  FetchOldDamagesParams,
  InspectionDamage[]
>({
  handler: fetchOldDamagesByTypes,
})

sample({
  clock: requestInspectionFx.doneData,
  fn: (inspection) => ({
    carId: inspection?.getCar().getApiId() as UniqueId,
    inspectionFinishedAt: inspection?.getFinishedAt(),
    type: CarDetailTypeEnum.INTERIOR,
  }),
  target: requestOldDamagesInteriorFx,
})

export const $oldDamagesInterior = domain
  .createStore<Damage[]>([])
  .on(requestOldDamagesInteriorFx.doneData, (cache, damages) =>
    mapInspectionDamageToDamageForm(damages),
  )
  .on($inspectionId, () => [])

export const requestOldDamagesEnvironmentFx = domain.createEffect<
  FetchOldDamagesParams,
  InspectionDamage[]
>({
  handler: fetchOldDamagesByTypes,
})

sample({
  clock: requestInspectionFx.doneData,
  fn: (inspection) => ({
    carId: inspection?.getCar().getApiId() as UniqueId,
    inspectionFinishedAt: inspection?.getFinishedAt(),
    type: CarDetailTypeEnum.ENVIRONMENT,
  }),
  target: requestOldDamagesEnvironmentFx,
})

export const $oldDamagesEnvironment = domain
  .createStore<Damage[]>([])
  .on(requestOldDamagesEnvironmentFx.doneData, (cache, damages) =>
    mapInspectionDamageToDamageForm(damages),
  )
  .on($inspectionId, () => [])
