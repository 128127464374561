import { zodResolver } from '@hookform/resolvers/zod'
import { Grid } from '@mui/joy'
import { useStore } from 'effector-react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Element } from 'react-scroll'
import { FormValues, repairOrderModel } from '~/entities/RepairOrder'
import { Car, Counterparty, RepairType, RoadAccident } from '~/shared/api'
import {
  repairOrderPaymentStatusEnumOptions,
  repairOrderStatusEnumOptions,
} from '~/shared/config/constants'
import { getObject } from '~/shared/lib/utils'
import {
  AsyncAutocompleteInput,
  AutocompleteInput,
  DatePickerInput,
  FormButtons,
  formButtonsModel,
  FormProvider,
  FormTitle,
  NumericInput,
  TextareaInput,
  TextInput,
} from '~/shared/ui/Form'
import { Container } from '~/shared/ui/Layout'
import { Files } from './Files'
import { saveFilesFx } from './model'

type FormProps = {
  isCreating?: boolean
  canEdit?: boolean
  isSubmitting?: boolean
  isLoading?: boolean
  onSuccess?: (formValues: FormValues) => void
  defaultValues?: Partial<FormValues>
  isView?: boolean
}

export function Form({
  isCreating,
  canEdit,
  isSubmitting,
  isLoading,
  defaultValues,
  onSuccess,
  isView,
}: FormProps) {
  const isEditing = useStore(formButtonsModel.$isEditing)
  const disabled = (!isEditing && !isCreating) || isSubmitting
  const isSaveFiles = useStore(saveFilesFx.pending)

  const form = useForm<FormValues>({
    resolver: zodResolver(repairOrderModel.formSchema),
    defaultValues,
  })

  const { reset, watch, setValue } = form

  const carOption = watch('carOption')

  useEffect(() => {
    const { vin, brandModel, subdivision } = getObject(carOption) || {
      vin: '',
      brandModel: '',
      subdivision: '',
    }
    setValue('vin', vin)
    setValue('brandModel', brandModel)
    setValue('subdivision', subdivision)
    // eslint-disable-next-line
  }, [carOption])

  useEffect(() => {
    if (defaultValues) reset(defaultValues)
    // eslint-disable-next-line
  }, [defaultValues, isEditing])

  return (
    <FormProvider form={form} onSuccess={onSuccess} canEdit={canEdit}>
      <Element name='car'>
        <Container>
          <FormTitle>Автомобиль</FormTitle>
          <Grid container spacing={2.5}>
            <Grid xs={6}>
              <AsyncAutocompleteInput
                name='carOption'
                placeholder='Выберите автомобиль'
                label='Автомобиль'
                fetchOptions={Car.fetchWithRelationsOptions}
                skeletonShow={isLoading}
                readOnly={disabled}
              />
            </Grid>
            <Grid xs={6}>
              <TextInput
                name='vin'
                placeholder='VIN'
                label='VIN'
                skeletonShow={isLoading}
                readOnly
              />
            </Grid>
            <Grid xs={6}>
              <TextInput
                name='brandModel'
                placeholder='Марка модель'
                label='Марка модель'
                skeletonShow={isLoading}
                readOnly
              />
            </Grid>
            <Grid xs={6}>
              <TextInput
                name='subdivision'
                placeholder='Подразделение'
                label='Подразделение'
                skeletonShow={isLoading}
                readOnly
              />
            </Grid>
            <Grid xs={6}>
              <NumericInput
                name='mileage'
                placeholder='Введите пробег'
                label='Пробег'
                skeletonShow={isLoading}
                readOnly={disabled}
              />
            </Grid>
            {isView && (
              <Grid xs={6}>
                <TextInput
                  name='userName'
                  placeholder='Пользователь'
                  label='Пользователь'
                  skeletonShow={isLoading}
                  readOnly
                />
              </Grid>
            )}
          </Grid>
        </Container>
      </Element>

      <Element name='roadAccidents'>
        <Container>
          <FormTitle>ДТП и Страховые случаи</FormTitle>
          <Grid container spacing={2.5}>
            <Grid xs={12}>
              <AsyncAutocompleteInput
                name='roadAccidentIds'
                placeholder='Выберите дтп'
                fetchOptions={RoadAccident.fetchOptions}
                skeletonShow={isLoading}
                multiple
                readOnly={disabled}
                autocompleteXs={12}
                sx={{ '.MuiGrid-root': { padding: 0 } }}
              />
            </Grid>
          </Grid>
        </Container>
      </Element>

      <Element name='descriptionRepair'>
        <Container>
          <FormTitle>Описание ремонта</FormTitle>
          <Grid container spacing={2.5}>
            <Grid container xs={6}>
              {!isView && (
                <Grid xs={12}>
                  <AutocompleteInput
                    label='Статус заказ-наряда'
                    name='status'
                    placeholder='Выберите статус заказ-наряда'
                    options={repairOrderStatusEnumOptions}
                    skeletonShow={isLoading}
                    readOnly={disabled}
                  />
                </Grid>
              )}
              <AsyncAutocompleteInput
                label='Тип ремонта'
                name='repairTypesOptions'
                placeholder='Выберите тип ремонта'
                fetchOptions={RepairType.fetchOptions}
                skeletonShow={isLoading}
                multiple
                readOnly={disabled}
                autocompleteXs={12}
              />
            </Grid>
            <Grid xs={6}>
              <TextareaInput
                name='reason'
                placeholder='Введите причину обращения'
                label='Причина обращения (неисправности)'
                skeletonShow={isLoading}
                readOnly={disabled}
                minRows={7}
                maxRows={7}
                sx={{
                  height: '186px',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Element>

      <Element name='sto'>
        <Container>
          <FormTitle>СТО</FormTitle>
          <Grid container spacing={2.5}>
            <Grid xs={isView ? 12 : 6}>
              <AsyncAutocompleteInput
                name='counterpartyOption'
                placeholder='Выберите контрагента'
                label='Контрагент'
                fetchOptions={Counterparty.fetchOptions}
                skeletonShow={isLoading}
                readOnly={disabled}
              />
            </Grid>
            {!isView && (
              <Grid xs={6}>
                <AutocompleteInput
                  label='Статус оплаты'
                  name='paymentStatus'
                  placeholder='Выберите статус оплаты'
                  options={repairOrderPaymentStatusEnumOptions}
                  skeletonShow={isLoading}
                  readOnly={disabled}
                />
              </Grid>
            )}
            <Grid xs={4}>
              <DatePickerInput
                label='Дата начала работ'
                placeholder='Выберите дату начала работ'
                name='startedAt'
                skeletonShow={isLoading}
                readOnly={disabled}
              />
            </Grid>
            <Grid xs={4}>
              <DatePickerInput
                label='Дата окончания работ'
                placeholder='Выберите дату окончания работ'
                name='finishedAt'
                skeletonShow={isLoading}
                readOnly={disabled}
              />
            </Grid>

            <Grid xs={4}>
              <TextInput
                name='counterpartyNumber'
                placeholder='Номер заказ-наряда контрагента'
                label='Номер заказ-наряда контрагента'
                skeletonShow={isLoading}
                readOnly={disabled}
              />
            </Grid>
          </Grid>
        </Container>
      </Element>

      <Element name='cost'>
        <Container>
          <FormTitle>Стоимость</FormTitle>
          <Grid container spacing={2.5}>
            <Grid xs={4}>
              <NumericInput
                name='materialsCost'
                placeholder='Введите стоимость материалов'
                label='Стоимость материалов'
                skeletonShow={isLoading}
                readOnly={disabled}
              />
            </Grid>
            <Grid xs={4}>
              <NumericInput
                name='workCost'
                placeholder='Введите стоимость работ'
                label='Стоимость работ'
                skeletonShow={isLoading}
                readOnly={disabled}
              />
            </Grid>
            {isView && (
              <Grid xs={4}>
                <NumericInput
                  name='totalCost'
                  placeholder='Стоимость итого'
                  label='Стоимость итого'
                  skeletonShow={isLoading}
                  readOnly
                />
              </Grid>
            )}
          </Grid>
        </Container>
      </Element>

      <Element name='files'>
        <Container>
          <FormTitle>Файлы</FormTitle>
          <Files isLoading={isLoading} disabled={disabled} />
        </Container>
      </Element>

      <FormButtons
        loading={isSubmitting || isSaveFiles}
        isCreating={isCreating}
      />
    </FormProvider>
  )
}
