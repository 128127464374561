import { zodResolver } from '@hookform/resolvers/zod'
import { Grid } from '@mui/joy'
import { useStore } from 'effector-react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { FormValues, counterpartyModel } from '~/entities/Counterparty'
import {
  FormButtons,
  formButtonsModel,
  FormProvider,
  FormTitle,
  NumericInput,
  TextInput,
} from '~/shared/ui/Form'
import { Container } from '~/shared/ui/Layout'

type FormProps = {
  isCreating?: boolean
  canEdit?: boolean
  isSubmitting?: boolean
  isLoading?: boolean
  onSuccess?: (formValues: FormValues) => void
  defaultValues?: Partial<FormValues>
}

export function Form({
  isCreating,
  canEdit,
  isSubmitting,
  isLoading,
  defaultValues,
  onSuccess,
}: FormProps) {
  const isEditing = useStore(formButtonsModel.$isEditing)
  const disabled = (!isEditing && !isCreating) || isSubmitting

  const form = useForm<FormValues>({
    resolver: zodResolver(counterpartyModel.formSchema),
    defaultValues,
  })

  const { reset } = form

  useEffect(() => {
    if (defaultValues) reset(defaultValues)
    // eslint-disable-next-line
  }, [defaultValues, isEditing])

  return (
    <FormProvider form={form} onSuccess={onSuccess} canEdit={canEdit}>
      <Container>
        <FormTitle>Информация</FormTitle>
        <Grid container spacing={2.5}>
          <Grid xs={6}>
            <TextInput
              label='Название'
              placeholder='Введите название'
              name='name'
              skeletonShow={isLoading}
              readOnly={disabled}
            />
          </Grid>
        </Grid>
      </Container>

      <Container>
        <FormTitle>Реквизиты</FormTitle>
        <Grid container spacing={2.5}>
          <Grid xs={4}>
            <NumericInput
              label='ИНН'
              name='inn'
              placeholder='Введите ИНН'
              skeletonShow={isLoading}
              readOnly={disabled}
              numericProps={{ thousandSeparator: '' }}
            />
          </Grid>
          <Grid xs={4}>
            <NumericInput
              label='КПП'
              name='kpp'
              placeholder='Введите КПП'
              skeletonShow={isLoading}
              readOnly={disabled}
              numericProps={{ thousandSeparator: '' }}
            />
          </Grid>
          <Grid xs={4}>
            <NumericInput
              label='ОГРН'
              name='ogrn'
              placeholder='Введите ОГРН'
              skeletonShow={isLoading}
              readOnly={disabled}
              numericProps={{ thousandSeparator: '' }}
            />
          </Grid>
        </Grid>
      </Container>

      <FormButtons loading={isSubmitting} isCreating={isCreating} />
    </FormProvider>
  )
}
