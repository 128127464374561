import { Damage } from '~/entities/Inspection/ui/Damage'
import { InspectionDamage } from '~/shared/api'

export const mapInspectionDamageToDamageForm = (
  inspectionDamage: InspectionDamage[],
): Damage[] => {
  return inspectionDamage?.map((damages) => ({
    ...damages.getAttributes(),
    code: damages?.getDetail()?.getCode(),
    id: damages.getApiId() as UniqueId,
    isRepairDamage: false,
    carDamageExtent: damages?.getExtent()?.getOption(),
    carDetail: damages.getDetail()?.getOption(),
    createdAt: damages.getCreatedAt(),
  }))
}
