import { Damages } from '~/features/Inspection/Damages'
import { QuickNavigation } from '~/shared/ui/QuickNavigation'

const quickNavigationOptions = [
  { label: 'Карта повреждений', anchor: 'damagesMap' },
  { label: 'Новые повреждения', anchor: 'damagesNew' },
  { label: 'История повреждений', anchor: 'damagesHistory' },
]

export function DamagesPage() {
  return (
    <>
      <Damages />
      <QuickNavigation options={quickNavigationOptions} />
    </>
  )
}
