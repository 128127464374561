import { useGate, useStore, useStoreMap } from 'effector-react'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { AddRepairOrder } from '~/features/RepairOrders/AddRepairOrder'
import { editRoadAccidentModel } from '~/features/RoadAccidents/EditRoadAccident'
import { Gate } from '~/features/RoadAccidents/EditRoadAccident/model'
import { roadAccidentModel } from '~/entities/RoadAccident'
import { CarWithRelationOption } from '~/shared/api'
import { Option } from '~/shared/config/constants'
import { formatDateTimeForUI } from '~/shared/lib/date'
import { Breadcrumbs, BreadcrumbsList } from '~/shared/ui/Breadcrumbs'
import { Container, PageContainer, PageTitle } from '~/shared/ui/Layout'
import { NotFound } from '~/shared/ui/NotFound'
import { QuickNavigation } from '~/shared/ui/QuickNavigation'

const quickNavigationOptions = [
  { label: 'Автомобиль', anchor: 'car' },
  { label: 'Описание ремонта', anchor: 'descriptionRepair' },
  { label: 'СТО', anchor: 'sto' },
  { label: 'Стоимость', anchor: 'cost' },
  { label: 'Файлы', anchor: 'files' },
]

export const RoadAccidentAddRepairOrderPage = () => {
  const params = useParams<{ roadAccidentId: string }>()
  const roadAccidentId = params.roadAccidentId as string
  useGate(Gate, { roadAccidentId })

  const isLoading = useStore(roadAccidentModel.requestFx.pending)
  const roadAccident = useStore(editRoadAccidentModel.$roadAccident)

  const number = roadAccident?.getNumber()
  const createdAt = roadAccident?.getCreatedAt()

  const title = `ДТП № ${number || '...'} от ${
    createdAt ? formatDateTimeForUI(createdAt) : ''
  }`

  const breadcrumbs: BreadcrumbsList = useMemo(
    () => [
      { title: 'ДТП и Страховые случаи', link: '/road-accidents' },
      {
        title: title,
        link: `/road-accidents/${roadAccidentId}`,
      },
      { title: 'Создание заказ наряда' },
    ],
    [roadAccidentId, title],
  )

  const defaultValues = useStoreMap(
    editRoadAccidentModel.$roadAccident,
    (roadAccident) => {
      const car = roadAccident?.getCar()
      const inspection = roadAccident?.getInspection()
      const mileage = roadAccident?.getMileage()

      return {
        mileage,
        carOption:
          car && inspection
            ? ({
                id: car?.getApiId(),
                label: inspection?.getPlateNumber(),
                subdivision: roadAccident?.getSubdivision()?.getTitle(),
                vin: car?.getVin(),
                brandModel: car?.getBrandModel()?.getTitle(),
              } as CarWithRelationOption)
            : null,
        roadAccidentIds: roadAccident
          ? [
              {
                id: roadAccident.getApiId(),
                label: roadAccident?.getNumber(),
              } as Option,
            ]
          : null,
      }
    },
  )

  const error = roadAccidentModel.useRoadAccidentError(roadAccidentId as string)
  if (!roadAccidentId || error) {
    return <NotFound message='Не удалось загрузить карточку ДТП' />
  }

  return (
    <PageContainer title='Создание заказ наряда'>
      <Container isHeader>
        <Breadcrumbs list={breadcrumbs} lastLoading={isLoading} />
        <PageTitle>Создание заказ наряда</PageTitle>
      </Container>

      <AddRepairOrder isLoading={isLoading} defaultValues={defaultValues} />

      <QuickNavigation options={quickNavigationOptions} />
    </PageContainer>
  )
}
