import { z, EnumLike } from 'zod'
import { formatForApi, fromString } from '~/shared/lib/date'
import { sanitizePhoneNumber, sanitizeStringNumber } from '~/shared/lib/utils'

export const dateSchema = z
  .preprocess((arg) => {
    if (typeof arg == 'string') {
      return fromString(arg)
    }
    return arg
  }, z.date())
  .transform(formatForApi)

export const dateSchemaOptional = z
  .string()
  .max(0)
  .or(dateSchema)
  .optional()
  .nullable()
  .transform((arg) => arg || null)

export const phoneScheme = z
  .string()
  .superRefine((value, ctx) => {
    const sanitized = sanitizePhoneNumber(value)
    if (sanitized.length > 0 && sanitized.length < 12) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Неверный номер телефона',
      })
    }
  })
  .transform(sanitizePhoneNumber)

export const priceRequiredScheme = z
  .string()
  .or(z.number())
  .superRefine((value, ctx) => {
    const sanitized = sanitizeStringNumber(value)
    if (!sanitized) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Обязательное поле',
      })
    }
    if (sanitized && sanitized > 999999999) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Превышена допустимая стоимость',
      })
    }
  })
  .transform(sanitizeStringNumber)

export const priceRequiredWithZeroScheme = z
  .string()
  .or(z.number())
  .superRefine((value, ctx) => {
    const sanitized = sanitizeStringNumber(value)
    if (!sanitized && sanitized !== 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Обязательное поле',
      })
    }
    if (sanitized && sanitized > 999999999) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Превышена допустимая стоимость',
      })
    }
  })
  .transform(sanitizeStringNumber)

export const optionSchema = z.object({
  id: z.string(),
  label: z.string(),
})
export const uuidOptionSchema = z
  .union([z.string().uuid(), optionSchema])
  .transform((arg) => {
    if (typeof arg === 'string') {
      return arg
    }
    return arg.id
  })
export const uuidOptionsSchema = z.array(uuidOptionSchema)

export const enumOptionSchema = <T extends EnumLike>(values: T) =>
  z
    .union([
      z.nativeEnum(values),
      z.object({
        id: z.nativeEnum(values),
        label: z.string(),
      }),
    ])
    .transform((arg) => {
      if (typeof arg === 'object' && 'id' in arg) {
        return arg.id
      }
      return arg
    })

export const yearSchema = z
  .union([
    z
      .string()
      .regex(
        /^\d{4}$/,
        'Год имеет неправильный формат, ожидается, например "2022"',
      ),
    z.number(),
  ])
  .transform((arg) => {
    if (typeof arg === 'string') return parseInt(arg)
    return arg
  })

export const numberRequiredScheme = z
  .string()
  .or(z.number())
  .superRefine((value, ctx) => {
    const sanitized = sanitizeStringNumber(value)
    if (!sanitized) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Обязательное поле',
      })
    }
    if (sanitized && sanitized > 999999999) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Превышена допустимое число',
      })
    }
  })
  .transform(sanitizeStringNumber)

export const stringRequiredScheme = z
  .string()
  .trim()
  .min(1, 'Обязательное поле')

export const okpoRequiredSchema = z
  .string()
  .trim()
  .regex(/^\d{8}$|^\d{10}$/, 'Только цифры, длина ОКПО - 8 или 10 символов')

export const innRequiredSchema = z
  .string()
  .trim()
  .regex(/^\d{10}$|^\d{12}$/, 'Только цифры, длина ИНН - 10 или 12 символов')

export const kppRequiredSchema = z
  .string()
  .trim()
  .regex(/^\d{0}$|^\d{9}$/, 'Только цифры, длина КПП - 9 символов')

export const ogrnRequiredSchema = z
  .string()
  .trim()
  .regex(/^\d{13}$|^\d{15}$/, 'Только цифры, длина ОГРН - 13 или 15 символов')

export const bankRsRequiredSchema = z
  .string()
  .trim()
  .refine(
    (value) => /^\d{20}$/.test(value),
    'Только цифры, длина поля - 20 символов',
  )

export const bankBikRequiredSchema = z
  .string()
  .trim()
  .refine(
    (value) => /^\d{9}$/.test(value),
    'Только цифры, длина БИК - 9 символов',
  )

export const filesSchema = z
  .array(
    z.object({
      originalUrl: z.string(),
      previewUrl: z.string(),
      fileName: z.string(),
      uuid: z.string(),
    }),
  )
  .optional()
  .nullable()

export const fileIdsSchema = z.array(z.string()).optional().default([])
